import { Button } from "@material-ui/core";
import Lottie from "lottie-web";
import { useEffect, useRef, useState } from "react";


export const ScreenCalibration = (props) => {

   
    
    const [imageWidth, setImageWidth] = useState()
    const [imageHeight, setImageHeight] = useState()

    const [zoomOpen, setZoomOpen] = useState(true)

    const zoom = useRef(null)
    const ref = useRef(null)
    var animation

    

    window.addEventListener('resize', () => {
        const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
        console.log(browserZoomLevel)
      })   

    useEffect(()=>{
        const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
        console.log(browserZoomLevel)

        Lottie.destroy(animation)

        animation = Lottie.loadAnimation(
            {
                container: zoom.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require( `../Lotties/zoom.json`)
            }
            )
            Lottie.loadAnimation(
                {
                    container: ref.current,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    animationData: require( `../Lotties/Lottie-1.json`)
                }
            )
    },[])

    const Views = () =>{
        if(props.step == 0)
        return (
                <div className="square">
                    <div className={`zoom ${zoomOpen ? 'open' : ''}`}>
                        <p>Before moving on to screen calibration make sure your browser zoom is set to 100%</p>
                        <div className='zoom-lottie' ref={zoom}>
                        </div>
                        <button onClick={()=> setZoomOpen(false)} className='btn-purple btn-medium'>Continue</button>
                    </div>
                    <h2>Let's check your Screen Size</h2>
                    <p>Anchor your card to the top right corner (+) and then adjust the slider to resize the bracket until your card fits snuggly</p>
                    <div className="lottie" ref={ref}>

                    </div>
                    <div className='buttons'>
                        <button onClick={()=> props.overlaytoggle(true)} className='btn-purple'>Continue</button>
                    </div>
                </div> 
        )
        else
            return(
                <div className="screen-calibration-verification">
                    <h2>
                    Let’s Verify your screen size
                    </h2>
                    <p>
                        Please take a dollar bill and place it on the screen to see if it matches the dollar bill below.
                    </p>
                    <img src='./images/dollar.jpg' alt="Verify calibration" style={{width:`${props.imageWidth}px`,height:`${props.imageHeight}px`}}/>
                    <div className='buttons'>
                            <button onClick={()=> props.overlaytoggle(true)} className='btn-blue'>It Doesn't match</button>
                            <button onClick={()=>  props.setActiveSetup(1)} className='btn-purple btn-small'>It Matches</button>
                    </div>
                </div> 
            )
    }
   

    return (
                 <div className="screen-calibration">
                   {Views()}
                </div>
    )
}
