import { useState, useEffect, useRef } from "react"
import { isMobile } from "react-device-detect"
import { SocketConnect } from "../util/utilities"
import { ColorBlindnessTest } from "./ColorBlindnessTest"
import { DepthPerceptionTest } from "./DepthPerceptionTest"
import { VisualAcuityTest } from "./VisualAcuityTest"
import { VisualFieldTest } from './VisualFieldTest'
import { useSelector } from "react-redux"
import { login, selectUser } from "../store/features/UserSlice"
import { useDispatch } from "react-redux"
import {Header} from './Header'
import Lottie from "lottie-web"
import axios from "axios"

export const TestController = (props) => {

    const socket = SocketConnect()

    const user = useSelector(selectUser)

    const [activeTest,setActiveTest] = useState(0)
    const [testFlow,setTestFlow] = useState()

    const [usState,setUsState] = useState()


    const [dimentions, setDimentions] = useState()

    const token = localStorage.getItem('sync').token

    const [loading,setLoading] = useState(true)
    const loader = useRef(null)

    useEffect(()=>{
        Lottie.loadAnimation({
            container: loader.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../loader.json')

    })
    if(!isMobile){

        if(user.test_in_progress){
            setActiveTest(user.test_in_progress - 1 )
        }
    }
    
    },[])

    const dispatch = useDispatch()

    const Headers = () =>{
      if(testFlow){
          return testFlow.tests.map((test,i) =>
          <div className="number">
                <p>
                    <span>{i+1}</span>
                        {test.name}
                </p>
        </div>
          )
      }
    }
    useEffect(()=>{
        socket.on('finished-tests',()=>{
            if(!isMobile){
              window.location.assign(`${process.env.REACT_APP_LINK}/test-end`)
            }
            else{
                setActiveTest(10)
            }
        })
    })

    useEffect(()=>{
        
        
        if(!isMobile){
            socket.emit('join', token)

            setDimentions(JSON.parse(localStorage.getItem('dimentions')))
            for(let i = 0; i< StateFlow.length; i++){
                if(StateFlow[i].usState === user.usState){
                    setTestFlow(StateFlow[i])
                   
                }
            }
        }
        else{
            socket.emit('join', token,1)
        }
    },[])

    useEffect(()=>{
        if(testFlow){
            if(!isMobile){
                let numbers = document.getElementsByClassName('number')
    
                for(let i =0 ; i< numbers.length; i++){
                    if(i === activeTest)
                        numbers[i].classList.add('lnk-blue')
                    else
                        numbers[i].classList.remove('lnk-blue')
                }
            }
        }
    },[testFlow])

    useEffect(()=>{
        if(usState){
            for(let i = 0; i< StateFlow.length; i++){
                if(StateFlow[i].usState === usState){
                    setTestFlow(StateFlow[i])
                }
            }
        }
    },[usState])

    useEffect(()=>{
        if(testFlow && isMobile){
            setLoading(false)
        }
    },[testFlow])

    const StateFlow = [
        {
            usState: 'NorthCarolina',
            tests: [
               {
                   name: 'Visual Acuity',
                   test: <VisualAcuityTest multiple={true} token={token}   socket={socket} />
               },
               {
                    name: 'Color Vision',
                    test: <ColorBlindnessTest setActiveTest={setActiveTest} activeTest={activeTest} token={token} socket={socket} />
               },
               {
                    name: 'Depth Perception',
                    test: <DepthPerceptionTest setActiveTest={setActiveTest} token={token} socket={socket} />
                },
                {
                    name: 'Visual Field',
                    test: <VisualFieldTest token={token} dimentions={dimentions} socket={socket} />
                },
            ]
        },
        {
            usState: 'florida',
            tests: [
                {
                    name: 'Visual Acuity',
                    test: <VisualAcuityTest token={token} headers={Headers} multiple={true} socket={socket} />
                },
                 {
                     name: 'Visual Field',
                     test: <VisualFieldTest token={token} dimentions={dimentions} socket={socket} />
                 },
             ]
        },
        {
            usState: 'NewYork',
            tests: [
               {
                   name: 'Visual Acuity',
                   test: <VisualAcuityTest token={token} multiple={true} socket={socket} />
               },
            ]
        },
    ]

    useEffect(()=>{
        socket.on('changeTest', test =>{
            setActiveTest(activeTest + 1)
        })  
        socket.on('mobileJoined',()=>{
            if(!isMobile)
                socket.emit('stateflow',token,user.usState,user.test_in_progress)
        })
        socket.once('stateflow', (flow,step)=>{
            if(isMobile){
                setUsState(flow)
                if(step === 0)
                setActiveTest(step)
                else
                setActiveTest(step-1)
            }
        }) 
    })

    useEffect(()=>{
        if(!isMobile && activeTest + 1 > user.test_in_progress)
         axios.post(`${process.env.REACT_APP_SERVER_LINK}/updateTestStep`,{id:user.id,step:activeTest+1})
        let data = {...user, test_in_progress:activeTest+1}

        dispatch(login(data))

        let numbers = document.getElementsByClassName('number')

        for(let i =0 ; i< numbers.length; i++){
            if(i === activeTest)
                numbers[i].classList.add('lnk-blue')
            else
                numbers[i].classList.remove('lnk-blue')
        }
       
    },[activeTest])


    const AlmostTests = () =>{
        if(testFlow){
            if(activeTest === testFlow.tests.length && activeTest > 0)
                {
                    if(isMobile){
                        localStorage.removeItem('sync')
                        localStorage.removeItem('prescription')
                        window.location.href = `${process.env.REACT_APP_MOBILE_LINK}/test-end`
                    }
                    else
                    window.location.href = `${process.env.REACT_APP_LINK}/test-end`
                }
            else
                return testFlow.tests[activeTest].test
        }
    }

    const MobileTests = () =>{
        if(loading)
            return(
                <>
                <div className='loader'>
                    <div className="mini-loader" ref={loader}>

                    </div>
                </div>
                </>
            )
        return(
            AlmostTests()
        )
        
    }

    const DesktopTests = () =>{
     if(testFlow){
            if(activeTest === testFlow.tests.length && activeTest > 0)
                 window.location.href = `${process.env.REACT_APP_LINK}/test-end`
         if(testFlow.tests[activeTest].name === 'Depth Perception' ||  testFlow.tests[activeTest].name === 'Visual Field'){
             return AlmostTests()
         }
         else{
            return (
                <div className="test-setup-container">
                <div className="header">
                   {Headers()}
                </div>
                <div className="test-setup">
                    {
                       AlmostTests()
                    }
                </div>
            </div>
            )
         }
     }
    }

    if(!isMobile)
        return(
            <>
            <Header />
            <div className="App gradient-back">
               {DesktopTests()}
            </div>
            </>
        )
    else
        return MobileTests()
  
}

