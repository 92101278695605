import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Lottie from 'lottie-web'
import {useState,useEffect, useRef} from 'react'
import { BrowserView, isMobile, MobileView } from 'react-device-detect'
import { Link } from 'react-router-dom'
import { Randomize } from '../util/utilities'
import { CountDown } from './CountDown'
import { TestFailed } from './TestFailed'

export const DepthPerceptionTest = (props) => {

    // TODO: implement socket

    const socket = props.socket

    const token = props.token

    var width = window.innerWidth;
    var height = window.innerHeight;

    // declare refs
    const canvasRef = useRef(null)
    const loaderRef = useRef(null)

    // declare test logic states
    const [currentTest, setCurrnetTest] = useState(0)
    const [thick, setThick] = useState()
    const [thickX, setThickX] = useState(0)
    const [thickY, setThickY] = useState(0)
    const [testX,setTestX] = useState(0)
    const [testY,setTestY] = useState(0)
    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(true)

    const [tutorial,setTutorial] = useState(true)
    const [open,setOpen] = useState(false)
    const [warning, setWarning] = useState(0)

    const [message, setMessage] = useState('Which Object is the closest?')

    const [canAnswer, setCanAnswer] = useState(false)

    const [finished, setFinished] = useState(false)
    const [passed, setPassed] = useState(false)
    
    const [countDown, setCountDown]=useState(false)

    const X = <FontAwesomeIcon icon={faTimes} />

    useEffect(()=>{
        Lottie.loadAnimation({
                container: loaderRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../loader.json')

        })
      
    },[])
    
    useEffect(()=>{
        if(loading)
            setTimeout(()=>{
                setLoading(false)
            },1000)
    },[loading])


    const Circles = (width,height) =>{
        switch(currentTest){
            case 0:
                return [
                        {x: width * 0.125 , y: height/2},{x: width * 0.375 , y: height / 2},{x: width * 0.625, y: height /2 },{x: width * 0.875  , y: height /2 }
                    ]
            case 1:
                return [
                        {x: width * 0.125 , y: height * 0.25},{x: width * 0.375 , y: height * 0.25},{x: width * 0.625, y: height * 0.25 },{x: width * 0.875  , y: height * 0.25 },
                        {x: width * 0.125 , y: height * 0.75},{x: width * 0.375 , y: height * 0.75},{x: width * 0.625, y: height * 0.75 },{x: width * 0.875  , y: height * 0.75 }
                ]
            case 2:
                return[
                        {x: width * 0.1 , y: height * 0.25},{x: width * 0.3 , y: height * 0.25},{x: width * 0.5 , y: height * 0.25},{x: width * 0.7, y: height * 0.25 },{x: width * 0.9  , y: height * 0.25 },
                        {x: width * 0.1 , y: height * 0.5},{x: width * 0.3 , y: height * 0.5},{x: width * 0.5 , y: height * 0.5},{x: width * 0.7, y: height * 0.5 },{x: width * 0.9  , y: height * 0.5 },
                        {x: width * 0.1 , y: height * 0.75},{x: width * 0.3 , y: height * 0.75},{x: width * 0.5 , y: height * 0.75},{x: width * 0.7, y: height * 0.75 },{x: width * 0.9  , y: height * 0.75 },,
                ]
            default:
                
        }
    }

    useEffect(()=>{
        if(!isMobile)
        {
            setThick(Randomize(0,Circles().length-1))
            let canv = canvasRef.current
            let ctx = canv.getContext('2d')
    
            ctx.clearRect(0,0,canv.width,canv.height)
    
    
        }
        
    },[currentTest])
    
    useEffect(()=>{
        if(!isMobile){
            console.log(thick)
            let canv = canvasRef.current
            let ctx = canv.getContext('2d')
    
            Circles(canv.width, canv.height).forEach((circle, i) => {
                ctx.beginPath()
                ctx.arc(circle.x,circle.y,50, 0 , Math.PI * 2)
                if(i !== thick){
                    if(tutorial)
                     ctx.lineWidth = 10
                    else{
                        let  rng = Randomize(0,100)
                        if(rng <= 25)
                             ctx.lineWidth = 10
                        else if( rng <= 50)    
                            ctx.lineWidth = 9
                        else if (rng < 75)
                            ctx.lineWidth = 8
                        else
                            ctx.lineWidth = 7
                    }
                }
                else{
                    if(tutorial)
                        ctx.lineWidth = 15
                    else    
                        ctx.lineWidth = 12
                    setThickX(circle.x)
                    setThickY(circle.y)
                }   
    
                ctx.strokeStyle = '#000'
                ctx.stroke()
        });
        }
    },[thick])

    useEffect(()=>{
        if(step>0)
        {
            
            console.log('draw')
            let canv = canvasRef.current
            let ctx = canv.getContext('2d')
    
            ctx.clearRect(0,0,canv.width,canv.height)
    
            let thick = Randomize(0,Circles().length-1)
            console.log(thick)
    
            Circles(canv.width, canv.height).forEach((circle, i) => {
                    ctx.beginPath()
                    ctx.arc(circle.x,circle.y,50, 0 , Math.PI * 2)
                    if(i !== thick)
                        ctx.lineWidth = 10
                    else{
                        ctx.lineWidth = 15
                        setThickX(circle.x)
                        setThickY(circle.y)
                    }   
    
                    ctx.strokeStyle = '#000'
                    ctx.stroke()
            });
        }
    },[step])

    function useKey(key, cb){
        const callbackRef = useRef(cb)


        useEffect(()=>{
            callbackRef.current= cb
        })

        useEffect(()=>{

            function handle(e){
                    setTestX(e.offsetX)
                    setTestY(e.offsetY)
            }
            if(document.getElementById('canvas')){
                document.getElementById('canvas').addEventListener('click', handle)
                return() => document.getElementById('canvas').removeEventListener('click', handle)
            }
        },[key])
   }
   function handleAnswer (){
      
    }
    useKey('click', handleAnswer)

    useEffect(()=>{
        if(testX !== 0){
            let d = Math.sqrt( Math.pow((testX - thickX),2) + Math.pow((testY - thickY),2) )
            console.log(d)
                if(d < 50 || d === 50){
                    if(tutorial){
                        setMessage("Which object is the closest?")
                    }
                    console.log('hit')
                  
                        if(currentTest === 2){
                            if(!tutorial)
                            {
                                setPassed(true)
                                setFinished(true)
                            }
                            else{
                                setFinished(true)
                            }
                        }
                        else{
                            setTimeout(()=>{
                                setCurrnetTest(currentTest + 1)
                            },200)
                        }
                }
                 else{
                     console.log('miss')
                        if(tutorial && canAnswer){
                            setMessage("Don't worry, you'll get it right next time")
                            let canv = canvasRef.current
                            let ctx = canv.getContext('2d')

                            ctx.lineWidth = 15

                            ctx.beginPath()
                            ctx.arc(thickX,thickY,50, 0 , Math.PI * 2)
                            ctx.strokeStyle = '#F16B6B'
                            ctx.stroke()
                        }
                        else{
                            if(canAnswer)
                            setFinished(true)
                        }
                 }
        }
    },[testY])

    const ResetTutorial = () =>{
        setTimeout(()=>{
            setCurrnetTest(0)
        },200)
        setFinished(false)
        setCountDown(true)
    }

    const EndTutorial = () =>{
        setTutorial(false)
        setTimeout(()=>{
            setCurrnetTest(0)
        },200)
        setFinished(false)
        setCountDown(true)
    }

    function ClosePrep(){
        setOpen(false)
        document.getElementById('prep').classList.add('no-display')
        setCountDown(true)
        setTimeout(()=>{
            setCanAnswer(true)
        },200)
    }
    const Warnings = () =>{
        switch(warning){
            case 0:
                return(
                    <>
                        <h3>Photosensitive Seizure Disorder</h3>
                        <p>
                        Have you ever been diagnosed with Photosensitive Seizure Disorder, or have you suffered a seizure in the last six months?
                        </p>
                        <div className='d-flex'>
                            <button onClick={()=> setWarning(1)} className="btn-big btn-prep">YES</button>
                            <button onClick={()=> ClosePrep()} className="btn-big btn-prep">NO</button>
                        </div>
                    </>
                )
            case 1:
                return(
                    <>
                        <h3>This test is not right for you</h3>
                        <p>
                        An eye care provider licensed in state is ready to review your eye vision.
                        </p>
                        <a href="#" className='lnk-blue'>Schedule an In person appointment</a>
                        <Link to={'/'} > <button className='btn-purple btn-big'> Exit </button></Link>
                    </>
                )
        }
    }

    const PostScreens = () =>{
        if(tutorial)
            return(
                <>
                        <h1>Practice Complete</h1>
                        <img src="./images/passed.png" alt="" />
                        <p>You're ready to take the actual test.</p>
                        <div className="buttons">
                            <button onClick={()=> ResetTutorial()} className="btn-blue btn-medium">
                                Practice Again
                            </button>
                            <button onClick={()=> EndTutorial()} className="btn-purple btn-medium">
                                Begin Test
                            </button>
                        </div>
                </>
            )
            else
            {
                if(passed)
                return(
                    <>
                            <h1>You Passed!</h1>
                            <img src="./images/passed.png" alt="" />
                            <p>Congratulations.</p>
                                <button onClick={()=> props.setActiveTest(3)} className="btn-purple btn-medium">
                                    Continue
                                </button>
                    </>
                )
                else
                    return <TestFailed retry={ResetTutorial} />
            }
    }

    return (
        <>
        <BrowserView className='depth-perception-container'>
                <div className={`prep-overlay ${open ? 'open' : ''}`}>
                        <div className="prep-overlay-container">
                            <div className="close" onClick={()=> setOpen(false)}>
                                {X}
                            </div>
                            <div className="header">
                    </div>
                    <div className="preps">
                       <Warnings />
                    </div>
                        </div>
                </div>
                <div className="depth-perception-test">
                    <CountDown load={countDown} setLoad={setCountDown} />
                    <h1>{message}</h1>
                        <canvas id='canvas' ref={canvasRef} width={width/2} height={height/2} className="DepthPerceptionCanvas" ></canvas>
                        <button onClick={()=>  setFinished(true)} className='btn-blue btn-big'>Can't see</button>
                        <p onClick={()=> EndTutorial()} className={tutorial ? 'lnk-blue' : 'no-display'}>Skip Practice</p>
                        <div className={finished ? 'post-overlay finished' : 'post-overlay'}>
                            {PostScreens()}
                        </div>
                </div>
            <div id='prep' className="prep">
                    <h1>About this test</h1>
                    <p style={{margin:'1em auto'}}>
                        This website provides a vision test for individuals who have suffered from a stroke or brain injury who wish to learn more about whether their vision has been impacted. While simpler than a diagnostic test available from physicians and hospitals, this vision test includes key elements of tests that might be used by a physician. We hope the results of your test can help you discuss your vision concerns with your physician.
                    </p>
                    <p style={{margin:'1em auto'}}>
                   <strong> Please Note</strong> - This test does not take the place of a medical exam. It is not intended for use in the diagnosis of disease or other conditions, or in the cure, mitigation, treatment, or prevention of disease. It is not intended to affect the structure or any function of the human body.
                    </p>
                    <button onClick={()=> { setOpen(true)}} style={{marginTop:'1em'}} className="btn-purple btn-big">
                        Practice First
                    </button>
                    <br/>
                    <button onClick={()=> {setTutorial(false); setOpen(true)}} style={{marginTop:'1em'}} className="btn-blue btn-big">
                       Skip Practice
                    </button>
                   
            </div>
        </BrowserView>
        <MobileView>
            <div className="App gradient-back">
                <h1>Continue on your Desktop</h1>
            </div>
        </MobileView>
       </>
    )
}

