import { useState } from "react";
import { Recovery } from "../util/auth";
import {Header} from './Header'

export const PasswordReset = () => {
    const [email,setEmail] = useState('')

    const [confirmation,setConfirmation] = useState(false)

    const [err,setErr] = useState('')

    function TryReset(){
        Recovery(email).then(res =>{
            console.log(res)
            if(res.status === 200){
                setErr('')
                setConfirmation(true)
            }
            else{
                setErr('A user with this email does not exist.')
            }
        })
    }

    return (
        <>
        <Header />
        <div className='App gradient-back'>
                <div className={`confirmation ${confirmation ? 'popup' : ''}`}>
                    <img src="./images/email.png" alt="confirmation" />
                    <h1>You've got mail</h1>
                    <p>Please check your inbox to reset your password</p>
                    <button onClick={() => setConfirmation(false)} className='btn-purple'>Close</button>
                </div>
                <form className="login-form reset" onSubmit={e=> {e.preventDefault();TryReset()}}>
                    <h1>Reset your Password</h1>
                    <p>You’ll receive you an email to help you reset your password.</p>
                <div className="form-row">
                    <input placeholder="Email" type="email" onChange={e => setEmail(e.target.value)} className="input-full" />
                </div>
                <input type="submit" className="btn-purple" value='Submit' />
                <h3 className='errorMsg'>{err}</h3>
            </form> 
        </div>
        </>
    )
}

