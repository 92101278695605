import axios from "axios";

const config = {
  headers: {
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': '*',
    }
  }

export async function SignUp(data){

   let post = await axios.post(`${process.env.REACT_APP_SERVER_LINK}/register`,data,config)
                .then()
                .catch(function (error) {
                    console.log(error);
                  });

   return post
}

export async function LogInUser(data){
    let post = await axios.post(`${process.env.REACT_APP_SERVER_LINK}/login`, data , config)
                  .then()
                  .catch(function (error){
                    console.log(error)
                  })
    return post
}
export async function Recovery(email){

    let data = {
      Email: email
    }
    let post = await axios.post(`${process.env.REACT_APP_SERVER_LINK}/recovery`, data , config)
    return post
}
export async function ChangePassword(data){

  let post = await axios.post(`${process.env.REACT_APP_SERVER_LINK}/change-password`, data , config)
  return post
}
export async function UpdateUserData(data){
  await axios.post(`${process.env.REACT_APP_SERVER_LINK}/update-user`,data,config)
}