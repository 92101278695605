import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

export const Aknowledgement = (props) => {

    const [open,setOpen] = useState(false)
    const [ans,setAns] = useState(0)

    const X = <FontAwesomeIcon icon={faTimes} />

    function buttonPress(e,check){

        let buttons = e.target.parentElement.getElementsByTagName('button')

        buttons[0].classList.remove('active')
        buttons[1].classList.remove('active')


       e.target.classList.add('active')

        setAns(check)
    }

    function Proceed(){
        if( ans === 2 )
            props.setActivePrep(2)
    }

    return (
        <>
        <div className={`prep-overlay ${open ? 'open' : ''}`}>
                <div className="prep-overlay-container">
                    <div className="close" onClick={()=> setOpen(false)}>
                        {X}
                    </div>
                    <div className="header">
                   <h1>
                   Aknowledgement
                   </h1>
               </div>
               <div className="prep-test">
                   <h3>Will this vision test replace a comprehensive eye exam?</h3>
                  <div className='d-flex'>
                      <button onClick={(e)=> buttonPress(e,1)} className="btn-big btn-prep">YES</button>
                      <button onClick={(e)=> buttonPress(e,2)} className="btn-big btn-prep">NO</button>
                  </div>
                      <h3 style={{color: 'red'}} className={ans ===  2 || ans === 0 ? 'no-display' : ''}>INCORRECT</h3>
                   <button onClick={()=> Proceed()} className="btn-purple btn-medium">Continue</button>
               </div>
                </div>
           </div>
            <div className="row">
                <div className='input-half'>
                    <h1>The visibility Test 
                        <br />
                        <span style={{color:'green'}}>IS</span>:
                    </h1>
                    <ul>
                        <li>
                        Used to asses elements of visual acuity 
                        </li>
                        <li>
                        Intended for use by adults ages 18 - 55
                        </li>
                        <li>
                        A self guided, online test
                        </li>
                    </ul>
                </div>
                <div className='input-half'>
                <h1>The visibility Test 
                        <br />
                        IS <span style={{color:'red'}}>NOT</span>:
                    </h1>
                    <ul>
                        <li>
                        Meant to perform or replace an initial eye examination
                        </li>
                        <li>
                        A replacement for a comprehensive eye test.
                        </li>
                        <li>
                        A way to asses eye health.
                        </li>
                    </ul>
                </div>
            </div>
                <p>
                DMV Eye Test Digital Vision Test Products are manufactured and distributed by DMV Eye Test, Inc. and operates under the <a href='#' className='lnk-blue' target="_blank"> Enforcement Policy for Remote Ophthalmic Assessment and Monitoring Devices during the Coronavirus Disease (COVID-19) Public Health Emergency</a> issued by the FDA in April 2020. This product has not been approved by the FDA. The data and recommendations made by the Visibly Digital Acuity Product are adjunctive (supporting) recommendations that will be used by an Eye Care Provider, along with your medical history and profile, prior corrective eyewear prescriptions, and subjective data provided about your vision. It is the responsibility of the patient to provide accurate information through the process as all of this information will be relied upon as accurate by the reviewing Eye Care Provider.
                </p>
                <p>
                For more information on the New York State DMV Eye Test Version 1, visit our <a href="#" target='_blank' className='lnk-blue'> Device Information Page</a>.
                </p>
                <a href="#root"><button onClick={()=> setOpen(true)} className="btn-purple btn-big">Continue</button></a>
        </>
    )
}

