import { createSlice } from "@reduxjs/toolkit";


export const SyncSlice = createSlice({
    name: 'sync',
    initialState:{
        sync: {
            token: '',
            code: ''
        }
    },
    reducers:{
        setSync: (state,action) => {
                state.sync = action.payload;
        },
        removeSync: (state)=>{
            state.sync = null;
        }
    }
})

export const {setSync, removeSync} = SyncSlice.actions;

export const getSync = (state) => state.sync.sync;

export default SyncSlice.reducer;