import React, {useState} from 'react'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Header } from './components/Header';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  

  
  
  
  function App() {
    
  const [step] = useState(0)
  const [overlayOpen, setOverlayOpen] = useState(false)

  const X = <FontAwesomeIcon icon={faTimes} />

  switch(step){
    case 0: {
      return (
        <>
         <div className="container">
            <section className="section-1">
              <Header />
              <div className="spacer"></div>
              <h1>Renew your Driver’s Licence 
                      <br/><span>from your PC</span></h1>
                  <h3>Only pay if you pass!</h3>
                  <div style={{display:'flex',gap:'5vmin'}}>
                  <Link to={'/test-preparations'}><button  className="btn-purple">Take test</button></Link>
                  <a href="#how-it-works"><button className='btn-blue'>How it works?</button></a>
                  </div>
                  <div className="spacer"></div>
                  <div className="popup-bar">
                    <div className="popup-container">
                      <img src="./images/quickneasy.png" alt=""></img>
                        <p>Quick and Easy</p>
                        <div className="spacer"></div>
                    </div>
                    <div className="popup-container">
                      <img src="./images/house.png" alt=""></img>
                        <p>from your home’s safety</p>
                        <div className="spacer"></div>
                    </div>
                    <div className="popup-container">
                      <img src="./images/result.png" alt=""></img>
                        <p>certified DMV results</p>
                        <div className="spacer"></div>
                    </div>
                  </div>
            </section>
            <section  id='how-it-works' className="section-2">
              <div className="circle"></div>
                <div className="cont">
                  <h1>
                    <span  className="title">FREQUENTLY ASKED</span>
                      <br /> <span className="blue">HOW</span> IT WORKS? 
                  </h1>
                  <p>Easy, all you need to start is a computer and a working phone with you, as well as Internet connection.
                      <br />
                      <br />

                     First we’ll ask you to fill in some data so we can begin with the documentation while you take the test. We’ll ask you to help us measure how big your screen is.
                     <br />
                     <br />
                      It only takes a few minutes and you can finish the rest of your renewal online as soon as you pass!</p> 
                  <a style={{zIndex:'10'}} href="#read-more"><button style={{zIndex:'10',marginTop:'10vmin'}} className="btn-purple btn-big">Read More</button></a>
                </div>
                <div className="spacer"></div>
            </section>
            <section id="read-more" className='section-3'>
              <div className="gradient"></div>
              <h1>
                WHAT THE TEST <span className="blue"> MEASURES:</span>
              </h1>
                    <div className="conte">
                      <div className="img-card" variant="elevation">
                          <div className="holder">
                            <img src="./images/depth.png" alt="" />
                          </div>
                          <p>DEPTH PERCEPTION</p>                        
                      </div>
                      <div className="img-card" variant="elevation">
                      <div className="holder">
                            <img src="./images/FOV.png" alt="" />
                          </div>
                          <p>FIELD <br/> OF VISION</p>
                      </div>
                      <div className="img-card" variant="elevation">
                      <div className="holder">
                            <img src="./images/VA.png" alt="" />
                          </div>
                          <p>VISUAL <br/> ACUITY</p>
                      </div>
                      <div className="img-card" variant="elevation">
                      <div className="holder">
                            <img src="./images/cblind.png" alt="" />
                          </div>
                          <p>COLOR <br/> VISION</p>
                      </div>
                    </div>
                    <Link to={'/test-preparation'}> <button className="btn-purple btn-big">Take Test</button> </Link>
                    <div className="spacer"></div>
            </section>
            <section className="section-3">
               <div className={`overlay ${overlayOpen ? 'overlay-open' : ''}`}>
                  <div className="overlay-content">
                  <div className="close" onClick={()=> setOverlayOpen(false)}>
                      {X}
                  </div>
                  <form action={''}>
                    <h1>
                          <br />GET <span className="blue">SVP</span> UPDATES 
                    </h1>
                      <div className="form-row">
                          <input className='input-half' placeholder='Full Name' type="text" />
                          <input className='input-half' placeholder='State' type="text" />
                      </div>
                      <div className="form-row">
                         <input className='input-half' placeholder='Email' type="email" />
                         <input className='input-half' placeholder='Zipcode' type="phone"  />
                      </div>
                      <div className="form-row">
                         <input type="text" placeholder='# of members' className="input-short" />
                         <input type="text" placeholder="Insurance" className="input-long" />
                      </div>
                      <button className="btn-purple">Subscribe</button>
                    </form>
                  </div>
              </div>
              <div className="circle"></div>
              <div className="spoacer"></div>
              <img style={{zIndex:'2'}} src="./images/NurseHome.svg" alt="Nurse" />
              <h1 style={{textTransform:'uppercase'}}>
                <span className="title">want more?</span> <br />
               try our <span className="blue"> sharp vision plan</span>
              </h1>
              <p>
              Introducing Sharp Vision Plan, the only eyecare plan that covers all your optometric needs. With a subscription to Sharp Vision Plan, you get matched with top doctors in your area for free annual comprehensive eye exams, $250 off lenses and frames, and DMV license renewal tests for free. Add family members or dependents to your plan and manage them all in one place!
              </p>
              <p>Coming Soon</p>
            <button style={{zIndex:'2'}} onClick={()=> setOverlayOpen(true)} className="btn-purple btn-big">Get Notified</button>
            <a style={{zIndex:'2'}} href="#contact-us"> <button className="btn-blue btn-big">Contact us</button></a> 
              <div className="spoacer"></div>
            </section>
            <section id='contact-us' className="section-4">
                <div className="card">
                  <div className="left">
                      <h1>
                            GET IN
                            <br /><span className="blue">TOUCH</span>
                      </h1>
                      <p>Get your answers answered in the following form and we’ll get back to you  soon.</p>
                  </div>
                  <div className="right">
                      <form action={''}>
                          <div className="form-row">
                                  <input placeholder='Full Name' className='input-half' type="text" />
                                  <input placeholder="Email" className='input-half' type="Email" />
                          </div>
                          <div className="form-row">
                              <textarea placeholder="Message" className="input-full" rows={10} />
                          </div>
                          <div className="form-row">
                            <div className="spacer"></div>
                            <button className="btn-purple">Submit</button>
                          </div>
                      </form>
                  </div>
                </div>
                <img className='logo' src="./images/logo.png" alt="DMVEYETEST" />
            </section>
         </div>
        </>
      )
    }
    case 1:
        return <Redirect to={'/test-set-up'} />
    default:
  }
  
}

export default App;
