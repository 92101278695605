import '../../PDFStyles/NewYork.css'

export const NewYorkTemplate = ({transaction, user}) => {
    return (
        <>
            <img src="./images/pdfs/newYork.jpg" alt="New York Form" />
            <p className="FirstName"> {transaction.first_name} </p>
            <p className="lastName">{transaction.last_name}</p>
            <p className='DOBDay'>{transaction.date_of_birth.split('/')[0]}</p>
            <p className='DOBMonth'>{transaction.date_of_birth.split('/')[1]}</p>
            <p className="DOBYear">{transaction.date_of_birth.split('/')[2]}</p>
            {
                transaction.gender ?
                <p className="FemaleCheck">&#x2713;</p>
                :
                <p className="MaleCheck">&#x2713;</p>
            }
            <p className='Address'>{transaction.address}</p>
            <p className='AptNum'>{transaction.aptNum}</p>
            <p className='City'>{transaction.city}</p>
            <p className='State'>New York</p>
            <p className='Country'>{transaction.country}</p>
            <p className='Zip'>{transaction.zipCode}</p>
            <p className='DateM'>{transaction.date.split('/')[0]}</p>
            <p className='DateD'>{transaction.date.split('/')[1]}</p>
            <p className='DateY'>{transaction.date.split('/')[2]}</p>
            <p className="AcuityTestYes">&#x2713;</p>
            {
                transaction.prescription?
                <p className="LenseCheckYes">&#x2713;</p>
                :
                <p className="LenseCheckNo">&#x2713;</p>
            }
            <p className='DoctorName'>Doctor Name</p>
            <p className='DoctorAddress'>Doctor Address</p>
            <p className='DoctorCity'>Doc. City</p>
            <p className='DoctorState'>New York</p>
            <p className='DoctorCountry'>Doc. Country</p>
            <p className='DoctorZip'>Doc. zip</p>
            <p className="Valid12">&#x2713;</p>
            <p className="Valid6">&#x2713;</p>
            <p className="DoctorSign">Doctor Signarure</p>
            <p className="DoctorLicense">12345678</p>
        </>
    )
}

