import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { PaymentForm } from "./PaymentForm"

export const StripeContainer = () => {

    const PK = 'pk_test_uBakHeoVPVlrAJI5YpSaCBKB00ikRDM06q'

    const stripeTestPromise = loadStripe(PK)

    return (
       <Elements stripe={stripeTestPromise}>
           <PaymentForm />
       </Elements>
    )
}

