import { useState } from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectUser } from "../store/features/UserSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faCaretUp, faUser } from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from "react-redux"
import { logout } from '../store/features/UserSlice'


export const Header = () => {

    const user = useSelector(selectUser)

    const dispatch = useDispatch()

    const [dropDownOpen,setDropDownOpen] = useState(false);

    const userIcon = <FontAwesomeIcon icon={faUser} />
    const dropDownSelector = dropDownOpen ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />

    const RightHeader = () =>{
        if(user)
            return(
                <>
                    <Link to={'/test-preparations'}> <button className="btn-purple btn-medium">Begin Test</button> </Link>
                    <div onClick={()=> setDropDownOpen(!dropDownOpen)} className="user-info">
                     <p>{userIcon} &nbsp; {user.firstName}  &nbsp; {dropDownSelector}</p>
                    </div>
                    <div className={`userDropDown ${dropDownOpen ? '': 'closed'}`}>
                        <Link to={'/dashboard'}> Dashboard </Link>
                        <a href="" onClick={()=> dispatch(logout())}> Log out </a>
                    </div>
                </>
            )
        else
            return(
                <>
                    <Link to={'/privacy-and-terms'}>Privacy and Terms</Link>
                    <Link to={'/login'}>Log In</Link>
                    <Link to={'/registration'}> <button className="btn-purple btn-medium">Sign Up</button> </Link>
                </>
            )
    }

   

    return (
       <header id='header'>
                <Link to={'/'}>
                     <img src={`${process.env.REACT_APP_LINK}/images/logo.png`} alt="DMV Eye test" />
                </Link>
                <div className="header-links">
                       {RightHeader()}
                </div>  
       </header>
    )
}

