import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { LogInUser } from "../util/auth"
import { login } from '../store/features/UserSlice'
import { Header } from "./Header"

export const LogIn = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errmsg,seterrmsg] = useState('')
    const dispatch = useDispatch();
    const [name, setName] = useState()
    const [confirmed, setConfirmed] = useState()


    const urlSearchParams = new URLSearchParams(window.window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());


    const Headers = () =>{
        if(confirmed)
            return(
                <>
                    <h1>Email confirmed!</h1>
                    <p style={{marginBottom:'1rem'}}>you can log in now.</p>
                </>
            )
        else{
            if(name)
                return(
                    <>
                          <h1>{`Welcome back, ${name}`}</h1>
                    </>
                )
            else
                return <h1> Welcome! </h1>
        }
    }

    function AttemptLogIn(){
        let data = {
            Email: email,
            Password: password
        }

       LogInUser(data).then( res =>{
           data = res.data
           if(res.status === 201){
                seterrmsg('Validate your email to log in');
           }
           else
             if(res.status === 202)
                seterrmsg('invalid Email or Password');
            
            else
                if(res.status === 203)
                    seterrmsg('No User found with that email address.')
                else{
                let userData ={
                    id: data.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    address: data.address,
                    usState: data.state,
                    zipCode: data.zipCode,
                    city: data.city,
                    phoneNumber: data.phoneNumber,
                    email: data.email,
                    frontLicense: data.license_front_id,
                    backLicense: data.license_back_id,
                    licenseNum: data.licenseNum,
                    loggedIn: true,
                    tests_taken: data.tests_taken,
                    tests_passed: data.tests_passed,
                    test_in_progress: data.test_in_progress
                    }
                    localStorage.setItem('user', JSON.stringify(userData))
                dispatch(
                    login(userData)
                    )}
       })

    }

    useEffect(()=>{
        setName(params.name)
        setConfirmed(params.confirmed)
    },[])

    return (
        <>
        <Header />
        <div className="App gradient-back">
            <form className="login-form" onSubmit={e=> {e.preventDefault();AttemptLogIn()}}>
                {Headers()}
                <div className="form-row">
                    <input placeholder="Email" type="email" onChange={e => setEmail(e.target.value)} className="input-full" />
                </div>
                <div className="form-row">
                    <input placeholder="Password" type="password" onChange={e => setPassword(e.target.value)} className="input-full" />
                </div>
                <Link to={'/password-reset'}>Forgot Password?</Link>
                <input type="submit" className="btn-purple btn-medium" value='Log In' />
                <div className="form-row bot"></div>
                <p>New User?</p>
                <Link className="lnk-blue" to={'/registration'}>Sign Up</Link>
                 <h3>{errmsg}</h3>
            </form> 
        </div>
       </>
    )
}

