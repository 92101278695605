import { Card, Button } from '@material-ui/core'
import React,{useState,useEffect, useRef} from 'react'
import { BrowserView, isMobile, MobileView } from 'react-device-detect'
import { Randomize } from '../util/utilities'
import { TestFailed } from './TestFailed'
import glasses from '../glasses.svg'
import lenses from '../lenses.svg'
import prep from '../VisualPrep.svg'
import nurse from '../NursePatch.svg'
import nurseBoth from '../Nurse.svg'
import { CountDown } from './CountDown'
import Lottie from 'lottie-web'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SVP from '../SVP.svg'
import notPassed from '../not-passed.svg'
import { Link } from 'react-router-dom'



export const VisualAcuityTest = (props) => {
    
    // declare socket for server interactions
    const socket = props.socket

    const token = props.token
    
    // Declare the test Logic states

    const [multiple] = useState(props.multiple ? true : false)

    const [multipleCounter, setMultipleCounter] = useState(0)

    const [correct,setCorrect] = useState(0)
    const [incorrect,setIncorrect] = useState(0)
    const [message, setMessage] = useState('')
    const [position, setPosition] = useState(0)

    const [tempAnswer, setTempAnswer] = useState(0)

    const [answer,setAnswer] = useState(0)
    const [loading, setLoading] = useState(true)

    const [countDown, setCountDown] = useState(false)
    
    // Declare the test flow step    
    const [appstep, setAppStep] = useState(0)

    const [isPassed,setIsPassed] = useState(0)

    const [canAnswer,setCanAnwer] = useState(true)

    const [tutorial, setTutorial] =  useState(true)
    const [prescription, setPrescription] = useState()

    const [prepCounter,setPrepCounter] = useState(0)

    const loader = useRef(null)

    var animation
    useEffect(()=>{
        animation = Lottie.loadAnimation({
                container: loader.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../loader.json')

        })
    },[])

    useEffect(()=>{
        if(appstep === 1){
            setCountDown(true)
        }
    },[appstep])

    useEffect(()=>{
       console.log('isPassed:',isPassed)
    },[isPassed])
    // Listen for user answer or change of the flow step
    useEffect(()=>{
        socket.once('failed',()=>{
               setIsPassed(2)
            })
        socket.on('visualStep', Vstep=>{
            setAppStep(Vstep)
        })
        socket.once('answer', ans=>{
            if(canAnswer && !isMobile){
                if(tempAnswer === ans)
                    setTempAnswer(-1)
                else 
                    setTempAnswer(ans)
            }
        })
        socket.on('passed', ()=>{
            setIsPassed(1)
        })

       
        socket.on('tutorialIncorrect',()=>{
            if(isMobile && document.getElementById('tutorial-incorrect'))
                document.getElementById('tutorial-incorrect').classList.remove('low-opacity')
        })
        socket.on('tutorialCorrect',()=>{
            if(isMobile && document.getElementById('tutorial-incorrect'))
                document.getElementById('tutorial-incorrect').classList.add('low-opacity')
        })
        socket.once('endTutorial',()=>{
            setTutorial(false)
            setIsPassed(0)
            setCorrect(0)
            setIncorrect(0)
            setMessage(0)
            setPosition(Randomize(1,4))
            if(multiple){
                if(isMobile){
                   setCountDown(true)
                   setAppStep(1)
                   console.log('what')
                }
            }
            else{
                ChangeAppStep(1)
            }
        })
        socket.once('pcStep',()=>{
            if(!isMobile){
            ChangeAppStep(1)
            setCountDown(true)
            }
        })
        socket.once('reset',()=>{
            Reset(1)
        })
        socket.on('changeEye',()=>{
            setMultipleCounter(multipleCounter +1)
        })
    })


    // initialize the starting position for the test
    useEffect(()=>{
        if(isMobile){
            socket.emit('mobileLoaded',token) // signal that mobile loaded
            if(JSON.parse(localStorage.getItem('prescription')))
                setPrescription(JSON.parse(localStorage.getItem('prescription')).prescription)
            socket.emit('join',token)
        }

        console.log(props.headers)
        setPosition(Randomize(1,4))
    },[])
    // Change flow step to declared step
    function ChangeAppStep(step){
        socket.emit('visualStep',token,step)
      }
    
    // Reset if Failed
    function Reset(num=1,check=true){
        setIsPassed(0)
        setCorrect(0)
        setIncorrect(0)
        setMessage(0)
        if(check)
            setPrepCounter(0)
        setPosition(Randomize(1,4))
        ChangeAppStep(num)
    }

    // Change what is displayed on each step
    function SlideOne(){
        switch(appstep){
            default:
            case 0: return('one-0')
            case 1: return('one-1')
            case 2: return('one-2')
        }
    }
    function SlideTwo(){
        switch(appstep){
            default:
            case 0: return('two-0')
            case 1: return('two-1')
            case 2: return('two-2')
        }
    }
    function SlideThree(){
        switch(appstep){
            default:
            case 0: return('three-0')
            case 1: return('three-1')
            case 2: return('three-2')
        }
    }
    // Change orientation of the C
    const LandotChanger = (index) =>{
        switch(index){
            default:
            case 1: return 'landot-up'
            case 2: return ''
            case 3: return 'landot-down'
            case 4: return 'landot-left'
        }
    }

    //Change the display so the C doesn't snap in place
    function ChangeQuestion(){
            if(loading)
                return 'transition'
            else
                return LandotChanger(position)
    }

    //Test logic declaration
    function TestLogic(){
        if(answer === position)
           { setCorrect(correct+1);
            console.log('correct', correct+1)
        }
        else
            {setIncorrect(incorrect+1)
           
        }
       setPosition(Randomize(1,4))
    }
    function TutorialLogic(){
        if(answer === position){
            setCorrect(correct+1)
            socket.emit('tutorialCorrect',token)
            setPosition(Randomize(1,4))
        }
        else{
            setCorrect(0)
            socket.emit('tutorialIncorrect',token)
        }
    }

    useEffect(()=>{
        console.log('pos:',position)
    },[position])

    // Apply test logic on each answer

    useEffect(()=>{
        if(tempAnswer !== -1)
          setAnswer(tempAnswer)
        else{
            if(appstep !== 0)
            {
                if(!tutorial)
                    TestLogic()
                else
                    TutorialLogic()
            }
        }
      },[tempAnswer])

    useEffect(()=>{
        console.log('answer', answer)
        if( appstep !== 0)
        {
            if(!tutorial)
                TestLogic()
            else
                TutorialLogic()
        }
    },[answer])


    // Handle user passing the test
    useEffect(()=>{
        if(tutorial){
            if(correct === 3)
                {
                    socket.emit('passed',token)
                }
        }
        if(correct > 7 ){
            if(!multiple)
                socket.emit('passed',token)
            else{
                if(multipleCounter === 2)
                    socket.emit('passed', token)
                else{
                    if(tutorial)
                        socket.emit('passed',token)
                    else    
                    socket.emit('changeEye',token)
                }
            }
        }
    },[correct])

    useEffect(()=>{
        console.log('counter', multipleCounter)
        if(multipleCounter !== 0)
            Reset(0,false)
    },[multipleCounter])

    // Handle user Failing the test
    useEffect(()=>{
        if(incorrect === 3)
            socket.emit('failed',token)
    },[incorrect])

    // handle loading transitions
    useEffect(()=>{
        if(loading)
        {
            setTimeout(()=>{
                setLoading(false)
                setCanAnwer(true)
            },2100)
        }
    },[loading])

    const PrepScreen = () =>{
        if(!multipleCounter){
            if(prescription)
                    {
                        return (
                            <>
                                <div className="col-m">
                                    <img src={ prescription === 'glasses' ? glasses : lenses} alt={prescription} style={{width: '50%' ,maxWidth: '200%'}} />
                                    <h1 style={{textTransform: 'capitalize', textAlign: 'center'}}>
                                        Wear your {prescription} now Please
                                    </h1>
                                    <p>We'll measure if you could see better!</p>
                                    <button className='btn-purple btn-big' onClick={()=> setPrescription()}>
                                        Next
                                    </button>
                                </div>
                            </>
                            )
                    }
                    else 
                    {
                        return (
                            <>
                                <div className="col-m">
                                    <h1 style={{textTransform: 'capitalize', textAlign: 'center'}}>About the test</h1>
                                    <ul style={{width: '80%'}}>
                                        <li style={{marginBottom: '1em'}}>Select the orientation of the C that best matches what you can see on your monitor</li>
                                        <li style={{marginBottom: '1em'}}>Select the orientation of the C that best matches what you can see on your monitor</li>
                                        <img style={{width:'80%'}} src={prep} alt="" />
                                        <li style={{marginBottom: '1em'}}>Get 3 correct in a row to continue test.</li>
                                        <li style={{marginBottom: '1em'}}>Take the practice test as many times as you want.</li>
                                    </ul>
                                        <button onClick={()=>{ChangeAppStep(1);}} className='btn-purple btn-big'>Practice First</button>
                                        <button onClick={()=>socket.emit('endTutorial',token)} className='btn-blue btn-big'>Skip Practice</button>
                                </div>
                            </>
                            )
                    }
        }
        else if(multipleCounter === 1){
            return(
                <>
                    <div className="App gradient-back col">
                        <h1 style={{textTransform: 'capitalize', textAlign: 'center'}}>Please cover your left eye</h1>
                        <img src={nurse} style={{transform: 'scaleX(-1)'}} alt="" />
                        <button onClick={()=>{ChangeAppStep(1)}} className='btn-purple btn-big'>Done</button>
                    </div>
                </>
            )
        }
        else
        return (
            <>
            <div className="App gradient-back col">
                <h1 style={{textTransform: 'capitalize', textAlign: 'center'}}>Now take the test with both eyes uncovered</h1>
                <img  src={nurseBoth} alt="" />
                <button onClick={()=>{ChangeAppStep(1)}} className='btn-purple btn-big'>Done</button>
            </div>
        </>
        )
    }


    const MobileDisplays = () =>{
        switch(isPassed){
            case 0:{
                switch(appstep){
                    default:
                    case 1:{
                        if(!prepCounter && !tutorial && multiple)
                                return(
                                    <>
                                    <div className="App gradient-back col">
                                        <h1 style={{textTransform: 'capitalize', textAlign: 'center'}}>Please cover your right eye</h1>
                                        <img src={nurse} alt="" />
                                        <button onClick={()=>{setPrepCounter(1);socket.emit('pcStep',token)}} className='btn-purple btn-big'>Done</button>
                                    </div>
                                    </>
                                )
                        else
                        return(
                            <>
                            <CountDown load={countDown} setLoad={setCountDown} />
                            <Card variant="outlined" className="m-sync-card">
                                <div className={loading ? 'loader-text' : 'loader-text loader-off'}>
                                        <h1>Loading...</h1>
                                </div>
                            <Button onTouchEnd={()=> {setLoading(true) ;socket.emit('answer',token, 1)}} className="c up" color="primary" variant="contained"><span>C</span></Button>
                                <div className="separate-c">
                                <Button onTouchEnd={()=> {setLoading(true) ;socket.emit('answer',token, 4)}} className="c left" color="primary" variant="contained"><span>C</span></Button>
                                <Button onTouchEnd={()=> {setLoading(true) ;socket.emit('answer',token, 2)}} className="c right" color="primary" variant="contained"><span>C</span></Button>
                                </div>
                            <Button onTouchEnd={()=> {setLoading(true) ;socket.emit('answer',token, 3)}} className="c down" color="primary" variant="contained"><span>C</span></Button>
                            <p id='tutorial-incorrect' className='low-opacity' style={{color: 'red', margin:'1em 0'}}>incorrect Please try again</p>
                            <Button color="primary" onTouchEnd={()=> {setLoading(true) ;socket.emit('answer',token, 5)}} variant="contained">Can't See</Button>
                            </Card>
                            </>
                        )
                    }
                    case 0:{
                        return(
                          PrepScreen()
                        )
                    }
                    case 2:{
                        return(
                        <h1>Continue on your Desktop</h1>
                        )
                    }
                    }
            }
            case 1:{
                if(tutorial)
                    return(
                        <>
                        <div className="App gradient-back col col-fail">
                            <h1>Practice Complete</h1>
                            <img src="./images/passed.png" alt="" />
                            <p>You’re ready to take the actual test.</p>
                            <button onClick={()=> {socket.emit('endTutorial',token)}} className="btn-purple btn-big">Begin Test</button>
                            <button onClick={()=> {socket.emit('reset',token)}} className="btn-blue btn-big">Practice Again</button>
                        </div>
                        </>
                    )
                else
                    return(
                        <>
                        <div className="App gradient-back col col-fail">
                            <h1>You passed!</h1>
                            <img src="./images/passed.png" alt="" />
                            <p>Congratulations. </p>
                            <button onClick={()=> socket.emit('nextTest',token,1)} className="btn-purple btn-big">Continue</button>
                        </div>
                    </>
                    )
            }
            case 2: return (
                <div className="App gradient-back col col-fail" style={{height:'100% !important'}}>
                    {Views()}
                </div>
            )
        }
    }
    const [inPerson,setInPerson] = useState(false)

    const Views = () =>{
        if(!inPerson)
            return (
                <>
                <h1>
                Test not Passed
                </h1>
                <img src={notPassed} alt="" />
                <p>An eye care provider licensed in state is ready to review your results.</p>
                <p onClick={()=> setInPerson(true)} style={{textDecoration: 'underline', fontWeight: 'bold'}}>
                Schedule an In person appointment
                </p>
                <div onClick={()=> {socket.emit('endTutorial',token); Reset(1)}} className="btn-purple btn-big">Retry</div>
                <Link style={{textDecoration:'none'}} to={'/'}><div className="btn-blue btn-big">Exit</div></Link>
             </>
            )
        else
            return(
                <>
                <h1>Sign up to SVP</h1>
                <img src={SVP} alt="" />
                <div className='svp-text'>
                    <p>
                    <FontAwesomeIcon icon={faCheck} /> &nbsp;  &nbsp;  &nbsp;    Complimentary Annual Vision Exam.
                    </p>
                    <p>
                    <FontAwesomeIcon icon={faCheck} /> &nbsp;  &nbsp;  &nbsp;    Take the DMV Eye Test for free (new states added weekly).
                    </p>
                    <p>
                    <FontAwesomeIcon icon={faCheck} /> &nbsp;  &nbsp;  &nbsp;    $250 off of lenses and frames anually.
                    </p>
                </div>
                <div>
                    <p>Comming soon</p>
                    <button className="btn-purple btn-big">Get notified</button>
                </div>
             </>
            )
    }

    //Declare mobile displays depending of the step of the flow
      
    return (
        <>
        <BrowserView className='sync-box' style={{width: '100%'}}>
        <div className="wrap-acuity">
            <div className="test-app">
                <div className={`slide one ${SlideOne()}`}>
                    <h3>Continue On Your Device</h3>
                </div>
                <div className={`slide ${SlideTwo()}`}>
                    <Card className="test-card landot" variant="outlined">
                    <CountDown load={countDown} setLoad={setCountDown} />
                            <h1 className={ChangeQuestion()} style={{fontSize: tutorial ? '10vmin' : '4vmin'}}>C</h1>
                    </Card>
                </div>
                <div className={`slide ${SlideThree()}`}>
                    <Card className="test-card" variant="outlined">
                            <h1>{message}</h1>
                            <Button onClick={()=> Reset()} color="primary" variant="contained">Go Back</Button>
                    </Card>
                </div>
            </div>
        </div>
        </BrowserView>
        <MobileView>
            <div className="App gradient-back">
                {
                   MobileDisplays()
                }
            </div>
        </MobileView>
        </>

    )
}

