import React, { useEffect, useState } from 'react'
import {ScreenCalibration} from './ScreenCalibration'
import {MobileSync} from './MobileSync'
import { Calibration } from './Calibration'
import { useDispatch } from "react-redux"
import { setSync } from '../store/features/SyncSlice';
import { SocketConnect } from '../util/utilities'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import img from '../paymentSuccess.svg'
import { Header } from './Header'

export const TestSetUp = () => {
 
    const socket = SocketConnect()

    const X = <FontAwesomeIcon icon={faTimes} />

    const [overlayOpen, setOverlayOpen] = useState(false)
    const [overlay, setOverlay] = useState(0)

    const [blackCounter, setBlackCounter] = useState(0)

    const [scale,setScale] = useState(2)
    const [imageWidth, setImageWidth] = useState()
    const [imageHeight, setImageHeight] = useState()
    const [cardWidth , setCardWidth] = useState(400);
    const [cardHeight, setCardHeight] = useState(250);

    const GetScreenDimentions = () =>{
        let wide = 3.37;
        let high =  2.215;
        let viewportWidth = window.screen.width
        let viewportHeight = window.screen.height

        
        let monitorWidth = (viewportWidth * wide)/(cardWidth*scale);
        let monitorHeight = (viewportHeight * high)/(cardHeight*scale);
        
        setImageWidth( (viewportWidth * 6.14) / monitorWidth )
        setImageHeight( (viewportHeight * 2.61) / monitorHeight )

        const diagonal = Math.floor(Math.sqrt(Math.pow(monitorWidth,2)+Math.pow(monitorHeight,2)))

        localStorage.setItem('dimentions', JSON.stringify({
            height: Math.floor(monitorHeight),
            width: Math.floor(monitorWidth),
            diagonal: Math.floor(diagonal)
        }))
    }
    useEffect(()=>{
        if(imageWidth && imageHeight){
            setOverlay(1)
        }
    },[imageWidth,imageHeight])
 
    // make token for mobile sync
 function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
      charactersLength));
    }
    return result;
  }
  
  
  //make code for mobile sync
  function makeCode(length) {
    var result           = '';
    var characters       = '0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
      charactersLength));
    }
    return result;
  }
  
  const dispatch = useDispatch()
  


  const [token,setToken] = useState('')
  const [code,setCode] = useState('')

  useEffect(()=>{
    setCode(''+ makeCode(6))
    setToken(''+ makeid(30))
  },[])

  useEffect(()=>{
    if(token !== ''){
      let data = {
        token: token,
        code: code
      }
      localStorage.setItem('sync', JSON.stringify(data))
      dispatch(setSync(data))
    }
  },[token])

  const Resize = e =>{

    let width = e.target.value * 6
    let height = e.target.value * 3.75
    let tempScale = (width*height) / (400 * 250)

        setScale(tempScale)        
}


    const [activeSetup,setActiveSetup] = useState(0)
    const [screenStep, setScreenStep] = useState(0)

    const Setups = (index) =>{
        switch(index){
            default:
             case 0:
                return <ScreenCalibration imageHeight={imageHeight} imageWidth={imageWidth} step={screenStep} setStep={setScreenStep} overlaytoggle={setOverlayOpen} overlay={setOverlay} setActiveSetup={setActiveSetup}  />
            case 1: 
                return <MobileSync setActiveSetup={setActiveSetup} />
            case 2: 
                return <Calibration counter={blackCounter} setCounter={setBlackCounter} socket={socket} setActiveSetup={setActiveSetup}/>
        }
    }


    useEffect(()=>{
        let numbers = document.getElementsByClassName('number')

        for(let i =0 ; i< numbers.length; i++){
            if(i === activeSetup)
                numbers[i].classList.add('lnk-blue')
            else
                numbers[i].classList.remove('lnk-blue')
        }
    },[activeSetup])

    const Overlays = () =>{
        switch(overlay){
            case 0:
                return(
                    <>
                    <div className="screen-calibration-container">
                        <div className="close" onClick={()=> setOverlayOpen(false)}>
                            {X}
                        </div>
                        <div className="resize" style={{transform:`scale(${scale})`}}>
                            <div className="resize-outline">
                                <p>Card</p>
                            </div>
                        </div>
                        <input className='slide-input' type="range" min="50" defaultValue="100" max="100" onChange={(e)=> Resize(e)} />
                        <button onClick={()=> GetScreenDimentions()} className='btn-purple'>Continue</button>
                    </div>
                    </>
                )
            case 1:
                return(
                  <>
                    <p> Please take a dollar bill and place it on the screen to see if it matches the dollar bill below.</p>
                    <img src='./images/dollar.jpg' alt="Verify calibration" style={{width:`${imageWidth}px`,height:`${imageHeight}px`}}/>
                    <div>
                        <button onClick={()=> setOverlay(0)}style={{marginRight:'1rem'}} className='btn-blue btn-medium'>It doesn't match</button>
                        <button onClick={()=> {setOverlayOpen(false);setActiveSetup(1)}} className='btn-purple btn-medium'>It matches!</button>
                    </div>
                  </>
                )
            case 2:
                return(
                    <>
                      <img src={img} alt="Connected!" />
                      <h1>Phone Connected!</h1>
                      <p>Press “Get Started” on your device to continue your vision test.</p>
                      <button onClick={()=> setOverlayOpen(false)} className="btn-purple btn-medium">Submit</button>
                    </>
                )
        }
    }
    const Views = () =>{
        if(blackCounter === 2)
        {
            return(
                Setups(activeSetup)
            )
        }
        else
        return(
            <>
            <Header/>
             <div className="App gradient-back">
        <div className={`overlay ${overlayOpen ? 'overlay-open' : ''}`}>
            <div className="overlay-content">
               
                 {Overlays()}
            </div>
        </div>
        <div className="test-setup-container">
            <div className="header">
                <div className="number">
                    <p>
                        <span>1</span>
                        screen calibration
                    </p>
                </div>
                <div className="number">
                    <p>
                        <span>2</span>
                        connect phone
                    </p>
                </div>
                <div className="number">
                    <p>
                        <span>3</span>
                        distance set up
                    </p>
                </div>
            </div>
            <div className="test-setup">
                {
                    Setups(activeSetup)
                }
            </div>
        </div>
    </div>
            </>
        )
    }
    return (
       Views()
    )
}
