import {useState} from 'react'
import { EnglishText, SpanishText } from './TermsAndPrivacyTexts'
import {Header} from './Header'
export const PrivacyAndTerms = () => {

    const [privacyOrTerms, setPrivacyorTerms] = useState(0)
    const [englishOrSpanish,setEnglishOrSpanish] = useState(false)


    function LaungageController(){
       if(!englishOrSpanish)
            return EnglishText(privacyOrTerms)
        else
            return SpanishText(privacyOrTerms)
    }

    function ChangeText(text, index){
         setPrivacyorTerms(text)
        let headings = document.getElementById('Terms').getElementsByTagName('h3')
        
        for( let i = 0 ; i< headings.length ; i++){
            if(i !== index)
                headings[i].classList.remove('active')
            else
                headings[i].classList.add('active')
        }
    }

    return (
        <>
        <Header />
       <div className="App gradient-back">
           <div className="privacy-container">
               <div className="privacy-nav">
                    <div id='Terms' className={ privacyOrTerms === 0 ? 'terms-privacy' : 'terms-privacy priv'}>
                        <h3 className='active' onClick={() => ChangeText(0,0)}>{englishOrSpanish ? 'Política de Privacidad' : 'Privacy Policy'}</h3>
                        <h3 onClick={() =>ChangeText(1,1)}>{englishOrSpanish ? 'Términos y Condiciones' : 'Terms of Service'}</h3>
                    </div>
                        <h3 className='lnk' onClick={()=> setEnglishOrSpanish(!englishOrSpanish)}>{!englishOrSpanish ? 'Ver en Español' : 'Read in English'}</h3>
               </div>
               <div className="text-container">
                    {LaungageController()}
               </div>
           </div>
           <div className="blender"></div>
       </div>
       </>
    )
}

