import '../../PDFStyles/NorthCarolina.css'

export const NorthCarolinaTemplate = ({transaction, user}) => {
    return (
        <>
            <img src="./images/pdfs/NorthCarolina.jpg" alt="North Carolina Form" />
            <p className="UserSign">{transaction.signature}</p>
            <p className="LicenseNum">{transaction.licenseNum}</p>
            <p className="FirstName"> {transaction.fullName} </p>
            <p className="Optometrist">&#x2713;</p>
            <p className="Ophthalmologist">&#x2713;</p>
            <p className='Date'>{`${transaction.date.split('/')[0]}/${transaction.date.split('/')[1]}/${transaction.date.split('/')[2]}`}</p>
            <p className='DoctorName'>Doctor Name</p>
            <p className='DoctorName-bottom'>Doctor Name</p>
            <p className='DoctorAddress'>Doctor Address</p>
            <p className="DoctorPhone">123123123</p>
            <p className="TableFirstRow first">40</p>
            <p className="TableFirstRow second">40</p>
            <p className="TableFirstRow third">40</p>
            <p className="TableSecondRow first">40</p>
            <p className="TableSecondRow second">40</p>
            <p className="TableSecondRow third">40</p>
            <p className="Question-Two One">&#x2713;</p>
            <p className="Question-Two Two">&#x2713;</p>
            <p className="Question-Two Three">&#x2713;</p>
            <p className="Question-Three One">&#x2713;</p>
            <p className="Question-Three Two">&#x2713;</p>
            <p className="Question-Three Three">&#x2713;</p>
            <p className="Question-Three Four">&#x2713;</p>
            <p className="Question-Six">&#x2713;</p>
            <p className="Question-Seven">&#x2713;</p>
            <p className="Question-Eight One">170(???)</p>
            <p className="Question-Eight Two">170(???)</p>
            <p className="Question-Eight Three">170(???)</p>
            <p className="Question-Eight Four">170(???)</p>
            <p className="Question-Eight-Check">&#x2713;</p>
            <p className="Question-Eight-Check">&#x2713;</p>
            <p className="Question-Eight-Check">&#x2713;</p>
            <p className="Question-Eight-Check">&#x2713;</p>
            <p className="Question-Eight-Check">&#x2713;</p>
            <p className="Question-Nine">&#x2713;</p>
            <p className="Question-Ten">&#x2713;</p>
            <p className="Question-Sixteen">&#x2713;</p>
            <p className="Question-Seventeen">&#x2713;</p>
            <p className="Question-Eighteen">&#x2713;</p>
            <p className="Question-Nineteen">&#x2713;</p>
            <p className="Question-Nineteen Six-Months">&#x2713;</p>
            <p className="Question-Nineteen Circle"></p>
            <p className="DoctorSign">Doctor Signarure</p>
            <p className="DoctorLicense">12345678</p> 
            <p className="Parent">{transaction.parent}</p>
            <p className="Phone">{transaction.phoneNumber}</p> 
            <p className="Diagnosis">Diagnosis ( ???? )</p>
            <p className="DoctorFax">Doctor Fax</p>
            <p className="DoctorDeg">Deg.</p>
        </>
    )
}

