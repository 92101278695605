import { useEffect, useState } from 'react'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import ReactToPdf from 'react-to-pdf'
import {selectUser} from '../store/features/UserSlice'
import { Florida } from './PDFPreviews/Florida'
import { NewYork } from './PDFPreviews/NewYork'
import { NorthCarolina } from './PDFPreviews/NorthCarolina'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const PdfEdit = (props) => {

    const pdf = useRef(null)
    const user = useSelector(selectUser)
    const [open,setOpen] = useState(false)
    const [canProceed, setCanProceed] = useState(true)
    const [canEdit, setCanEdit] = useState(true)

    const X = <FontAwesomeIcon icon={faTimes} />

    const [signature, setSignature] = useState('')
    const [view, setView] = useState(0)

    useEffect(()=>{
       console.log(user.usState)
    },[])


    const PDFSwitcher = () =>{
        switch(user.usState){
            case 'florida':
                return <Florida canEdit={canEdit}/>
            case 'NewYork':
                return <NewYork canEdit={canEdit} />
            case 'NorthCarolina':
                return <NorthCarolina canEdit={canEdit} />
            default:
                return <h1>Error! invalid state</h1>
        }
    }

    function openSignature(){
        setCanEdit(false)
        setTimeout(()=>{    
            if(document.getElementById('certify').checked)
            {
                document.getElementById('certify').classList.remove('required')
    
                    document.getElementsByClassName('footer')[0].classList.add('required')
                    setOpen(true)
    
            }
            else{
                document.getElementById('certify').classList.add('required')
            }
        },500)
    }
    function Generate(){
        let sign = document.getElementById('user-sign').value
        setSignature(sign)
        document.getElementById('submit').classList.remove('disabled')
    }

    function Proceed(){
        let data = JSON.parse(localStorage.getItem('data'))
        data = {...data, signature: signature}
        localStorage.setItem('data', JSON.stringify(data))
        props.setStep(1)
    }
    
    const Views = () =>{
        if(!view)
            return(
                <>
                     <h2>Type your signature</h2>
                                    <input id='user-sign' type="text" placeholder='Type Your Signature' />
                                <button onClick={()=> Generate()} className='btn-purple btn-medium'>Generate</button>
                                <input id='signature' type="text" value={signature}  placeholder='Signature Preview' />
                                <button id='submit' onClick={()=> setView(1)} className='btn-purple btn-medium disabled'>Submit</button>
                </>
            )
        else
            return(
                <>
                    <h1>
                        Success!
                    </h1>
                    <img src="./images/passed.png" alt="" />
                    <p>Please procced to payment.</p>
                    <button onClick={()=>Proceed()} className="btn-purple btn-medium">Okay!</button>
                </>
            )
    }

    return (
        <div className='App gradient-back col'>
             <div className={`prep-overlay ${open ? 'open' : ''}`}>
                        <div className="prep-overlay-container">
                            <div className="close" onClick={()=> {setOpen(false)}}>
                                {X}
                            </div>
                            <div className="header">
                    </div>
                    <div className="preps">
                        {Views()}                       

                    </div>
                        </div>
                </div>
            <div className="pdf-container">
            <div ref={pdf} className={`pdf ${user.usState}`}>
                  <PDFSwitcher user={user} />
                </div>
            </div>
            <div className="footer">
             <input type="checkbox"  id='certify' />
             <p>I certify that the information entered above is accurate to the best of my knowledge</p>
            <button onClick={()=>
                {
                    //  toPdf()
                    openSignature()
                }
            } className={`btn-purple btn-medium ${canProceed ? '' : 'disabled'}`}>Confirm</button>
            </div>
                                           
        </div>
    )
}
