import React, { useEffect, useState } from 'react'
import { isExpired } from "react-jwt";
import { ChangePassword } from '../util/auth';
import {Header} from './Header'

export const PasswordResetChange = () => {
    
    const urlSearchParams = new URLSearchParams(window.window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const [token,setToken] = useState('')
    const [name, setName] = useState('')

    const isMyTokenExpired = isExpired(token);

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [err,setErr] = useState('')
    const [confirmation,setConfirmation] = useState(false)

    useEffect(()=>{
        setToken(params.token)
        setName(params.name)
    },[])

    useEffect(()=>{
        console.log(isMyTokenExpired)
    })

    function AttemptChange(){
        if(password !== confirmPassword){
            setErr('Passwords do not match.')
        }
        else{   
            let data = {
                token: token,
                password: password
            }
          ChangePassword(data).then( res =>
                {
                    setErr('')
                    if(res.status === 200)
                        setConfirmation(true)
                }
            )
        }
    }
    function CheckPassword(e){

        let regexLower = new RegExp("(?=.*[a-z])")
        let regexUpper = new RegExp("(?=.*[A-Z])")
        let regexNumber = new RegExp("(?=.*\\d)")

        let pw = e.target.value
        setPassword(pw)
        let container = e.currentTarget

        if(pw.length < 8 ){
            container.classList.add('invalid')
            container.setAttribute('aria-data', 'length')
            document.getElementById('change').classList.add('disabled')
        }
        else{
            if(regexUpper.test(pw)){
                if(regexNumber.test(pw)){
                    if(regexLower.test(pw)){
                        container.classList.remove('invalid')
                         document.getElementById('change').classList.remove('disabled')
                    }   
                    else
                    container.setAttribute('aria-data', 'lower')
                }
                else
                container.setAttribute('aria-data', 'number')
            }
            else
            container.setAttribute('aria-data', 'upper')
        }
    }
    const View = () =>{
        switch(token){
            case '':{
                return <p>Invalid Request</p>
            }
            default:{
               if(!isMyTokenExpired){
                   return(
                       <>
                      <Header />
                    <div className='App gradient-back'>
                        <div className={`confirmation ${confirmation ? 'popup' : ''}`}>
                            <h1>Success!</h1>
                            <p>Your password has been reset successfully.</p>
                            <p>You can continue to your account</p>
                            <button onClick={() => window.location.href=`${process.env.REACT_APP_LINK}/login/?name=${name}`} className='btn-purple'>Continue</button>
                        </div>
                        <form className="login-form reset" onSubmit={e=> {e.preventDefault(); AttemptChange()}}>
                            <h1>{`Hello ${name}!`}</h1>
                            <p>Enter your new password.</p>
                        <div className="form-row password" onChange={e => CheckPassword(e)}>
                            <input placeholder="Password" type="password"  className="input-full" />
                        </div>
                        <div className="form-row">
                            <input placeholder="Confirm Pasword" type="password" onChange={e => setConfirmPassword(e.target.value)} className="input-full" />
                        </div>
                        <input type="submit" id='change' className="btn-purple" value='Submit' />
                        <h3 className='errorMsg'>{err}</h3>
                    </form> 
                </div>
                </>
                   )
               }
               else
                  return <p>Your request expired, please try again</p>
            }
        }
    }

    return (
        <div className='App gradient-back'>
            {View()}
        </div>
    )
}

