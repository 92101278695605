import { Checkbox, FormControlLabel } from "@material-ui/core"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { SignUp } from "../util/auth"
import { Header } from "./Header"

export const Registration = () => {

    const[firstName,setFirstName] = useState('')
    const[lastName,setLastName] = useState('')
    const[phoneNumber,setPhoneNumber] = useState('')
    const[address,setAddress] = useState('')
    const[lstate,setLstate] = useState('State')
    const[zipcode,setZipCode] = useState('')
    const[city,setCity] = useState('')
    const[email,setEmail] = useState('')
    const[password,setPassword] = useState('')
    const[confirmPassword,setConfirmPassword] = useState('')

    const [checkClr,setChechclr] = useState('primary')
    const [err,setErr] = useState('')
    const [status,setStatus] = useState('')

    const [confirmation, setConfirmation] = useState(false)

    function CheckData(e){

        e.preventDefault();
       
            if(password !== confirmPassword){
                setErr('* Passwords do not match.')
            }    
            else if(!document.getElementById('check').checked){
                setErr('Please agree to the Terms of Service before registering.')
            }   
            else{
                setErr('')
                setStatus('loading...')
                let data = {
                    FirstName: firstName,
                    LastName: lastName,
                    Phone: phoneNumber,
                    Address: address,
                    State: lstate,
                    ZipCode: zipcode,
                    City: city,
                    Email: email,
                    Password: password
                }
                SignUp(data).then(res =>{
                    if(res.status === 200){
                        setStatus('Success!')
                        setConfirmation(true)
                    }
                    if(res.status === 201){
                        setStatus('')
                        setErr('Email Already in use.')
                    }
                   
                })
            }

    }

    function CheckPassword(e){
        e.preventDefault()

        let regexLower = new RegExp("(?=.*[a-z])")
        let regexUpper = new RegExp("(?=.*[A-Z])")
        let regexNumber = new RegExp("(?=.*\\d)")

        let pw = e.target.value
        setPassword(pw)
        let container = e.currentTarget

        if(pw.length < 8 ){
            container.classList.add('invalid')
            container.setAttribute('aria-data', 'length')
            document.getElementById('register').classList.add('disabled')
        }
        else{
            if(regexUpper.test(pw)){
                if(regexNumber.test(pw)){
                    if(regexLower.test(pw)){
                        container.classList.remove('invalid')
                         document.getElementById('register').classList.remove('disabled')
                    }   
                    else
                    container.setAttribute('aria-data', 'lower')
                }
                else
                container.setAttribute('aria-data', 'number')
            }
            else
            container.setAttribute('aria-data', 'upper')
        }
    }
    function CheckZip(e){
        e.preventDefault()

        let regexLower = new RegExp("(?=.*[a-z])")
        let regexUpper = new RegExp("(?=.*[A-Z])")
        let regexSpecial = new RegExp("(?=.*[-+_!@#$%^&*., ?])") 

        let zip = e.target.value
        setZipCode(zip)
        let container = e.currentTarget

        if(zip.length !== 5){
            container.classList.add('invalid')
            container.setAttribute('aria-data','length')
            document.getElementById('register').classList.add('disabled')
        }
        else{
            if(!regexLower.test(zip)){
                if(!regexUpper.test(zip))
                {
                    if(!regexSpecial.test(zip))
                    {
                        container.classList.remove('invalid')
                        document.getElementById('register').classList.remove('disabled')
                    }
                    else
                    container.setAttribute('aria-data','numbers')
                }
                else
                container.setAttribute('aria-data','numbers')
            }
            else
            container.setAttribute('aria-data','numbers')
        }
    }

    function CheckEmail(e){
         setEmail(e.target.value)
         const regex =
         /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
         let container = e.currentTarget
        if (!regex.test(e.target.value)){
            container.classList.add('invalid')
            container.setAttribute('aria-data', 'invalid')
            document.getElementById('register').classList.add('disabled')
        }
        else{
            container.classList.remove('invalid')
            document.getElementById('register').classList.remove('disabled')
        }
    }

    function CheckPhone(e){
        setPhoneNumber(e.target.value)

        let container = e.currentTarget
        const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

        if(e.target.value.length < 10 || !regex.test(e.target.value)){
            container.classList.add('invalid')
            container.setAttribute('aria-data', 'phone')
        }
        else{
            container.classList.remove('invalid')
        }
    }

    return (
        <>
        <Header />
       <div className="App gradient-back">
           <div className={`confirmation ${confirmation ? 'popup' : ''}`}>
               <img src="./images/email.png" alt="confirmation" />
               <h1>You've got mail</h1>
               <p>Please check your inbox to confirm your email.</p>
               <button onClick={() => {setConfirmation(false); window.location.href=`${process.env.REACT_APP_LINK}/login`}} className='btn-purple'>Close</button>
           </div>
           <div className="form-wrapp">
                <form onSubmit={e => CheckData(e)}>
                    <h1>Sign Up</h1>
                    <div className="left">
                            <div className="form-row">
                                <input required onChange={(e)=> setFirstName(e.target.value)} placeholder="First Name" type="text" className="input-half" />
                                <input required onChange={(e)=> setLastName(e.target.value)} placeholder='Last Name' type="text" className="input-half" />
                            </div>
                            <div className="form-row">
                                <div className="phone" onChange={(e)=> CheckPhone(e)}>
                                 <input required pattern="[0-9]+}" minLength="10"  maxlength="12"   placeholder='Phone Number' type="tel" className="input-half" />
                                </div>
                                <div className="email" onChange={e=> CheckEmail(e)}>
                                    <input required placeholder='Email' type="email" className="input-half" />
                                </div>
                            </div>
                            <div className="form-row">
                                <input required onChange={(e)=> setAddress(e.target.value)} placeholder='Address' type="text" className="input-half" />
                                <input required onChange={(e)=> setCity(e.target.value)} placeholder='City' type="text" className="input-half" />
                            </div>
                            <div className="form-row">
                            <select className="input-half" required onChange={(e)=> setLstate(e.target.value)} defaultValue={lstate} name="cars" id="cars">
                                <option value="">State</option>
                                <option value="NewYork">New York</option>
                                <option value="florida">Florida</option>
                                <option value="NorthCarolina">North Carolina</option>
                            </select>
                            <div className="input-half zip" onChange={(e)=> CheckZip(e)}>
                                <input required maxLength="5"  placeholder='Zip Code' type="text" />
                            </div>
                            </div>
                    </div>
                    <div className="right">
                        <div className="form-row">
                            <div style={{width:'98%'}} className='password'  onChange={(e)=> CheckPassword(e)}>
                                <input required placeholder="Password" type="password" className="input-full" />
                            </div>
                        </div>
                        <div className="form-row">
                            <input required placeholder="Confirm Password" onChange={(e)=> setConfirmPassword(e.target.value)} type="password" className="input-full" />
                        </div>
                        <div className="form-row">
                                    <FormControlLabel aria-required='true' control={<Checkbox onChange={()=> setChechclr('primary')}  id="check" color={checkClr} />}/>
                                    <p>I have read and understand the <Link to={'/privacy-and-terms'}>Privacy Policy and Terms of Service</Link></p>
                        </div>
                        <div className="form-row">
                                <div className="spacer"></div>
                           <input id='register' className="btn-purple" type="submit" value='Register' />
                        </div>
                    </div>
                    <h3 className='errorMsg'>{err}</h3>
                    <h3 className='statusMsg'>{status}</h3>
                </form>
           </div>
       </div>
       </>
    )
}

