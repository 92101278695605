import React, { useEffect, useState, useRef } from 'react'
import { Calibration } from './components/Calibration'
import { ColorBlindnessTest } from './components/ColorBlindnessTest'
import { VisualFieldTest } from './components/VisualFieldTest'
import { SocketConnect } from './util/utilities'
import { useSelector } from 'react-redux'
import {selectUser} from './store/features/UserSlice'
import Lottie from 'lottie-web'
import { CalibrationSetUp } from './components/Calibration-Instructions/CalibrationSetUp'
import { BrowserView, MobileView } from 'react-device-detect'
import Webcam from 'react-webcam'
import axios from 'axios'

export const TestArea = () => {
  const user = useSelector(selectUser)

useEffect(()=>{
 axios.post(`${process.env.REACT_APP_SERVER_LINK}/endTest`,{id:user.id,tests_taken: user.tests_taken+1})
},[])
   return (
    <>
      <div style={{display:'flex', flexDirection:'column',alignItems:'center',gap:'2em', margin:'0 auto', width:'min(100vw, 600px)'}}>
        <img src="https://memesseum.com:3000/images/logo.png" alt="DMV Eye Test" />
        <h1>Reset your password</h1>
        <p>To reset your password please click the button bellow</p>
        <a href=""><button style={{padding:'1rem 2rem', background:'#3ab9ff', color:'white',border:'none'}}>Reset</button></a>
      </div>
    </>
    )
}

