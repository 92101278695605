import '../PDFStyles/NorthCarolina.css'
import '../PDFStyles/NorthCarolinaSmall.css'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../store/features/UserSlice'


export const NorthCarolina = (props) => {

        const user = useSelector(selectUser)
  

    const [data, setData] = useState({
        fullName: user.firstName + ' ' + user.lastName,
        licenseNum: user.licenseNum,
        parent: 'Parent',
        phoneNumber: user.phoneNumber
    })


    useEffect(()=>{
        let list = document.getElementsByTagName('input')
        for( let i = 0 ; i < list.length ; i++){
            list[i].style.width = ((list[i].value.length +1 ) * 7) +'px'
        }
    },[])

    const AdjustSize = (e) =>{
        let obj = e.target
       obj.style.width = ((e.target.value.length +1 ) * 7) + 'px' 

       setData({
           ...data,
            [e.target.name]: e.target.value
       })
    }

    useEffect(()=>{
        if(props.canEdit)
            localStorage.setItem('data', JSON.stringify(data))
    },[data])

    return (
        <>
            <img src="./images/pdfs/NorthCarolina.jpg" alt="New York Form" />
            <input type="text" name='fullName' onChange={ e => { AdjustSize(e)}} className='FirstName' defaultValue={data.fullName} />
            <input type="text" name='licenseNum' onChange={ e => { AdjustSize(e)}} className='LicenseNum' defaultValue={data.licenseNum} />
            <input type="text" name='parent' onChange={ e => { AdjustSize(e)}} className='Parent' defaultValue={data.parent}/>
            <input type="text" name='phoneNumber' onChange={ e => { AdjustSize(e)}} className='Phone' defaultValue={data.phoneNumber}/>
            <p className='Date'>{(new Date().getMonth()+1) + '/' + new Date().getDate() + '/' + new Date().getFullYear()}</p>
            <p className="TableFirstRow first">40</p>
            <p className="TableFirstRow second">40</p>
            <p className="TableFirstRow third">40</p>
            <p className="TableSecondRow first">40</p>
            <p className="TableSecondRow second">40</p>
            <p className="TableSecondRow third">40</p>
            <p className="Question-Two One">&#x2713;</p>
            <p className="Question-Two Two">&#x2713;</p>
            <p className="Question-Two Three">&#x2713;</p>
            <p className="Question-Three One">&#x2713;</p>
            <p className="Question-Three Two">&#x2713;</p>
            <p className="Question-Three Three">&#x2713;</p>
            <p className="Question-Three Four">&#x2713;</p>
            <p className="Question-Six">&#x2713;</p>
            <p className="Question-Seven">&#x2713;</p>
            <p className="Question-Eight One">170(???)</p>
            <p className="Question-Eight Two">170(???)</p>
            <p className="Question-Eight Three">170(???)</p>
            <p className="Question-Eight Four">170(???)</p>
            <p className="Question-Eight-Check">&#x2713;</p>
            <p className="Question-Eight-Check">&#x2713;</p>
            <p className="Question-Eight-Check">&#x2713;</p>
            <p className="Question-Eight-Check">&#x2713;</p>
            <p className="Question-Eight-Check">&#x2713;</p>
            <p className="Question-Nine">&#x2713;</p>
            <p className="Question-Ten">&#x2713;</p>
            <p className="Question-Sixteen">&#x2713;</p>
            <p className="Question-Seventeen">&#x2713;</p>
            <p className="Question-Eighteen">&#x2713;</p>
            <p className="Question-Nineteen">&#x2713;</p>
            <p className="Question-Nineteen Six-Months">&#x2713;</p>
            <p className="Question-Nineteen Circle"></p>
            
        </>
    )
}


