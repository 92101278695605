import axios from 'axios';
import { config } from 'dotenv';
import io from 'socket.io-client'




// socket connection reusable
export const SocketConnect = () =>{
    return io.connect(`${process.env.REACT_APP_SERVER_LINK}`)//http://18.222.79.244/
}

export function Randomize(min,max){
   return  Math.floor(Math.random() * (max - min + 1)) + min;
}

// Probably delete, shape detector
export const drawRect = (detections,ctx) =>{

    detections.forEach(prediction=>{
        const [x,y,width,height] = prediction['bbox']
        const text = prediction['class']

        const color = 'green'
        ctx.strokeStyle = color
        ctx.font = '18px Arial'
        ctx.fillStyle = color


        ctx.beginPath()
        ctx.fillText( text , x , y )
        ctx.rect(x , y , width , height )
        ctx.stroke()
    })
}

export async function UploadImages(front,back,id,licenseNum,frontID, backID){

    if(frontID || backID){
        return UpdateLicense(front,back,frontID,backID,id,licenseNum)
    }
    else{
        let formData = new FormData()


        formData.append('front',front)
        formData.append('back', back)

        formData.append('id', id)
        formData.append('licenseNum',licenseNum)

        try{
            const res = await axios.post(`${process.env.REACT_APP_SERVER_LINK}/upload`, formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }}) 
            return res

        }
        catch(err){
            console.log(err)
        }
    }
    
}
export async function SetUpLicense(front,back,id,licenseNum){
    if(front && back)
        UploadImages(front,back,id);

     return  await axios.post(`${process.env.REACT_APP_SERVER_LINK}/updateLicenseNum`, {id:id,licenseNum:licenseNum})
    
}
export async function UpdateLicense(front,back,frontID,backID,id,lincenseNum){

    if(!frontID || !backID){
        return UploadImages(front,back,id,licenseNum,)
    }

    let formData = new FormData()


    formData.append('front',front)
    formData.append('back', back)
    formData.append('frontID', frontID)
    formData.append('backID', backID)
    
    formData.append('id', id)
    formData.append('licenseNum', lincenseNum)

    try{
        const res = await axios.post(`${process.env.REACT_APP_SERVER_LINK}/update-license`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }}) 
        return res

    }
    catch(err){
        console.log(err)
    }
}
export async function ResetTest(id){
    return axios.post(`${process.env.REACT_APP_SERVER_LINK}/resetTest`,{id:id},config)
}