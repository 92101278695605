import React, { useEffect } from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import { MobileSync } from './components/MobileSync'
import App from './App'
import { Header } from './components/Header'
import { Registration } from './components/Registration'
import { PrivacyAndTerms } from './components/PrivacyAndTerms'
import { LogIn } from './components/LogIn'
import { useSelector } from "react-redux"
import { login, selectUser } from "./store/features/UserSlice"
import { PasswordReset } from './components/PasswordReset'
import { PasswordResetChange } from './components/PasswordResetChange'
import { DashBoard } from './components/DashBoard'
import { TestSetUp } from './components/TestSetUp'
import { TestController } from './components/TestController'
import { PdfEdit } from './components/PdfEdit'
import { EndController } from './components/EndController'
import { TestPreparations } from './components/TestPreparations'
import { TestArea } from './TestArea'
import { isMobile } from 'react-device-detect'
import axios from 'axios'
import { useDispatch } from 'react-redux'

export const Landing = () => {

  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  useEffect(()=>{
    async function Update(){
      await axios.post(`${process.env.REACT_APP_SERVER_LINK}/loadUpdate`,{id:user.id}).then(
        res =>{
          let data = res.data
          let userData ={
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            address: data.address,
            usState: data.state,
            zipCode: data.zipCode,
            city: data.city,
            phoneNumber: data.phoneNumber,
            email: data.email,
            frontLicense: data.license_front_id,
            backLicense: data.license_back_id,
            licenseNum: data.licenseNum,
            loggedIn: true,
            tests_taken: data.tests_taken,
            tests_passed: data.tests_passed,
            test_in_progress: data.test_in_progress
            }
            localStorage.setItem('user', JSON.stringify(userData))
        dispatch(
            login(userData)
            )
        }
      )
    }
    if(user){
      Update()
    }
  },[])

  return (
    <Router>
      <Switch>
        <Route path="/" exact component={App} />
        <Route exact path="/registration">
          {user ? <Redirect to="/dashboard" /> : <Registration/>}
        </Route>
        <Route exact path="/login">
          {user ? <Redirect to="/dashboard" /> : <LogIn/>}
        </Route>
        <Route exact path="/password-reset">
          {user ? <Redirect to='/'/> : <PasswordReset/>}
        </Route>
        <Route exact path="/password-reset-change" component={PasswordResetChange}>
        </Route>
        <Route exact path="/privacy-and-terms" component={PrivacyAndTerms} />
        <Route exact path="/dashboard">
          {!user ? <Redirect to='/'/> : <DashBoard />}
        </Route>
        <Route exact path="/test-preparations">
          {!user ? <Redirect to='/login'/> : <TestPreparations/>}
        </Route>
        <Route exact path="/test-set-up">
          {!user && !isMobile ? <Redirect to='/'/> : <TestSetUp />}
        </Route>
        <Route exact path ='/testarea' component={TestArea} />
        <Route exact path="/test">
          {
            !user && !isMobile ? <Redirect to='/login'/> : <TestController />
          }
        </Route>
        <Route exact path='/test-end'>
          { !user && !isMobile ? <Redirect to ='/'/> :  <EndController />}
        </Route>
        <Route exact path="/sync" component={MobileSync} />
        <Route exact path='/pdf' component={PdfEdit} />
      </Switch>
    </Router>
  )
}
  
