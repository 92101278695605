import React, { useEffect, useState } from 'react'
import { SocketConnect } from '../../util/utilities'


export const Prescriptions = () => {

    const [correction,setCorrection] = useState(0)

    const [prescription, setPrescription] = useState(0)

    function ActiveButton(e){
        let buttons = e.target.parentElement.getElementsByTagName('button')

        buttons[0].classList.remove('active')
        buttons[1].classList.remove('active')

       e.target.classList.add('active')

    }

    useEffect(()=>{
        if( correction === 2){
            let reveal = document.getElementsByClassName('prep-el')
                    for( let i = 0 ; i < reveal.length ; i++){
                        reveal[i].classList.add('low-opacity')
                    }
            document.getElementById('continue').classList.remove('disabled')
            document.getElementById('continue').disabled = false
        }
        else if(correction === 1){
            if(prescription === 0 ) 
                {
                    let reveal = document.getElementsByClassName('prep-el')
                    for( let i = 0 ; i < reveal.length ; i++){
                        reveal[i].classList.remove('low-opacity')
                    }
                   
                }
            else{
                document.getElementById('continue').classList.remove('disabled')
                document.getElementById('continue').disabled = false
            }
        }
    },[correction, prescription])

    function Proceed(){
        if(prescription){
            let data
            if(prescription === 1)
            {
                 data = {
                    type: 'glasses'
                }
            }
            else
            {
                data = {
                    type: 'lenses'
                }
            }
            localStorage.setItem('Prescription', JSON.stringify(data))
        }
        window.window.location.href=`${process.env.REACT_APP_LINK}/test-set-up`
    }


    return (
        <>
       <h1 className='align-c'>Does your license require a vision correction?</h1>
        <div className='d-flex'>
            <button onClick={(e)=> {ActiveButton(e); setCorrection(1)}} className="btn-big btn-prep">YES</button>
            <button onClick={(e)=> {ActiveButton(e); setCorrection(2)}} className="btn-big btn-prep">NO</button>
        </div>
        <h1 className='align-c prep-el low-opacity'>
             Select your prescription type:
        </h1>
        <div className='d-flex prep-el low-opacity'>
            <button onClick={(e)=> {ActiveButton(e); setPrescription(1);}} className="btn-big btn-prep"><img src="./images/glasses.png" alt="" />   Glasses</button>
            <button onClick={(e)=> {ActiveButton(e); setPrescription(2)}} className="btn-big btn-prep"><img src="./images/lenses.png" alt="" /> Lenses</button>
        </div>
        <button id='continue' onClick={()=> Proceed()} className="btn-purple btn-big disabled">Continue</button>
        </>
    )
}

