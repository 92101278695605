import { configureStore } from "@reduxjs/toolkit";
import userReducer from './features/UserSlice';
import syncReducer from './features/SyncSlice'
import dimentionsReducer from './features/DimensionsSlice'

export default configureStore({
    reducer:{
        user: userReducer,
        sync: syncReducer,
        dimensions: dimentionsReducer,
    }
})