import React, { useState, useEffect, useRef } from 'react'
import { Card, Button, TextField } from '@material-ui/core'
import { BrowserView, isMobile, MobileView } from 'react-device-detect'
import Lottie from 'lottie-web'
import { Randomize } from '../util/utilities'
import { TestFailed } from './TestFailed'
import { CountDown } from './CountDown'



export const ColorBlindnessTest = (props) => {

    // declare socket for server communication
    const socket = props.socket

    const token = props.token


    // temporary answer in case of repeats
    const [tempAnswer, setTempAnswer] = useState(0)
    const [passed, setIsPassed] = useState(false)
    const [failed, setIsFailed] = useState(false)

    const [countDown, setCountDown] = useState(false)

    useEffect(()=>{
        if(isMobile)
          socket.emit('join',token)
    },[])


    // detect user's answer and handle a repeat
    useEffect(()=>{
        socket.on('visualStep', step =>{
            setTutorialCounter(0)
            setStep(step)
            if(step == 1 ){
                setCorrect(0)
                setLoad(true)
            }
        })
        socket.once('answer' ,ans =>{
            let answer = Number(ans)
              if(tempAnswer === answer)
                setTempAnswer(-1)
              else 
                setTempAnswer(answer)
        })   
        socket.on('passed', ()=>{
            setIsPassed(true)
        })
        socket.on('failed', ()=>{
            setIsFailed(true)
        })
        socket.on('tutorialIncorrect',()=>{
            if(isMobile)
                document.getElementById('tutorial-incorrect').classList.remove('low-opacity')
        })
        socket.on('tutorialCorrect',()=>{
            if(isMobile)
                document.getElementById('tutorial-incorrect').classList.add('low-opacity')
        })
        socket.on('endTutorial',()=>{
            setTutorial(false)
            setCurrentQuestion(questions[Randomize(0,questions.length-1)])
        })
        socket.on('reset', ()=>{
            Reset();
        })
        socket.on('changeTest',()=>{
             props.setActiveTest(3)
        })
    }) 


    // declare states for test logic
    const [correct,setCorrect] = useState(0)
    const [incorrect,setIncorrect] = useState(0)
    const [answer,setAnswer] = useState(0)
    const [mobileAnswer, setMobileAnswer] = useState()
    const [activeColor, setActiveColor] = useState(0)
    const [loading,setLoading] = useState(true)
    const[load, setLoad] = useState(false)

    const [isPassed, setIspassed] = useState(false)
    const [isFailed, setisFailed] = useState(false)

    const [tutorial, setTutorial] = useState(true)
    const [tutorialCounter, setTutorialCounter] = useState(0)
    const [step, setStep] = useState(0)

    // declare current question
    const [currentQuestion, setCurrentQuestion] = useState({src: '', number: 0})

    // handle no question error
    const Image = () =>{
        if(currentQuestion)
            return  <img src={currentQuestion.src} alt="Question" />
        else
            return 'error loading image'
    }

    // returns paths to images depending on the color which is being tested
    const ImagePaths = () =>{
        switch(activeColor){
            default: 
                return [
                    {src: './images/Red/9.svg',number: 9 },
                    {src: './images/Red/12.svg',number: 12 },
                    {src: './images/Red/13.svg',number: 13 },
                    {src: './images/Red/14.svg',number: 14 },
                    {src: './images/Red/16.svg',number: 16 },
                    {src: './images/Red/18.svg',number: 18 },
                    {src: './images/Red/22.svg',number: 22 },
                    {src: './images/Red/23.svg',number: 23 },
                    {src: './images/Red/24.svg',number: 24 },
                    {src: './images/Red/26.svg',number: 26 },
                    {src: './images/Red/28.svg',number: 28 },
                    {src: './images/Red/32.svg',number: 32 },
                    {src: './images/Red/34.svg',number: 34 },
                    {src: './images/Red/36.svg',number: 36 },
                    {src: './images/Red/38.svg',number: 38 },
                    {src: './images/Red/42.svg',number: 42 },
                    {src: './images/Red/53.svg',number: 53 },
                    {src: './images/Red/62.svg',number: 62 },
                    {src: './images/Red/68.svg',number: 68 },
                    {src: './images/Red/69.svg',number: 69 },
                ]
            case 1:
                return [
                    {src: './images/Green/3.svg',number: 3 },
                    {src: './images/Green/5.svg',number: 5 },
                    {src: './images/Green/7.svg',number: 7 },
                    {src: './images/Green/11.svg',number: 11 },
                    {src: './images/Green/17.svg',number: 17 },
                    {src: './images/Green/20.svg',number: 20 },
                    {src: './images/Green/20(1).svg',number: 20 },
                    {src: './images/Green/23.svg',number: 23 },
                    {src: './images/Green/25.svg',number: 25},
                    {src: './images/Green/29.svg',number: 29 },
                    {src: './images/Green/30(1).svg',number: 30 },
                    {src: './images/Green/30.svg',number: 30 },
                    {src: './images/Green/33.svg',number: 33 },
                    {src: './images/Green/35.svg',number: 35 },
                    {src: './images/Green/44.svg',number: 44 },
                    {src: './images/Green/48.svg',number: 48 },
                    {src: './images/Green/50.svg',number: 50 },
                    {src: './images/Green/52.svg',number: 52 },
                    {src: './images/Green/56.svg',number: 56 },
                    {src: './images/Green/60.svg',number: 60 },
                    {src: './images/Green/66.svg',number: 66 },
                    {src: './images/Green/75.svg',number: 75 },
                    {src: './images/Green/78.svg',number: 78 },
                    {src: './images/Green/79.svg',number: 79 },
                    {src: './images/Green/84.svg',number: 84 },
                    {src: './images/Green/87.svg',number: 87 },
                    {src: './images/Green/89.svg',number: 89 },
                    {src: './images/Green/91.svg',number: 91 },
                    {src: './images/Green/92.svg',number: 92 },
                    {src: './images/Green/93.svg',number: 93 },
                    {src: './images/Green/94.svg',number: 94 },
                    {src: './images/Green/95.svg',number: 95 },
                    {src: './images/Green/96.svg',number: 96 },
                    {src: './images/Green/97.svg',number: 97 },
                    {src: './images/Green/98.svg',number: 98 },
                    {src: './images/Green/99.svg',number: 99 },
                ]
            case 2: 
                return [
                    {src: './images/Amber/4.svg',number: 4 },
                    {src: './images/Amber/6.svg',number: 6 },
                    {src: './images/Amber/8.svg',number: 8 },
                    {src: './images/Amber/46.svg',number: 46 },
                    {src: './images/Amber/58.svg',number: 58 },
                    {src: './images/Amber/65.svg',number: 65 },
                    {src: './images/Amber/65(1).svg',number: 65 },
                    {src: './images/Amber/70.svg',number: 70 },
                    {src: './images/Amber/70(1).svg',number: 70 },
                    {src: './images/Amber/75.svg',number: 75 },
                    {src: './images/Amber/75(1).svg',number: 75 },
                    {src: './images/Amber/80.svg',number: 80 },
                    {src: './images/Amber/80(1).svg',number: 80 },
                    {src: './images/Amber/82.svg',number: 82 },
                    {src: './images/Amber/85.svg',number: 85 },
                    {src: './images/Amber/86.svg',number: 86 },
                    {src: './images/Amber/88.svg',number: 88 },
                    {src: './images/Amber/90.svg',number: 90 },
                    {src: './images/Amber/90(1).svg',number: 90 },
                    {src: './images/Amber/95.svg',number: 95 },
                    {src: './images/Amber/96.svg',number: 96 },
                    {src: './images/Amber/98.svg',number: 98 },
                    {src: './images/Amber/99.svg',number: 99 },
                ]
        }
    }

    const tutorialPaths = [
            {src: './images/tutorial/42.png', number:42},
            {src: './images/tutorial/12.png', number:13},
            {src: './images/tutorial/3.png', number:100}
        ]

    // grab questions of current color
    const questions = ImagePaths()


    // declare loader ref
    const loader = useRef(null)

    // load lottie loader animation
    useEffect(()=>{
        Lottie.loadAnimation({
                container: loader.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../loader.json')

        })
        if(questions)

        // grab starting question
        setCurrentQuestion(tutorialPaths[tutorialCounter])
    },[])

     //handle loading transition
     useEffect(()=>{
        if(loading){
            setTimeout(()=>{
                setLoading(false)
            },1500)
        }
     },[loading])

    // Generate a random number for question selection
   

    // switch to next color for testing or end the test
    function NextColor(){
        if(activeColor < 2){
            setCorrect(0)
            setIncorrect(0)
            setActiveColor(activeColor+1)
        }
        else 
           socket.emit('passed',token)
    }

    //reset if fail
    function Reset(){
        socket.emit('visualStep', token,1)
        setTutorialCounter(0)
        setIsPassed(false)
        setCorrect(0)
        setIncorrect(0)
        setActiveColor(0)
        setLoading(true)
    }

    useEffect(()=>{
        if(step === 1){
            setCountDown(true)
        }
    },[step])

    //grab new question on color change
    useEffect(()=>{
        if(!tutorial)
        setCurrentQuestion(questions[Randomize(0,questions.length-1)])
    },[activeColor])

    // declare test logic
    function TestLogic(){
        if(answer === currentQuestion.number){
            setCorrect(correct+1);
            setCurrentQuestion(questions[Randomize(0,questions.length-1)])
        }
        else{
            setIncorrect(incorrect+1)
            setCurrentQuestion(questions[Randomize(0,questions.length-1)])
        }
        setLoading(true)
    }
    function TutorialLogic(){
        if(answer === currentQuestion.number){
            console.log('correct')
            setCorrect(correct+1);
            socket.emit('tutorialCorrect', token)
            setTutorialCounter(tutorialCounter+1)
        }
        else{
            console.log('incorrect')
            socket.emit('tutorialIncorrect', token)
        }
        setLoading(true)
    }
    useEffect(()=>{
        setCurrentQuestion(tutorialPaths[tutorialCounter])
    },[tutorialCounter])
    // handle user pass color
    useEffect(()=>{
        if(correct === 3){
            if(tutorial){
                socket.emit('passed',token)
            }
            else
            NextColor()
        }
    },[correct])

    useEffect(()=>{
        if(incorrect == 2)
            {socket.emit('visualStep',token,2)}
    },[incorrect])

    useEffect(()=>{
        if(passed)
            setStep(2)
    },[passed])
    //handle user answer
    useEffect(()=>{
        if(!isMobile){

            if(tempAnswer !== -1)
              setAnswer(tempAnswer)
            else{
                if(tutorial)
                TutorialLogic()
                else
                TestLogic()
            }
        }
    },[tempAnswer])

    //process user answer on desktop
    useEffect(()=>{
        if(!isMobile && answer !== 0){
            if(tutorial)
            TutorialLogic()
            else
            TestLogic()

            }
     },[answer])

      function sendAnswer(e){
        let ans = document.getElementById('number').value

        socket.emit('answer', token,ans)
    }
  
    const MobileViews = () =>{
        switch(step){
            case 0:
                return(
                    <>
                            <div className="App">
                                <div className="col-m" style={{width:'100%', alignItems:'center'}} >
                                <h1 style={{textTransform: 'capitalize', textAlign: 'center'}}>About the test</h1>
                                <ul style={{width: '80%'}}>
                                    <li style={{marginBottom: '1em'}}>Use your Device to select what you see on your screen</li>
                                    <img style={{width:'100%'}} src='./images/PrepSVG/ColorPrep.svg' alt="" />
                                    <li style={{marginBottom: '1em'}}>Keep your glasses on</li>
                                    <li style={{marginBottom: '1em'}}>Position your computer at the edge of your desk</li>
                                    <li style={{marginBottom: '1em'}}>Maximize your computer screen brightness</li>
                                </ul>
                                    <button onClick={()=>{ socket.emit('visualStep',token,1)}} className='btn-purple btn-big'>Practice First</button>
                                    <button onClick={()=>{socket.emit('endTutorial',token) ; socket.emit('visualStep',token,1)}} className='btn-blue btn-big'>Skip Practice</button>
                                </div>
                            </div>
                    </>
                )
            case 1:
                return(
                    <div className="App gradient-back">
                        <CountDown load={countDown} setLoad={setCountDown} />
                    <Card variant="outlined" className="m-sync-card">
                        <input type="number" id='number'/>
                        <Button onClick={() => sendAnswer()} color="primary" variant="contained">Submit</Button>
                        <Button onClick={() => socket.emit('answer', token,100)} color="primary" variant="contained">Can't see</Button>
                        <p id='tutorial-incorrect' className='low-opacity' style={{color: 'red', margin:'1em 0'}}>incorrect Please try again</p>
                    </Card>
                </div>
                )
            case 2:
                {
                    if( !passed )
                        return <TestFailed retry={Reset} />
                    else{
                        if(tutorial)
                        {
                            return(
                                <>
                                <div className="App gradient-back">
                                    <div className="col-m center"  style={{position:'absolute',top:'10%',left:'50%',transform:'translate(-50%,-10%)'}}>
                                        <h1 style={{textAlign:'center'}}>Practice Complete</h1>
                                        <img src="./images/passed.png" alt="" />
                                        <p>You’re ready to take the actual test.</p>
                                        <button onClick={()=> {socket.emit('endTutorial',token); Reset()}} className="btn-purple btn-big">Begin Test</button>
                                        <button onClick={()=> {socket.emit('reset',token)}} className="btn-blue btn-big">Practice Again</button>
                                    </div>
                                </div>
                                </>
                            )

                        }
                        else
                        { 
                             return(
                                <>
                                    <div className="App gradient-back">
                                        <div className="col-m center" style={{position:'absolute',top:'10%',left:'50%',transform:'translate(-50%,-10%)'}}>
                                            <h1 style={{textAlign:'center'}}>You passed!</h1>
                                            <img src="./images/passed.png" alt="" />
                                            <p>Congratulations. </p>
                                            <button onClick={()=> {socket.emit('nextTest',token, 2)}} className="btn-purple btn-big">Continue</button>
                                        </div>
                                    </div>
                                </>
                                )
                            }
                    
                    }
                }
        }
    }

    const DesktopViews = () =>{
        switch(step){
            case 0: 
                return (
                    <div className="wrap">
                       <h1>Continue on your Device.</h1>
                    </div>
                )
            case 1:
                return(
                    <div className="wrap">
                        <CountDown load={countDown} setLoad={setCountDown} />
                        <Card variant="outlined" className="loader-card">
                            <div className={loading ? 'loader' : 'loader loader-off'} ref={loader}></div>
                            {Image()}
                        </Card>
                    </div>
                )
                case 2:
                    return (
                        <div className="wrap">
                           <h1>Continue on your Device.</h1>
                        </div>
                    )
        }
    }

    return (
        <>
            <BrowserView>
               <DesktopViews />
            </BrowserView>
            <MobileView>
               <MobileViews />
            </MobileView>
        </>
    )
}

