import { Button } from "@material-ui/core"
import Lottie from "lottie-web"
import { useEffect, useRef, useState } from "react"
import { BrowserView, isMobile, MobileView } from "react-device-detect"
import { Circle, Layer , Stage } from "react-konva"
import { Randomize } from "../util/utilities"
import prep from '../VFieldPrep.svg'
import space from '../space.svg'
import VField1 from '../VField1.svg'
import VField2 from '../VField2.svg'
import {TestFailed} from './TestFailed'
import { Link } from "react-router-dom"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CountDown } from "./CountDown"

export const VisualFieldTest = (props) => {

    // declare socket for server communication
    const socket = props.socket

    const [dimentions , setDimentions] = useState()
    // declare loader ref for lottie 
    const loader = useRef(null)

    // declare test states handlers ( pre-test, test, post-test )
    const [loading,setLoading] = useState(true)
    const [isStarting, setIsStarting] = useState(false)
    const [counter, setCounter] = useState(3)
    const [isStarted, setIsStarted] = useState(false)
    const [ended, setEnded] = useState(false)
    const [passed, setPassed] = useState(false)


    const X = <FontAwesomeIcon icon={faTimes} />

    // declare test logic states (timer regulator, number of dots, number of correct answers)
    const [canAnswer, setCanAnswer] = useState(false)
    const [dotCounter, setDotCounter] = useState(0)
    const [answerCounter, setAnswerCounter] = useState(0)

    const [Xvalues, setXvalues] = useState([])
    const [Yvalues, setYvalues] = useState([])


    // declare message for post-test display
    const [mess, setMess] = useState('')

    const [prepCounter, setPrepCounter] = useState(0)

    const [tutorial, setTutorial] = useState(true)

    const [open,setOpen] = useState(false)

    const [countDown, setCountDown] = useState(false)

    // handle loading toggle
    useEffect(()=>{
        if(loading)
            setTimeout(()=>{
                setLoading(false)
            },1000)
    },[loading])

    // get screen dimensions on load 
    var width = window.innerWidth;
    var height = window.innerHeight;


  
    // end the test
    function End(){
        setIsStarted(false)
    }

    useEffect(()=>{
        if(!isStarted && dotCounter > 0){
            if(answerCounter >= dotCounter * 0.8 && dotCounter > 0){
                if(!tutorial)
                setPassed(true)
            }
            else
             setPassed(false)
            setIsStarting(false)
            setEnded(true)
        }
    },[isStarted])
    useEffect(()=>{
        console.log('Dots: ', dotCounter)
    },[dotCounter])
    useEffect(()=>{
        console.log('Answers: ', answerCounter)
    },[answerCounter])
    // reset the test if failed
    function Reset(){
        setMess('')
        setAnswerCounter(0)
        setDotCounter(0)
        setPassed(false)
        setIsStarting(true)
        setCounter(3)
        setEnded(false)
    }


    // start test start countdown timer
    useEffect(()=>{
        if(isStarting){
            setCountDown(true)
            setTimeout(()=>{
                setCounter(2)
            },1000)
        }
    },[isStarting])
    
    // Timer workaround because intervals are shit
    useEffect(()=>{
        if(counter === 2){
            setTimeout(()=>{
                setCounter(1)
            },1000)
        }
        if(counter === 1){
            setTimeout(()=>{
                setCounter(0)
            },1000)
        }
        if(counter === 0){
            setTimeout(()=>{
                setIsStarted(true)
            },1000)
        }
    },[counter])

    // load the lottie loader
    useEffect(()=>{
        Lottie.loadAnimation({
                container: loader.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../loader.json')

        })
        if(!isMobile){
            document.getElementById('header').style.display='none'
            let c = document.getElementById('container').getElementsByTagName('canvas')[0]
            let ctx = c.getContext('2d')
            getEllipse(ctx, width/2, height/2, width/2.4, height/2.4);
            // ctx.lineWidth = 5;
            // ctx.stroke();
           setDimentions(JSON.parse(localStorage.getItem('dimentions')))
        }
    },[])

    // TEST LOGIC ( draw dots and set duration)
    useEffect(()=>{
        if(isStarted){
            const interval = setInterval(()=>{
                setCanAnswer(true)
           },5000)
           if(tutorial){
               setTimeout(()=>{
                   End()
                },45000)
           }
           else{
            setTimeout(()=>{
                End()
            },90000)
        }
        return () => clearInterval(interval)
    }
    },[isStarted])

    useEffect(()=>{
        if(canAnswer){
            let check =  Randomize(0,100)
            if(check < 85)
                DrawDot()
            else
               DrawCenter()
        }
    },[canAnswer])

    function getEllipse(ctx, cx, cy, rx, ry) {  
        var angleStep = 0.05, angle = angleStep;
        ctx.moveTo(cx + rx, cy);
        let i = 0                 
        let tempX = Xvalues
        let tempY = Yvalues
        while(angle < Math.PI*2) {

            tempX[i] = cx + rx * Math.cos(angle)
            tempY[i] = cy + ry * Math.sin(angle)

          ctx.lineTo(
            cx + rx * Math.cos(angle),             
            cy + ry * Math.sin(angle)
          );
          angle += angleStep
          i++
        }
        setXvalues(tempX)
        setYvalues(tempY)

        ctx.closePath();
      }

      useEffect(()=>{
        console.log('X',Xvalues)
        console.log('Y', Yvalues)
      },[Xvalues,Yvalues])
     
    // Draw a dot on random coordinates inside the elipse area
   function DrawDot(){
       let c = document.getElementById('container').getElementsByTagName('canvas')[0]
       let ctx = c.getContext('2d')
       
       let indicator = Randomize(0, Xvalues.length - 1)
       
       let x = Xvalues[indicator]
       let y = Yvalues[indicator]


        setDotCounter(dotCounter + 1)

        
        ctx.beginPath();
        ctx.arc(x, y, 5, 0, 2 * Math.PI, false);
        ctx.fillStyle = '#F16B6B';
        ctx.fill();

       setTimeout(()=>{
        ctx.clearRect(x-10,y-10,20, 20 )
            setCanAnswer(false)
       },500)
   }

   //draw a dot in center to check if the user is focused on it
    function DrawCenter(){
        let c = document.getElementById('container').getElementsByTagName('canvas')[0]
        let ctx = c.getContext('2d')
        let x = width/2 
        let y = height/2 
       
        setDotCounter(dotCounter + 1)
       
        ctx.clearRect(x-5,y-5,11, 12 )
        ctx.beginPath()
        ctx.moveTo(x-10, y+5); 
        ctx.lineTo(x+10, y+5); 
        ctx.lineTo(x, y-10); 
        ctx.fillStyle = "#F2C94C";
        ctx.fill();

        setTimeout(()=>{
            ctx.clearRect(x-11,y-11,21, 20 )

            setCanAnswer(false)
         
            ctx.beginPath();
            ctx.arc(x, y, 5, 0, 2 * Math.PI, false);
            ctx.fillStyle = '#3AB9FF';
            ctx.fill();
        },500)
    }

    //Detect and handle user press
   function useKey(key, cb){
        const callbackRef = useRef(cb)

        useEffect(()=>{
            callbackRef.current= cb
        })

        useEffect(()=>{

            function handle(e){
                if(e.code === key){
                    callbackRef.current(e)
                }
            }

            document.addEventListener('keypress', handle)
            return() => document.removeEventListener('keypress', handle)
        },[key])

   }
   useEffect(()=>{
    setTimeout(()=>{
        setMess('')
    },2000)
   },[mess])
   //Check if user pressed within the 500ms window
   function handleAnswer (){
      if(canAnswer){
          setAnswerCounter(answerCounter+1)
          if(tutorial){
              document.getElementById('VF-tutorial').classList.remove('red-text')
              setMess('Good Job!')
          }
      }
      else{
          if(tutorial)
         { document.getElementById('VF-tutorial').classList.add('red-text')
          setMess('Oh no! try again')}
      }
   }

   // declare what button to listen for
   useKey('Space', handleAnswer)


   const PrepScreens = () =>{

    if(prepCounter === 0)
       return (
           <>
               <div className="left">
                    <h3>
                        Enviroment Setup
                    </h3>
                    <ul>
                        <li>
                             This test is performed with both eyes at the same time. 
                        </li>
                    </ul>
                    <ul>
                        <li>
                            To perform the test correctly, please:
                            <ol>
                                    <li>
                                    Ensure you are in a quiet environment.
                                    </li>
                                    <li>
                                    Dim the lighting in the room.
                                    </li>
                                    <li>
                                    View the chart to determine how far from your screen to sit.
                                    </li>
                                    <li>
                                    Please maximize your window so the test fills your entire screen.
                                    </li>
                                    <li>
                                    Distance your eyes from the screen depending on your screen size, you can find out how much is that using our Distance Calculator.This test is performed with both eyes at the same time. 
                                    </li>
                            </ol>
                        </li>
                    </ul>
                    <p style={{color:'#F16B6B'}}>
                    Results may be inaccurate if the test is not performed correctly.
                    </p>
                    <button onClick={()=> setPrepCounter(1)} className="btn-purple btn-big">
                        continue
                    </button>
               </div>
               <div className="right">
                    <h3>
                    Distance calculator
                    </h3>
                   <div className="d-flex">
                        <div className='blue'>
                                <p>
                                Your screen size is:
                                </p>
                                <p>
                                    <span>
                                        {
                                        dimentions ? 
                                        dimentions.diagonal
                                        :
                                        ''
                                         }
                                    </span>
                                        inches
                                </p>
                                <p style={{marginTop:'1em'}}>
                                    <span>
                                        {
                                            dimentions ?
                                        Math.round(dimentions.diagonal * 2.54)
                                        :
                                        ''
                                         }
                                    </span>
                                        cm
                                </p>
                        </div>
                        <div className='purple'>
                                <p>
                                Take this distance from screen:
                                </p>
                                <p>
                                    <span>
                                    {
                                        dimentions 
                                        ?
                                        Math.round(dimentions.diagonal * 1.3)
                                        :
                                        ''    
                                    }
                                    </span>
                                    inches
                                </p>
                                <p style={{marginTop:'1em'}}>
                                    <span>
                                    {
                                        dimentions?
                                       Math.round( dimentions.diagonal * 2.54 * 1.3)
                                        :
                                        ''    
                                    }
                                    </span>
                                    cm
                                </p>
                        </div>
                   </div>
                   <img style={{maxWidth:'70%'}} src={prep} alt="" />
               </div>
           </>
       )
       else
        return(
            <>
             <div className="left">
                    <h3>
                       Instructions
                    </h3>
                    <ul>
                        <li>
                             During the test you will first respond to a fixation point, which is located in the centre of the testing area.
                        </li>
                        <li>
                        Keep your eyes focused on the fixation point at all times.
                        </li>
                        <li>
                        Press the space bar when the fixation point changes from a blue circle to a yellow triangle.
                        </li>
                    </ul>
                    <ul>
                        <li>
                             Remember, you have three main tasks:
                            <ol>
                                   <li>
                                   Keep your eyes on the fixation point at all times.
                                   </li>
                                   <li>
                                   Press the space bar when the fixation point changes.
                                   </li>
                                   <li>
                                   1. Keep your eyes on the fixation point at all times. 2. Press the space bar when the fixation point changes. 3. Press the space bar when you see a stimulus.
                                   </li>
                            </ol>
                        </li>
                    </ul>
               </div>
               <div className="right">
                   <div className="d-flex" style={{flexDirection:'row'}}>
                        <div style={{display:'flex',flexDirection:'column', alignItems:'center'}}>
                            <img style={{marginBottom:'2em'}} src={VField1} alt="Stimulus" />
                            <img src={space} alt="" />
                        </div>
                        <div style={{display:'flex',flexDirection:'column', alignItems:'center'}}>
                            <img style={{marginBottom:'2em'}} src={VField2}alt="Stimulus" />
                            <img src={space} alt="" />
                        </div>
                   </div>
               <div style={{width: '100%', display:'flex', flexDirection:'column', alignItems:'center'}}>
                  <h4 style={{textAlign:'center', marginBottom:'1em'}}>
                  Please Note - It is recommended that you take a practice test at least once. Please remember to maximize your browser window before starting the test.
                  </h4>
                  <button onClick={()=> setOpen(true)} style={{margin:'0 auto'}} className='btn-purple btn-big'>Continue</button>
               </div>
               </div>
            </>
        )
   }

   const EndScreens = () =>{
       if(passed){
           return (
               <>
                    <div className="d-flex col" style={{margin:'0 auto', alignItems:'center', justifyContent:'space-evenly', height:'100%'}}>
                        <h1>Congratulations!</h1>
                        <img src="./images/passed.png" alt="You passed!" />
                        <p>
                             You have passed all the tests required by your state!
                        </p>
                        <Link to={'/test-end'}><button className="btn-purple btn-big">
                            See My Results
                        </button></Link>
                    </div>
               </>
           )
       }
       else{
           if(tutorial){
                return(
                    <>
                        <div className="d-flex col" style={{margin:'0 auto', alignItems:'center', justifyContent:'space-evenly', height:'100%'}}>
                    <h1>Practice Complete!</h1>
                    <img src="./images/passed.png" alt="You passed!" />
                    <p>
                         You’re ready to take the actual test.
                    </p>
                    <div className="buttons" style={{display:'flex',gap:'1rem'}}>
                        <button onClick={()=> Reset()} className="btn-blue btn-medium">
                            Practice Again
                        </button>
                        <button onClick={()=> {setTutorial(false);Reset()}} className="btn-purple btn-medium">
                            Begin Test
                        </button>
                    </div>
                </div>
                    </>
                )
           }
           else
           return <TestFailed retry={Reset} />
       }
   }

    return (
       <>
        <BrowserView>
             <div className={`prep-overlay ${open ? 'open' : ''}`}>
                        <div className="prep-overlay-container">
                            <div className="close" onClick={()=> setOpen(false)}>
                                {X}
                            </div>
                            <div className="header" style={{textAlign:'center'}}>
                                <h2>WARNING <br/>
                                Please read notice below
                                </h2>
                            </div>
                        <div className="preps">
                        <p style={{textAlign:'left', maxWidth:'60ch'}}>
                        If you experience any of the following symptoms: dizziness, altered vision, eye or muscle twitching, involuntary movements, loss of awareness, disorientation, or convulsions, DISCONTINUE THE TEST IMMEDIATELY and consult your physician.
                        </p>
                        <p style={{textAlign:'left', maxWidth:'60ch'}}>
                        By clicking "START" you understand this warning and acknowledge that you have not been diagnosed with any type of photosensitive disorder and have not had a seizure within the last six months.
                        </p>
                        <p style={{textAlign:'left', maxWidth:'60ch'}}> 
                        If you are unsure, please consult your physician before taking this test. 
                        </p>
                        <button onClick={()=> {setOpen(false);setIsStarting(true)}} className='btn-purple btn-medium'>Start Practice</button>
                        <button onClick={()=> {setOpen(false);setTutorial(false);setIsStarting(true)}} className='btn-blue btn-medium'>Skip Practice</button>
                        </div>
                            </div>
            </div>
            <div className={loading ? 'loader' : 'loader loader-off'}>
                <div className="mini-loader" ref={loader}>

                </div>
            </div>
            <div className={ended? 'end' : 'end no-display'}>
                <EndScreens />
            </div>
            <div className={isStarting ? 'start no-display' : 'start'} style={{flexWrap:'wrap'}}>
                <div className="VF-prep-container">
                    <PrepScreens />
                </div>
            </div>
               <CountDown load={countDown} setLoad={setCountDown} />
           <div id="container" className={isStarted ? `VisualFieldContainer` :`VisualFieldContainer blurred`}>
               <h2 id='VF-tutorial'>{mess}</h2>
                <Stage width={width} height={height} >
                    <Layer>
                        <Circle x={width/2} y={height/2} radius={5} fill={'#3AB9FF'} />
                    </Layer>
                </Stage>
           </div>
        </BrowserView>
        <MobileView>
            <div className="App gradient-back">
                <h1>Continue on Desktop</h1>
            </div>
        </MobileView>
       </>
    )
}

