import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import {selectUser} from '../../store/features/UserSlice'
import Lottie from 'lottie-web'

export const CalibrationSetUp = (props) => {

   const user = useSelector(selectUser)
   useEffect(()=>{
   },[])

   const [instructionStep, setInstructionStep] = useState(0)
   const [innerStep, setInnerStep] = useState(0)

   const [lang, setLang]= useState(0) // 0 = eng 

   function switchButton(x){
      let buttons = document.getElementsByClassName('btn-prep')

      for(let i = 0; i< buttons.length ; i++){
         if (i=x)
            buttons[i].classList.add('active')
         else
            buttons[i].classList.remove('active')
      }
   }

   const Instructions = () =>{
      switch(instructionStep){
         case 0:
            return(
               <>
                  <div className="App gradient-back">
                        <div className="col text-left">
                           <p>Just to let you know!</p>
                           <p>You are about to use your mobile device as a remote control for this test. Your mobile device will be used to determine the correct distance between you and the desktop screen. To do so, this application will request access to your world-facing camera. While most devices are supported, non-OEM, modified, or rooted devices may experience unexpected behaviors.</p>
                           <p>Throughout the course of the test, your session may experience long delays depending on traffic and the stability of your connection.</p>
                           <p>Distance calibration requires a double confirmation while test answer submission only requires a single confirmation. Therefore, submitting the incorrect answer accidentally is possible. Please do not repeat an accidental gesture too many times in succession and instead, wait to retry at the end of the test or re-calibrate if this happens.</p>
                           <button onClick={()=> setInstructionStep(1)} className='btn-purple btn-big'>Continue</button>
                        </div>
                  </div>
               </>
            )
            case 1:
               return(
                  <>
                      <div className="App gradient-back">
                           <div className="instruction-col">
                                 <h1>
                                    {/* Hi {user.firstName}, <br /> */}
                                    Hi Test, <br />
                                    let's get set up
                                 </h1>
                                 <div className='d-flex'>
                                    <button onClick={e=>{setLang(0); switchButton(0)}} className="btn-medium btn-prep active">ENG</button>
                                    <button  onClick={e=>{setLang(0); switchButton(1)}} className="btn-medium btn-prep">ESP</button>
                                 </div>
                                 <div className="instruction-container">
                                    <p>
                                    {Text()}
                                    </p>
                                    <div className='lottie' ref={lottieRef}>

                                    </div>
                                    <div className="instruction-counter">
                                       <div onClick={()=> setInnerStep(0)} className="dot active"></div>
                                       <div onClick={()=> setInnerStep(1)} className="dot"></div>
                                       <div onClick={()=> setInnerStep(2)} className="dot"></div>
                                    </div>
                                 </div>
                                 {
                                    innerStep == 2 ?
                                    <button onClick={()=> setInstructionStep(2)} className='btn-purple btn-big'> Continue </button>
                                    :
                                    <button onClick={()=> setInnerStep(innerStep+1)} className='btn-purple btn-big'>Next</button>
                                 }
                           </div>
                     </div>
                  </>
               )
            case 2:
               return(
                  <>
                  <div className="App gradient-back">
                     <div className="col text-center">
                        <h1>Setup <br /> Completed!</h1>
                        <p>You’re ready to start Screen Calibrating</p>
                        <button onClick={()=> props.setAppStep(1)} className="btn-purple btn-big">Continue</button>
                     </div>
                  </div>
                  </>
               )
      }
   }
   var animation
   const EnglishText = () =>{
      switch(innerStep){
         case 0: return 'Turn up the brightness until you can see the text on your monitor clearly.'
         case 1: return 'Double-check that your room is well lit.'
         case 2: return 'Give permission to your phone to access your camera.'
      }
   }
   const SpanishText = () =>{
      switch(innerStep){
         case 0: return ' Configura tu ordenador para maximizar el brillo de tu pantalla.'
         case 1: return 'Verifica que tu cuarto esté bien iluminado.'
         case 2: return 'Cuando aparezca una ventana emergente pidiendo que des permiso para acceder a la cámara, haz click en “Permitir”'
      }
   }

   const Text = () =>{
      if(lang)
         return SpanishText()
      else
         return EnglishText()
   }
   
   const lottieRef = useRef(null)


  useEffect(()=>{
     if(instructionStep === 1)
     {Lottie.destroy(animation)
     animation = Lottie.loadAnimation({
      container: lottieRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: require( `../../Lotties/Lottie-${innerStep+2}.json`)
   })
   const buttons = document.getElementsByClassName('dot')

   for ( let i = 0 ; i < buttons.length ; i++){
      if(i === innerStep)
         buttons[i].classList.add('active')
      else
         buttons[i].classList.remove('active')
   }
}
  },[innerStep,instructionStep])

   return (
      <>
        {Instructions()}
      </>
   )
}

