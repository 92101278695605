import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { Link } from 'react-router-dom'
import notPassed from '../not-passed.svg'
import SVP from '../SVP.svg'

export const TestFailed = (props) => {

    const [inPerson,setInPerson] = useState(false)

    const Views = () =>{
        if(!inPerson)
            return (
                <>
                <h1>
                Test not Passed
                </h1>
                <img src={notPassed} alt="" />
                <p>An eye care provider licensed in state is ready to review your results.</p>
                <p onClick={()=> setInPerson(true)} style={{textDecoration: 'underline', fontWeight: 'bold'}}>
                Schedule an In person appointment
                </p>
                <div onClick={()=> props.retry()} className="btn-purple btn-big">Retry</div>
                <Link style={{textDecoration:'none'}} to={'/'}><div className="btn-blue btn-big">Exit</div></Link>
             </>
            )
        else
            return(
                <>
                <h1>Sign up to SVP</h1>
                <img src={SVP} alt="" />
                <div className='svp-text'>
                    <p>
                    <FontAwesomeIcon icon={faCheck} /> &nbsp;  &nbsp;  &nbsp;    Complimentary Annual Vision Exam.
                    </p>
                    <p>
                    <FontAwesomeIcon icon={faCheck} /> &nbsp;  &nbsp;  &nbsp;    Take the DMV Eye Test for free (new states added weekly).
                    </p>
                    <p>
                    <FontAwesomeIcon icon={faCheck} /> &nbsp;  &nbsp;  &nbsp;    $250 off of lenses and frames anually.
                    </p>
                </div>
                <div>
                    <p>Comming soon</p>
                    <button className="btn-purple btn-big">Get notified</button>
                </div>
             </>
            )
    }

    return (
       <>
       <MobileView>
        <div className="App gradient-back col col-fail">
            {Views()}
        </div>
       </MobileView>
       <BrowserView>
       <div className="App gradient-back col col-fail" style={{height:'100% !important'}}>
            {Views()}
        </div>
       </BrowserView>
       </>
    )
}

