import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import img from '../paymentSuccess.svg'
import { selectUser } from '../store/features/UserSlice'



export const PaymentForm = () => {
    
    const [cardNum, setCardNum] = useState()
    const [success,setSuccess] = useState(false)
    const stripe = useStripe()
    const elements = useElements()
    const [msg,setMsg] = useState()

    const user=useSelector(selectUser)

    useEffect(()=>{
        let data = JSON.parse(localStorage.getItem('data'))
        console.log(user.tests_taken +1)
    },[])

   const handleSubmit = async (e) =>{
        e.preventDefault()
        setMsg('Working...')
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement)
        })

        if(!error){
            try{
                 const {id} = paymentMethod
                 const res = await axios.post(`${process.env.REACT_APP_SERVER_LINK}/payments`, {
                     amount: 10000, //100$
                     id
                 })
     
                 if( res.data.success){
                     console.log('Success')
                     let data = JSON.parse(localStorage.getItem('data'))
                     data = {...data, state:user.usState, date: `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`,
                     userID: user.id}
                     await axios.post(`${process.env.REACT_APP_SERVER_LINK}/addtransaction`,data).then(
                         res =>{
                             if(res.status === 200){
                                 axios.post(`${process.env.REACT_APP_SERVER_LINK}/endTest`,{id:user.id,tests_taken: user.tests_taken+1}).then(
                                    res =>{
                                        console.log(res)
                                        localStorage.removeItem('dimentions')
                                        localStorage.removeItem('sync')
                                        localStorage.removeItem('token')
                                        localStorage.removeItem('data')
                                        setSuccess(true)
                                    }
                                 )
                            }
                        }
                        )
                 }
            }
            catch(err){
                console.log('Error: ',err)
                setMsg('Please check your input and try again.')
            }
        }else{
            console.log(error.messasge)
        }
   }

   useEffect(()=>{
    console.log(cardNum)
   },[cardNum])
    
    return (
        <>
        {!success ?
        
        <form className='payment-form' onSubmit={handleSubmit}>
            <h1>Confirm Payment</h1>
            <h3>Payment Method</h3>
                <div className="form-row">
                    <CardNumberElement onChange={(e)=> setCardNum(e.target)} className='card-input' placeholder='Card Number'/>
                </div>
                <div className="form-row d-flex">
                    <CardExpiryElement className='card-input input-half' placeholder='Card Number'/>
                    <CardCvcElement className='card-input input-half' placeholder='Card Number ' />
                </div>
                <input className='btn-purple' type="submit" value='Submit' />
                <p className="lnk-blue">{msg}</p>
        </form>
            :
            <>
                <h1>Payment Successful!</h1>
                <img src={img} alt="" />
                <p>
                Thank you for renewing your license with DMV Eye Test.
                
                <br /> Please find your application and receipt in the email you have provided
                </p>

                <Link to={'/dashboard'}><button className='btn-purple btn-big'>Back To Account</button></Link>
            </>
        }
        </>
    )
}

