
    //Text components for Privacy and Terms, stored separately as not to clog the component

export const EnglishText = (Switch) => {
    switch(Switch){
        default:
        case 0: 
            return(
                <p>This website is operated by Sharp Vision Plan d/b/a DMV Eye Test, a Wyoming corporation (hereinafter, “DMV Eye Test”, “We”, or “Us”). This Privacy Policy (the “Privacy Policy”) governs your access to the DMV Eye Test website: https://www.dmveyetest.com and any other web services owned, controlled, or offered by DMV Eye Test now or in the future (all collectively referred to as, the “Services"). The term “You” or “User” shall refer to any individual that views, uses, accesses, browses or submits any content or material to the Services.
                Since we may gather certain types of information about our users, we feel you should fully understand our policy and the terms and conditions surrounding the capture and use of that information. This Privacy Policy discloses what information we gather and how we use it. The private information you provide on the Services will be used only for its intended purpose.
                BY VISITING, SIGNING UP, USING, BROWSING, OR ACCESSING THE SERVICES, YOU CONSENT TO THE DATA PRACTICES DESCRIBED IN THIS STATEMENT. IF YOU DO NOT AGREE WITH OUR PRIVACY PRACTICES, DO NOT USE THE SERVICES.
                <br /> 1.      INFORMATION WE COLLECT<br />
                We may collect information, including: Personal Health Information, Personal Information and Non-Personal Information, when you interact with us and the Services, for example when you:
                Access or use the Services;<br />
                Open or respond to our e-mails;<br />
                Contact customer service;<br />
                Visit any page online that displays our ads or content;<br />
                Make a purchase on or through the Services (if applicable);<br />
                2.      PERSONAL HEALTH INFORMATION (PHI) and HIPAA<br />
                Certain demographic, health and/or health-related information that DMV Eye Test collects about its Users as part of providing the Services to Providers may be considered “Protected Health Information” or “PHI” under the Health Insurance Portability and Accountability Act (“HIPAA”). Specifically, the results of your visual acuity test may be considered PHI. However, Personal Information that you provide to DMV Eye Test outside of the foregoing context is not PHI. For example, when you provide information directly to us, such as when creating an account or using our interactive tools and services, or when you voluntarily provide information in free-form text boxes through the Services or through responses to surveys and questionnaires, or when you send us an email or otherwise contact us, that information is not PHI. HIPAA provides specific protections for the privacy and security of PHI and restricts how PHI is used and disclosed. DMV Eye Test may only use and disclose PHI in the ways permitted by law.
                <br />3.      PERSONAL INFORMATION<br />
                “Personal Information” means information about you that specifically identifies you or, when combined with other information we have, can be used to identify you. As a general rule, we do not collect Personal Information about you when you visit and/or use the Services, unless you choose to provide such information to us. Submitting Personal Information through the Services is voluntary. By doing so, you are giving us your permission to use the information for the stated purpose.
                <br />(a) LEGAL BASIS FOR COLLECTING YOUR PERSONAL INFORMATION
                We collect, process and use your Personal Information for the purposes described in this Privacy Policy, based at least on one of the following legal grounds:
                · With Your Consent: We ask for your agreement to process your information for specific purposes and you have the right to withdraw your consent at any time.
                · When Performing This Agreement: We collect and process your Personal Information in order to provide you with the Services, following your acceptance of this Privacy Policy; to maintain and improve our Services; to develop new services and features for our Users; and to personalize the Services in order for you to get a better user experience.
                · Legitimate Interests: We process your information for our legitimate interests while applying appropriate safeguards that protect your privacy. This means that we process your information for things like detecting, preventing, or otherwise addressing fraud, abuse, security, usability, functionality or technical issues with, protecting against harm to the rights, property or safety of our properties, or our users, or the public as required or permitted by law; enforcing legal claims, including investigation of potential violations of this Privacy Policy; in order to comply and/or fulfill our obligation under applicable laws, regulation, guidelines, industry standards and contractual requirements, legal process, subpoena or governmental request, as well as our Terms and our billing process.
                <br />(b) INFORMATION WE COLLECT AND HOW WE COLLECT INFORMATION
                Through the Services, we may collect information that can identify you when you voluntarily submit it to us. Your Personal Information may include your:
                <br />· Name;<br />
                · Address;<br />
                · Email address;<br />
                · Phone number;<br />
                · Driver’s license number;<br />
                · Billing information (such as billing address, credit card digits);<br />
                · Demographic data (such as age, gender, zip code);<br />
                · Date of birth; and<br />
                · Other similar information<br />
                (c) STORAGE OF PERSONAL INFORMATION<br />
                We will take reasonable precautions, as well as physical, technical, and organizational measures in accordance with industry standards, as described herein, to protect your Personal Information from loss, misuse, unauthorized access, disclosure, alteration, or destruction. Computer safeguards, such as firewalls and data encryption, may be used to protect your information. We authorize access to your information only for those employees or agents who require it to fulfill their job responsibilities and these individuals are required to treat this information as confidential.
                However, the security of information on or transmitted via the Internet cannot be guaranteed. Unauthorized entry of use, hardware or software failures, and other factors may compromise the security of your Personal Information. All information you send to us electronically or through email is at your own risk, as the transmission of information via the Internet is not completely secure.
                <br />4.      NON-PERSONAL INFORMATION
                We collect and temporarily store certain information about your usage of the Services. Non-Personal Information means information that alone cannot identify you, including data such as cookies, pixel tags, web beacons, and device information. The information includes, without limitation:
                · Device Data: We may collect information such as: the type of computer and/or mobile device you use; the unique device ID of your computer and/or mobile device; the IP address of your computer and/or mobile device; the operating system of your computer and/or mobile device; and the type of Internet browsers of your computer and/or mobile device.
                · Usage Details: When you access and use the Services, we may automatically collect certain details of your access to and use of the Services, including traffic data, location data, logs and other communication data and the resources that you access and use on or through the App.
                · Location Information: When you first visit or use the Services we may request permission to collect and use your device’s precise geowindow.location. You can opt not to permit the collection of this information, but you may not be able to use the Services provided by DMV Eye Test without access to your window.location. We need your location information in order to provide our Services. You can control how and whether we collect your precise geolocation information through your device’s settings.
                We may use information that is neither Personal Information nor P
                </p>
            )
        case 1:
            return(
                <p>
                    This website is operated by Sharp Vision Plan d/b/a DMV Eye Test, a Wyoming corporation (hereinafter, “DMV Eye Test”, “We”, or “Us”). These terms and conditions (the “Terms”) govern your access to the DMV Eye Test website: https://www.dmveyetest.com, and any other web services owned, controlled, or offered by DMV Eye Test now or in the future (all collectively referred to as, the “Services"). The term “You” or “User” shall refer to any person or entity who views, uses, accesses, browses or submits any content or material to the Services.
                    <br /> These Terms are important and affect your legal rights, so please read them carefully. Note that these Terms contain a mandatory arbitration provision that requires the use of arbitration on an individual basis and limits the remedies available to you in the event of certain disputes.
                    <br />  The Services are offered to you conditioned on your acceptance without modification of Terms contained herein. Certain features, services or tools of the Services may be subject to additional guidelines, terms, or rules, which will be posted with those features and are a part of these Terms. Your use of the Services constitutes your agreement to all such Terms. Please read these terms carefully.
                    <br /> YOU ACKNOWLEDGE AND AGREE THAT BY ACCESSING, USING, CONSUMATING A FINANCIAL TRANSACTION, OR BROWSING THE SERVICES, YOU ARE AFFIRMING THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO THESE TERMS, THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE SERVICES.
                    <br /> PRIVACY POLICY
                    <br /> Your use of the Services is subject to the DMV Eye Test Privacy Policy. Our Privacy Policy details how we may use, share, and maintain the information that you voluntarily share with us, which may include, without limitation, your name, address, date of birth, driver’s license number, contact information, and other personally identifiable information (collectively, “Personal Information”).
                    <br /> 1.      USE OF OUR SERVICES
                    <br />DMV Eye Test provides remote visual acuity screenings for the fulfillment of driver’s license renewal vision test requirements. DMV EYE TEST IS NOT A GOVERNMENT AGENCY OR ENTITY, AND IS NOT AFFILIATED WITH, OR ACTING ON BEHALF OF THE DEPARTMENT OF MOTOR VEHICLES OR ANY GOVERNMENT AGENCY. DMV EYE TEST IS AUTHORIZED BY THE APPLICABLE GOVERNMENT AGENCIES TO PROVIDE AND SUBMIT RESULTS OF VISION TESTS.
                    <br /> You acknowledge that the Services provided by DMV Eye Test does not create a medical professional/patient relationship, and do not constitute an opinion, medical advice, or diagnosis or treatment, but are provided for the sole purpose assisting you in obtaining a visual acuity screening for the renewal of your driver’s license and submitting it to the applicable governmental entity.
                    <br /> Subject to your compliance with its obligations under these Terms, we will provide you with access to the Services. Access to the Services is permitted on a temporary basis, and we reserve the right to withdraw or amend the service we provide without notice. We will not be liable if for any reason our Services are unavailable at any time or for any period.
                    <br /> You must be eighteen (18) years or over in order to use the Services. By accessing the Services, you represent and warrant that you are an adult, and you are capable of lawfully entering into and performing all the obligations outlined in this Agreement. We do not knowingly collect, either online or offline, personal information from persons under the age of 13.
                    <br /> 2.      NOT A PROVIDER OF MEDICAL ADVICE
                    <br /> Any information supplied through the Services or by any of our employees or agents, whether by telephone, email, video call, letter, facsimile or other form of communication, is for informational purposes or general guidance and does not constitute medical or other professional advice. Health-related information provided through the Services is not a substitute for medical advice and it is important that you not make medical decisions without first consulting your personal physician or other healthcare professional.
                    <br /> This online visual acuity test is not a medical evaluation and does not replace a visit to an eye care professional. It is not designed to be used as a diagnosis for illness or other conditions, for treatment, or for the mitigation or prevention of illness. This test simply aims to give you a general idea about your visual capacity such that you can renew your driver’s license. We recommend that you follow up this test with a full vision evaluation by a vision care specialist. Only eye care professionals can make decisions on medical treatment, diagnosis or prescription. DO NOT USE THE SERVICES FOR EMERGENCY MEDICAL NEEDS. IF YOU EXPERIENCE A MEDICAL EMERGENCY, IMMEDIATELY CALL A HEALTHCARE PROFESSIONAL AND 911. NOTHING STATED OR POSTED ON THE SERVICES OR AVAILABLE THROUGH ANY SERVICES IS INTENDED TO BE, AND MUST NOT BE TAKEN TO BE, THE PRACTICE OF MEDICINE OR OTHER PROFESSIONAL HEALTHCARE ADVICE, OR THE PROVISION OF MEDICAL CARE.
                    <br /> YOUR USE OF THE SERVICES IS NOT A SUBSTITUTE FOR HEALTHCARE. NO LICENSED MEDICAL PROFESSIONAL/PATIENT RELATIONSHIP IS CREATED WHEN YOU USE THE SERVICES OR CONTENT. THIS IS TRUE WHETHER SUCH CONTENT IS PROVIDED BY OR THROUGH THE USE OF THE SERVICES OR THROUGH ANY OTHER COMMUNICATIONS FROM DMV EYE TEST.
                    <br /> 3.      PREPARING FOR YOUR APPOINTMENT; ACCESSIBILITY
                    <br /> In order to use the Services, you will need access to an Internet connection and a device with a camera (such as a computer, laptop, mobile phone or tablet). You will also be required to present your current driver’s license. You will have a video call with one of our team members who will assist with the performance of the test. Please remove from your surroundings any items or objects that may contain sensitive or private information.
                    <br /> We may record audio or video of your appointment or otherwise have other authorized third parties who are not visibly present listening in on any video calls for training and quality control purposes.
                    <br /> You will need to have at least ten (10) feet of open space available between you and your device. You may have to place your device on a steady surface (such as a table, desk or countertop) and be able to walk 10 feet away from the device. Make sure that you are in a secure area and that you remove any objects or obstructions from the area prior to your test. We are not responsible for any injuries caused by any such objects or obstructions near you while using the Services.
                    <br /> If you require any type of accessibility needs or any type of assistive technology, please let us know prior to your appointment and we will make any necessary and reasonable accommodations prior to your use of the Services.
                    <br /> 4.      ACCESS TO THE SERVICES
                    <br /> We shall use commercially reasonable efforts to ensure the availability of the Services, except that we shall not be liable for: (a) scheduled downtime; or (b) any unavailability caused directly or indirectly by circumstances beyond our reasonable control, including without limitation, (i) a force majeure event; (ii) Internet provider, web hosting, cloud computing platform, or public telecommunications network failures or delays, or denial of service attacks; (iii) a fault or failure of your computer systems or networks; or (iv) any breach of these Terms by you.
                    <br /> 5.      TERMINATION OR SUSPENSION OF SERVICES
                    <br /> DMV Eye Test reserves the right to refuse or suspend access to any user, for any reason or no reason, and without any notice. DMV Eye Test may suspend your use of the DMV Eye Test Services or any portion thereof if DMV Eye Test believes that you have breached these Terms, or for any other reason, at its sole discretion. You agree that any termination of your access to the DMV Eye Test Services may be without prior notice, and you agree that DMV Eye Test will not be liable to you or any third party for any such termination. Where needed, we reserve the right to alert local law enforcement authorities about suspected fraudulent, abusive, or illegal activity that may be grounds for termination of your use of the Services.
                    <br /> 6.      PAYMENTS, REFUNDS AND CANCELLATIONS
                    <br /> (a) Payment Information. In order to use the Services, you will be required to pay a one-time fee. Our fee for the Services is separate from any fees charged by the Department of Motor Vehicles or any applicable government agency for the renewal of your driver’s license. By entering your payment information when requested, you authorize our third-party payment processor (“Payment Processor”) to charge and process the fees and charges assessed in connection with your visual acuity test. We do not store any credit card information in our systems other than the last 4 digits of your card number and card type.
                    <br /> The Payment Processor may ask you to supply information relevant to your payment including your credit card number, the expiration date of your credit card, CVV/CVC code, and your email and postal addresses for billing and notification (such information, “Payment Information”). You represent and warrant that you have the legal right to use all payment method(s) represented by any such Payment Information. If you pay any amounts with a credit card, we may seek pre-authorization of your credit card account prior to your purchase to verify that the credit card is valid and has credit available for a transaction. While We take reasonable efforts to ensure secure transmission of your information to third parties who assess and process payments, We are not responsible for any fees or charges assessed by third parties or any errors in the processing of payments by third parties, including any errors that result from third-party negligence, improper transmission of payment information, your mistaken submission of payment information, or your submission of erroneous payment information. The Payment Processor is responsible for transacting such payments through its platform, and you agree to release and hold Us harmless from any errors, negligence or misconduct by the Payment Processor. You authorize Payment Processor to directly remit Us any applicable fees owed to Us. DMV Eye Test is not liable for any fees incurred due to billing errors.
                    <br /> (b) Cancelling your Appointment. Cancellation is allowed up to the time of the appointment. You will not be charged if you do not pass the screening test and refunds will be given if the results are not accepted by the DMV or other relevant state agency for the purpose of driver’s license renewal. We have no obligation to provide refunds or credits but may grant them in extenuating circumstances on a case-by-case basis, in our sole discretion.
                    <br /> (c) Pricing Modifications; Promo Codes. We reserve the right to establish, remove, and/or revise prices, fees, and/or surcharges for any or all services obtained through the use of the Services at any time. We may also, in our sole discretion, make promotional offers with different features and different rates to any or all of our customers. Unless made to you, these promotional offers will have no bearing on your obligation to pay the amounts charged. DMV Eye Test may, from time to time in its sole discretion, offer specific promotional codes for discounts. Promotional codes are non-transferable and are not redeemable for cash, credit, or toward previous purchases. Promotional codes are void where prohibited. Any promotional program may be terminated or modified by Us at any time in our sole discretion.
                    <br /> 7.      PROHIBITED USES
                    <br /> Use of the Services is limited to the permitted uses expressly authorized by us. Any violation of the guidelines or this Agreement by your user content, as determined by DMV Eye Test in its sole discretion, may result in the termination of your access to the Services. The Services shall not be used to:
                    <br /> (a) Harass, abuse, stalk, threaten, defame, or otherwise infringe or violate the rights of any other party (including but not limited to rights of publicity or other proprietary rights);
                    <br /> (b) Engage in any unlawful, fraudulent, or deceptive activities;
                    <br /> (c) Misrepresent the source, identity, or content of information transmitted via the Services;
                    <br /> (d) Use technology or other means to access unauthorized content or non-public spaces;
                    <br /> (e) Use or launch any automated system or process, including without limitation, “bots,” “spiders,” or “crawlers,” to access unauthorized content or non-public spaces;
                    <br /> (f) Attempt to introduce viruses or any other harmful computer code, files or programs that interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;
                    <br /> (g) Probe, scan, or test the vulnerability of the Services or any system or network; use any robot, spider, scraper or other automated means to access the Services for any purpose without our express written permission;
                    <br /> (h) Use the Services in connection with hazardous environments requiring fail-safe performance or any application in which the failure or inaccuracy of that application or the Services could lead to death, personal injury, or physical or property damage;
                    <br /> (i) Modify the Services in any manner or form; use or develop any application that interacts with the Services or provides access to other users' content or information without our written permission; or use modified versions of the Services, including to obtain unauthorized access to the Services;
                    <br /> (j) Attempt to damage, disable, overburden, or impair our servers or networks;
                    <br /> (k) Attempt to gain unauthorized access to the Services, or any part of it, computer systems or networks connected to DMV Eye Test, or any part of it, through hacking, password mining or any other means, or interfere or attempt to interfere with the proper working of or any activities conducted on the Services;
                    <br /> (l) Display the Services or profile data on any external display or monitor or in any public setting without obtaining the prior consent of all participants. Furthermore, you may not display the Services or profile data on any external display or monitor or in any public setting in a manner that infringes on the intended use of the Services;
                    <br /> (m) Encourage the commission or encouragement of any illegal purpose, or in violation of any local, state, national, or international law, including laws governing criminal acts, prohibited or controlled substances, intellectual property and other proprietary rights, data protection and privacy, and import or export control; or
                    <br /> (n) Violate these Terms in any manner.
                </p>
            )
    }
}

export const SpanishText = (Switch) => {
    switch(Switch){
        default:
        case 0: 
            return(
                <p>
                    Este sitio web es operado por Sharp Vision Plan d/b/a DMV Eye Test, una compañía de Wyoming (de aquí en adelante, “DMV Eye Test”, o “Nosotros”). Esta Política de Privacidad (“Política de Privacidad”) controla su acceso al sitio web de DMV Eye Test: https://www.clearedtodrive.com y cualquier otro servicio web de propiedad, controlado y ofrecido por DMV Eye Test en el presente o en el futuro (colectivamente se refieren como los “Servicios”). El término “Usted” o “Usuario” se referirá a cualquier individuo que vea, use, acceda, navegue o envíe cualquier tipo de contenido o material a los Servicios.
                <br/>Debido a que nosotros podemos recolectar cierto tipo de información acerca de nuestros usuarios, creemos que usted debería entender completamente nuestra política, términos y condiciones entorno a la recopilación y uso de la información. Esta Política de privacidad muestra qué información recopilamos y cómo la usamos. La información privada que usted proporcione a los Servicios se utilizará solo para el propósito previsto.
                <br/>AL VISITAR, USAR, CONSUMAR UNA TRANSACCIÓN FINANCIERA O NAVEGAR POR LOS SERVICIOS, ESTÁ AFIRMANDO QUE HA LEÍDO, ENTENDIDO Y ACEPTADO ESTÁ POLÍTICA DE PRIVACIDAD. SI NO ESTÁ DE ACUERDO CON ESTA POLíTICA DE PRIVACIDAD, NO UTILICE LOS SERVICIOS.
                <br/>1.      INFORMACIÓN QUE RECOLECTAMOS
                <br/>Nosotros quizá recolectamos información incluyendo: Información de salud personal, Información Personal e Información no personal, cuando interactúas con nosotros y los Servicios, como por ejemplo cuando Usted:
                <br/>Accede o usa los Servicios;
                <br/>Abre o responde a nuestros correos electrónicos;
                <br/>Contacta a nuestros servicios de atención al cliente;
                <br/>Visita cualquier página en línea que muestra nuestros anuncios o contenido;
                <br/>Realiza alguna compra en o a través de los Servicios (si aplica);
                <br/>2.      INFORMACIÓN DE SALUD PERSONAL (PHI) y HIPAA
                <br/>Cierta información demográfica, de salud y/o relacionada con la salud que DMV Eye Test recopila acerca de sus usuarios como parte de la prestación de servicios a los proveedores que quizá consideren como Información Personal Protegida (“PHI”, por sus siglas en inglés) bajo la (Ley de Transferencia y Responsabilidad de Seguros de Salud (“HIPAA”, por sus siglas en inglés). En particular, los resultados de su prueba de agudeza visual pueden ser considerados PHI. Sin embargo, la siguiente Información Personal provista por usted a DMV Eye Test fuera del contexto mencionado anteriormente, no es PHI. Por ejemplo, cuando Usted provee información directamente a Nosotros al utilizar nuestros servicios y herramientas interactivas, cuando voluntariamente provee información en cajas de texto a través de nuestra página web o responde a nuestras encuestas o cuestionarios, o cuando nos envía algún email o se comunica con nosotros, dicha información no es PHI. La ley HIPAA provee protecciones específicas para la privacidad y seguridad de la PHI y restringe como la PHI es utilizada u divulgada. DMV Eye Test solo puede utilizar PHI de manera según prescrita por ley.
                <br/>3.      INFORMACIÓN PERSONAL
                <br/>“Información Personal” significa información acerca de usted que lo identifica específicamente a usted o, que combinada con otra información que tenemos, puede ser usada para identificarlo a usted. Como regla general, nosotros no recolectamos su Información Personal cuando usted visita y/o usa los Servicios, a menos que usted elija darnos esa información a nosotros. Enviar Información Personal a través de los Servicios es voluntario. Haciendo esto, usted nos está dando su consentimiento para usar la información para el propósito previsto.
                <br/>(a) BASE LEGAL PARA RECOLECCIÓN DE SU INFORMACIÓN PERSONAL
                <br/>Nosotros recolectamos, procesamos y usamos su Información Personal para el propósito descrito en esta Política de Privacidad, basada al menos en uno de los siguientes fundamentos legales:
                <br/>· Con su consentimiento: Nosotros pedimos su consentimiento para procesar su información para fines específicos y usted tiene el derecho a retirar su consentimiento en cualquier momento.
                <br/>· Al ejecutar este acuerdo: Nosotros recopilamos y procesamos su Información Personal para brindarle los Servicios, siguiendo su aprobación de esta Política de Privacidad; para mantener y mejorar nuestros servicios; para desarrollar nuevos servicios para brindarle una mejor experiencia.
                <br/>· Intereses legítimos: nosotros procesamos su información para nuestros intereses legítimos mientras aplicamos salvaguardas adecuadas que protegen su privacidad. Esto significa que procesamos su información para cosas como detectar, prevenir o fraude abuso, seguridad, usabilidad, funcionalidad o problemas técnicos, proteger contra daños a los derechos, propiedad o seguridad de nuestras propiedad o la de nuestros usuarios o del público según lo requiera y permita la ley; ejecutar reclamos legales, incluyendo la investigación de posibles violaciones de esta Política de Privacidad; para cumplir y/o satisfacer nuestra obligación según las leyes, regulaciones, pautas, estándares de la industria y requisitos contractuales aplicables, procesos legales, citaciones o solicitudes gubernamentales, así como también nuestros Términos y procesos de facturación.
                <br/>(b) INFORMACIÓN QUE RECOLECTAMOS Y CÓMO RECOLECTAMOS LA INFORMACIÓN
                <br/>A través de los Servicios, nosotros quizá recolectamos información que puede identificarlo a usted cuando voluntariamente usted nos la envía. Su información Personal puede incluir su:
                <br/>Nombre;
                <br/>Dirección;
                <br/>Correo electrónico;
                <br/>Número de teléfono;
                <br/>Número de licencia de conducir;
                <br/>Información de Pago (como dirección de facturación, dígitos de la tarjeta de crédito);
                <br/>Datos demográficos (como edad, género, código postal);
                <br/>Fecha de nacimiento; y
                <br/>Otra información similar
                <br/>(c) ALMACENAMIENTO DE INFORMACION PERSONAL
                <br/>Nosotros tomaremos precauciones razonables, así como medidas físicas, técnicas y organizativas de acuerdo con los estándares de la industria, tal y como se describe en este documento, para proteger de pérdida, mal uso, acceso no autorizado, divulgación, alteración o destrucción de su Información Personal. Es posible que se utilicen salvaguardas informáticas, como firewalls y cifrado de datos, para proteger su información. Nosotros autorizamos el acceso a su información únicamente para aquellos empleados o agentes que lo requieran para cumplir con sus responsabilidades laborales y estos individuos tienen la obligación de tratar esta información como confidencial.
                <br/>Sin embargo, no se puede garantizar la seguridad de la información en Internet o transmitida a través de ella. La entrada de uso no autorizada, fallas de hardware o software y otros factores pueden comprometer la seguridad de su información personal. Toda la información que nos envíe electrónicamente o por correo electrónico es bajo su responsabilidad, debido a que la transmisión de información a través de Internet no es completamente segura.
                <br/>4.      INFORMACIÓN NO PERSONAL
                <br/>Nosotros recopilamos y almacenamos temporalmente cierta información sobre su uso de los Servicios. Información No-Personal significa información que por sí sola no puede identificarlo a usted, incluidos datos como cookies, etiquetas de píxeles, balizas web e información del dispositivo. La información incluye, sin limitación:
                <br/>· Datos del dispositivo: Nosotros podemos recopilar información como: el tipo de computadora y/o dispositivo móvil que usa; el ID de dispositivo único de su computadora y/o dispositivo móvil; la dirección IP de su computadora y/o dispositivo móvil; el sistema operativo de su computadora y/o dispositivo móvil; y el tipo de navegadores de Internet de su computadora y/o dispositivo móvil.
                <br/>· Detalles de uso: Cuando usted accede y usa los Servicios, podemos recopilar automáticamente ciertos detalles de su acceso y uso de los Servicios, incluidos datos de tráfico, datos de ubicación, registros y otros datos de comunicación y los recursos a los que accede y usa en o a través de la aplicación.
                <br/>· Información de ubicación: Cuando usted visita o usa los Servicios por primera vez, podemos solicitar permiso para recopilar y usar la geolocalización precisa de su dispositivo. Puede optar por no permitir la recopilación de esta información, pero es posible que no pueda utilizar los Servicios proporcionados por DMV Eye Test sin acceso a su ubicación. Nosotros necesitamos la información de su ubicación para poder brindarle nuestros Servicios. Usted puede controlar cómo y si recopilamos su información precisa de geolocalización a través de la configuración de su dispositivo.
                <br/>Nosotros podemos utilizar información que no sea ni información personal ni PHI (incluida la información personal que no es PHI que ha sido desidentificada y/o agregada) para comprender mejor quién usa nuestros Servicios y cómo podemos brindar una mejor experiencia, o de otra manera a nuestra discreción.
                <br/>Si usted no desea que recopilemos esta información, puede cambiar la configuración de privacidad de su dispositivo o NO ACCEDER NI UTILIZAR LOS SERVICIOS.
                <br/>5.      TECNOLOGÍAS DE RECOPILACIÓN DE INFORMACIÓN
                <br/>Las tecnologías que nosotros utilizamos para la recopilación automática de información pueden incluir:
                <br/>· Cookies (o cookies móviles): nosotros podemos usar “cookies” para ayudarlo a usted a personalizar su experiencia en línea. Una cookie es un archivo de texto que un servidor de páginas web coloca en su disco duro. Las cookies no se pueden utilizar para ejecutar programas o enviar virus a su computadora. Las cookies se le asignan exclusivamente a usted y únicamente pueden ser leídas por el servidor web en el dominio que emitió la cookie. El propósito de una cookie es decirle al servidor web que ha regresado a una página específica y almacenar información para ofrecerle una mejor experiencia. Usted tiene la posibilidad de aceptar o rechazar cookies. La mayoría de los navegadores web aceptan cookies automáticamente, pero usualmente usted puede modificar la configuración de su navegador para rechazar las cookies si así usted lo prefiere. Si usted opta por rechazar las cookies, es posible que no pueda experimentar por completo las funciones interactivas de los Servicios.
                <br/>· Almacenamiento local: Nosotros podemos usar almacenamiento local para que su experiencia sea más fluida. El almacenamiento local es un archivo creado por un sitio web en los datos de su dispositivo que persiste incluso si actualiza la página o cierra el navegador. El almacenamiento local puede desactivarse accediendo a la configuración de almacenamiento de datos y cookies en su navegador.
                <br/>· Cookies de publicidad/redireccionamiento: Las cookies de publicidad/redireccionamiento recopilan datos sobre su actividad en línea e identifican sus intereses para que podamos proporcionarle publicidad que creemos que es relevante para usted.
                <br/>· Balizas web: Los Servicios y nue
                </p>
            )
        case 1:
            return(
                <p>
                     Este sitio web es operado por Sharp Vision Plan d/b/a DMV Eye Test, una sociedad de responsabilidad limitada de Nueva York (en adelante, “DMV Eye Test” o, “Nosotros"). Estos términos y condiciones (los “Términos”) rigen su acceso al sitio web de DMV Eye Test: https://www.dmveyetest.com, y a cualquier otro servicio web que sea propiedad, esté controlado u sea ofrecido por DMV Eye Test ahora oen el futuro (todos ellos denominados colectivamente los “Servicios"). El término “Usted“o “Usuario” se referirá a cualquier persona o entidad que vea, utilice, acceda, navegue o envíe cualquier contenido o material a los Servicios. 
                <br/> Estos términos son importantes y afectan a sus derechos legales, así que por favor léalos cuidadosamente. Tenga en cuenta que estas Condiciones contienen una disposición de arbitraje obligatorio que requiere el uso del arbitraje de forma individual y que limita los recursos disponibles para usted en caso de determinadas disputas. 
                <br/> Los Servicios se le ofrecen a condición de que usted acepte sin modificaciones los Términos aquí contenidos. Ciertas características, servicios o herramientas de los Servicios pueden estar sujetas a directrices, términos o reglas adicionales que se publicarán con esas características y forman parte de estos Términos. El uso de los Servicios por parte del usuario constituye su aceptación de todos estos Términos. Lea atentamente estos términos. 
                <br/> USTED RECONOCE Y ACEPTA QUE AL ACCEDER, USAR, CONSUMAR UNA TRANSACCIÓN FINANCIERA O NAVEGAR POR LOS SERVICIOS, ESTÁ AFIRMANDO QUE HA LEÍDO, ENTENDIDO Y ACEPTADO ESTOS TÉRMINOS. SI NO ESTÁ DE ACUERDO CON ESTOS TÉRMINOS, ENTONCES NO TIENE DERECHO A ACCEDER O UTILIZAR LOS SERVICIOS. 
                <br/> 1.      POLÍTICA DE PRIVACIDAD 
                <br/> El uso de los Servicios está sujeto a la Política de Privacidad de DMV Eye Test. Our Política de Privacidad detalla cómo podemos usar, compartir y mantener la información que usted comparte voluntariamente con nosotros, que puede incluir, sin limitación, su nombre, dirección, fecha de nacimiento, número de licencia de conducir, información de contacto y otra información de identificación personal (colectivamente, “Información Personal"). 
                <br/> 1.      USO DE NUESTROS SERVICIOS 
                <br/> DMV Eye Test ofrece exámenes de agudeza visual a distancia para cumplir con los requisitos de la prueba de visión para la renovación de la licencia de conducir. DMV EYE TEST NO ES UNA AGENCIA O ENTIDAD DEL GOBIERNO, Y NO ESTÁ AFILIADO O ACTUANDO EN NOMBRE DEL DEPARTAMENTO DE VEHÍCULOS DE MOTOR O CUALQUIER AGENCIA DEL GOBIERNO. DMV EYE TEST ESTÁ AUTORIZADO POR LOS AGENCIAS GUBERNAMENTALES CORRESPONDIENTES PARA PROPORCIONAR Y PRESENTAR LOS RESULTADOS DE LAS PRUEBAS DE VISIÓN. 
                <br/> Usted reconoce que los servicios prestados por DMV Eye Test no crean una relación de médico y paciente, y no constituyen una opinión, consejo médico o diagnóstico ni tratamiento, sino que se prestan con el único propósito de ayudarle a obtener un examen de agudeza visual para la renovación de su licencia de conducir y presentarlo a la entidad gubernamental correspondiente. 
                <br/> Sujeto a su cumplimiento de las obligaciones de estos Términos, le proporcionaremos acceso a los Servicios. El acceso a los Servicios se permite de forma temporal, y nos reservamos el derecho de retirar o modificar el servicio que ofrecemos sin previo aviso. No seremos responsables si por alguna razón nuestros Servicios no están disponibles en cualquier momento ni durante cualquier período. 
                <br/> Debe tener dieciocho (18) años o más para poder usar los Servicios. Al acceder a los Servicios, usted declara y garantiza que es un adulto, y que es capaz de entrar y cumplir legalmente con todas las obligaciones descritas en este Acuerdo. No recopilamos a sabiendas, ni en línea ni fuera de ella, información personal de personas menores de 13 años. 
                <br/> 2.      NO ES UN PROVEEDOR DE ASESORAMIENTO MÉDICO 
                <br/> Toda información suministrada a través de los Servicios o por cualquiera de nuestros empleados o agentes, ya sea por teléfono, correo electrónico, videollamada, carta, fax u otra forma de comunicación, tiene fines informativos o de orientación general y no constituye un consejo médico u otro tipo de asesoramiento profesional. La información relacionada con la salud que se proporciona a través de los Servicios no sustituye al consejo médico y es importante que no tome decisiones médicas sin consultar primero a su médico personal u otro profesional de la salud. 
                <br/> Esta prueba de agudeza visual en línea no es una evaluación médica y no reemplaza una visita a un profesional de la visión. No está diseñado para ser usado como un diagnóstico de enfermedad u otras condiciones, para el tratamiento, ni para la mitigación o prevención de la enfermedad. Esta prueba simplemente tiene como objetivo darle una idea general acerca de su capacidad visual de manera que pueda renovar su licencia de conducir. Le recomendamos que siga esta prueba con una evaluación completa de la visión por parte de un especialista en cuidado de la visión. Sólo los profesionales de la visión pueden tomar decisiones sobre el tratamiento médico, el diagnóstico o prescripción médica. NO UTILICE LOS SERVICIOS PARA NECESIDADES MÉDICAS DE EMERGENCIA. SI EXPERIMENTA UNA EMERGENCIA MÉDICA, LLAME INMEDIATAMENTE A UN PROFESIONAL DE LA SALUD Y AL 911. NADA DE LO DECLARADO O PUBLICADO EN LOS SERVICIOS O DISPONIBLE A TRAVÉS DE CUALQUIER SERVICIO TIENE LA INTENCIÓN DE SER, Y NO DEBE SER TOMADO COMO, LA PRÁCTICA DE LA MEDICINA U OTRO CONSEJO PROFESIONAL DE SALUD, O LA PROVISIÓN DE ATENCIÓN MÉDICA. 
                <br/> SU USO DE LOS SERVICIOS NO ES UN SUSTITUTO DE LA ASISTENCIA MÉDICA. NO SE CREA UNA RELACIÓN PROFESIONAL MÉDICO-PACIENTE CUANDO USA LOS SERVICIOS O EL CONTENIDO. ESTO ES CIERTO TANTO SI DICHO CONTENIDO ES PROPORCIONADO POR O A TRAVÉS DEL USO DE LOS SERVICIOS O A TRAVÉS DE CUALQUIER OTRA COMUNICACIÓN DE DMV EYE TEST. 
                </p>
            )
    }
}

