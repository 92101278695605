import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { selectUser,login } from "../store/features/UserSlice"
import { AccountSettings } from "./dashboard/AccountSettings"
import { StyledDropZone } from 'react-drop-zone'
import Upload from '../upload.svg'
import { ResetTest, SetUpLicense, UpdateLicense, UploadImages } from "../util/utilities"
import axios from "axios"
import ReactToPdf from 'react-to-pdf'
import { PDFDownloader } from "./dashboard/PDFDownloader"
import { Link } from "react-router-dom"
import {Header} from './Header'
import { useDispatch } from "react-redux"

export const DashBoard = () => {

    const user = useSelector(selectUser)
    const [selectedMenu, setSelectedMenu] = useState(0)

    const [frontID] = useState(user.frontLicense)
    const [backID] = useState(user.backLicense)

    const [front, setFront] = useState();
    const [back, setBack] = useState();

    const [frontPreview,setFrontPreview] = useState()
    const [backPreview,setBackPreview] = useState()

    const [licenseNum, setLicenseNum] = useState(user.licenseNum)

    const [msg,setMsg] = useState('')
    const [canEdit, setCanEdit] = useState(false)
    const frontLicense = useRef(null)
    const backLicense = useRef(null)
    const pdf = useRef(null)

    const [transactions, setTransactions] = useState()
    const [selectedTransaction, setSelectedTransaction] = useState({
        date: '',
        id: 0,
        state: '',
        userID: 0
    })

    useEffect(()=>{
        async function FetchLicense(){
            if(frontID || backID)
              {  let data = {
                    front: frontID,
                    back: backID
                }
                await axios.post(`${process.env.REACT_APP_SERVER_LINK}/getLicense`,data,
               { headers: {
                    "Content-Type": "application/json",
                    'Access-Control-Allow-Origin': '*',
                    }}
                ).then( res => {
                   let data = res.data
                   setFrontPreview(data[0].path)
                   setBackPreview(data[1].path)
    
                })}
        }
        FetchLicense()
    },[selectedMenu])

    const dispatch = useDispatch()
    
    useEffect(()=>{
        if(canEdit){
            let inputs = document.getElementsByTagName('input')
            for( let i = 0; i < inputs.length ; i++ )
                inputs[i].classList.remove('disabled')     
        }
        else{
            let inputs = document.getElementsByTagName('input')
                for( let i = 0; i < inputs.length ; i++ )
                    inputs[i].classList.add('disabled')     
        }
    },[canEdit])

    const States = () =>{
        switch(user.usState){
            case 'NewYork':
                return 'New York'
            case 'florida':
                return 'Florida'
            case 'NorthCarolina':
                return 'North Carolina'
        }
    }

    const TransactionTable = () =>{
        if(transactions)
            return  transactions.map((transaction,key)=>(
                <tr>
                <td>{transaction.date}</td>
                <td>{transaction.id}</td>
                <td>**** *** 6655</td>
                <td>$100.00</td>
            </tr>
            ))
        else return ''
    }
    useEffect(()=>{
        async function fetchLicense(){
            let backend = process.env.REACT_APP_SERVER_LINK
            if(user.frontLicense && user.backLicense){
                let data ={
                    front : user.frontLicense,
                    back : user.backLicense
                }
                await axios.post(`${process.env.REACT_APP_SERVER_LINK}/getLicense`, data,{
                        headers: {
                          "Content-Type": "application/json",
                          'Access-Control-Allow-Origin': '*',
                          }
                        }
                    ).then(res => console.log(res))
            }
           await axios.post(`${backend}/transactions` , {id: user.id} ,{
            headers: {
              "Content-Type": "application/json",
              'Access-Control-Allow-Origin': '*',
              }
            } ).then( res =>{
                setTransactions(res.data)
            })
        }
        fetchLicense()
        console.log()
    },[])

    useEffect(()=>{
        async function FetchLicense(){
            if(frontID || backID)
              {  let data = {
                    front: frontID,
                    back: backID
                }
                await axios.post(`${process.env.REACT_APP_SERVER_LINK}/getLicense`,data,
               { headers: {
                    "Content-Type": "application/json",
                    'Access-Control-Allow-Origin': '*',
                    }}
                ).then( res => {
                   let data = res.data
                   setFrontPreview(data[0].path)
                   setBackPreview(data[1].path)
    
                })}
        }
        FetchLicense()
    },[frontID,backID])

    useEffect(()=>{
        console.log(frontPreview,backPreview)
    },[frontPreview,backPreview])


    function getPdf(id){
      setSelectedTransaction(transactions.find(transaction => {return transaction.id === id}))
    }

    useEffect(()=>{
     console.log('Transactions: ',transactions)

    },[transactions])

    function UploadLicense(e){
        setMsg('Loading...')
        e.preventDefault();
        if(!front || !back){
            if(!user.frontLicense)
            setMsg('Please select a picture to upload')
            else
            { 
                if(licenseNum){
                    SetUpLicense(null,null,user.id,licenseNum).then(res =>{
                        if(res.status === 200){
                            
                            async function Update(){
                                await axios.post(`${process.env.REACT_APP_SERVER_LINK}/loadUpdate`,{id:user.id}).then(
                                  res =>{
                                    let data = res.data
                                    let userData ={
                                      id: data.id,
                                      firstName: data.firstName,
                                      lastName: data.lastName,
                                      address: data.address,
                                      usState: data.state,
                                      zipCode: data.zipCode,
                                      city: data.city,
                                      phoneNumber: data.phoneNumber,
                                      email: data.email,
                                      frontLicense: data.license_front_id,
                                      backLicense: data.license_back_id,
                                      licenseNum: data.licenseNum,
                                      loggedIn: true,
                                      tests_taken: data.tests_taken,
                                      tests_passed: data.tests_passed,
                                      test_in_progress: data.test_in_progress
                                      }
                                      localStorage.setItem('user', JSON.stringify(userData))
                                  dispatch(
                                      login(userData)
                                      )
                                  }
                                )}
                                Update()
                                    setMsg('Success!')
                                    setCanEdit(false)
                        }
                        else
                        setMsg('Something went wrong, please try again later')
                    })
                    
                }
                else{
                    setMsg('Please fill out your license number.')
                }
            }
        }
        else{
            if(licenseNum){
                UploadImages(front,back,user.id,licenseNum,user.frontLicense, user.backLicense).then(res =>
                     {
                         if(res.status === 200)
                         {
                             setMsg('Success!')
                             setCanEdit(false)
                            } 
                        })
                        async function Update(){
                            await axios.post(`${process.env.REACT_APP_SERVER_LINK}/loadUpdate`,{id:user.id}).then(
                              res =>{
                                let data = res.data
                                let userData ={
                                  id: data.id,
                                  firstName: data.firstName,
                                  lastName: data.lastName,
                                  address: data.address,
                                  usState: data.state,
                                  zipCode: data.zipCode,
                                  city: data.city,
                                  phoneNumber: data.phoneNumber,
                                  email: data.email,
                                  frontLicense: data.license_front_id,
                                  backLicense: data.license_back_id,
                                  licenseNum: data.licenseNum,
                                  loggedIn: true,
                                  tests_taken: data.tests_taken,
                                  tests_passed: data.tests_passed,
                                  test_in_progress: data.test_in_progress
                                  }
                                  localStorage.setItem('user', JSON.stringify(userData))
                              dispatch(
                                  login(userData)
                                  )
                              }
                            )}
                            Update()                              
                }
            else{
                setMsg('Please fill out your license number.')
            }
        }
    }
    useEffect(()=>{
        if(front){
            let reader = new FileReader();
            reader.onloadend = ()=>{
                setFrontPreview(reader.result)
            }
            reader.readAsDataURL(front)
        }
        else{
            setFrontPreview()
        }
        if(back){
            let reader = new FileReader();
            reader.onloadend = ()=>{
                setBackPreview(reader.result)
            }
            reader.readAsDataURL(back)
        }
        else{
            setBackPreview()
        }
    },[front,back])

    async function TestReset(){
        await ResetTest(user.id).then(
            res =>{
                console.log(res)
                if(res.status === 200)
                window.location.href = `${process.env.REACT_APP_LINK}/test-preparations`
            }
        )
    }

    const DashBoardView = () =>{
        switch(selectedMenu){
            case 0:
                return (
                    <>
                    <div className="header-dashboard">
                    <h1>Dashboard</h1>
                    </div>
                    <div className="banner">
                        <div>
                            <h3>Time to renew your driver’s licence?</h3>
                            {
                                user.test_in_progress ?
                                <>
                                <Link to={'/test-preparations'}><button style={{marginRight:'1rem'}} className="btn-purple btn-medium">Resume test</button></Link>
                                <button onClick={()=>{TestReset()}} className="btn-blue btn-medium">Start Over</button>
                                </>
                                :
                                <Link to={'/test-preparations'}><button className='btn-purple'>begin test</button></Link>

                            }
                        </div>
                        <img src="./images/nurse.png" alt="" />
                    </div>
                    <div className="activity">
                        <h3>Activity</h3>
                        <div className="activity-info">
                            <div className="test-info">
                                <img src="./images/test-taken.png" alt="" />
                                <div className="info">
                                    <h3>Tests taken</h3>
                                    <p>{user.tests_taken}</p>
                                </div>
                            </div>
                            <div className="test-info">
                                <img src="./images/test-incomplete.png" alt="" />
                                <div className="info">
                                    <h3>Incomplete</h3>
                                    <p>{
                                        user.test_in_progress ?
                                        1
                                        :
                                        0
                                    }</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="activity">
                        <h3>Past Transactions</h3>
                        <table className="transactions">
                            <tbody>
                                <tr>
                                    <th>Date</th>
                                    <th>No.</th>
                                    <th>Card</th>
                                    <th>Quantity</th>
                                </tr>
                                {
                                   TransactionTable()
                                }
                            </tbody>
                        </table>
                </div>
                </>
                )
            case 1:
                return <AccountSettings user={user} />
            case 2:
                return(
                    <div className="header-dashboard">
                    <h1>Payment Methods</h1>
                    <div className="payment">
                        <div className="payment-item">
                            <div className="type">
                                <img src="./images/payment.png" alt="" />
                                <h2>Visa</h2>
                            </div>
                            <p>
                            ******* **** 7451
                            </p>
                            <p className='delete'>
                                Delete
                            </p>
                        </div>
                        <div className="payment-item">
                            <div className="type">
                                <img src="./images/payment.png" alt="" />
                                <h2>Visa</h2>
                            </div>
                            <p>
                            ******* **** 7451
                            </p>
                            <p className='delete'>
                                Delete
                            </p>
                        </div>
                        <div className="payment-item">
                            <div className="type">
                                <img src="./images/payment.png" alt="" />
                                <h2>Visa</h2>
                            </div>
                            <p>
                            ******* **** 7451
                            </p>
                            <p className='delete'>
                                Delete
                            </p>
                        </div>
                    </div>
                </div>
                )
            case 3:
                return(
                    <>
                    <div className="header-dashboard">
                    <h1>Transaction History</h1>
                    </div>
                    <div className="pdf-container transaction-pdf">
                        <div ref={pdf} className={`pdf ${selectedTransaction.state}`}>
                           <PDFDownloader user={user} transaction={selectedTransaction} />
                        </div>
                    </div>
                    <div className="activity a1">
                        <table className="transactions">
                        <tbody>
                            <tr>
                                <th>Date</th>
                                <th>No.</th>
                                <th>Card</th>
                                <th>Quantity</th>
                                <th></th>
                            </tr>
                            {
                                transactions ?
                                transactions.map(transaction => 
                                    <tr>
                                        <td>{transaction.date}</td>
                                        <td>{transaction.id}</td>
                                        <td>**** *** 6655</td>
                                        <td>$100.00</td>
                                        <td>
                                        <ReactToPdf scale={0.88} targetRef={pdf} filename={`${user.firstName}${user.lastName}DMVEyeTest-${transaction.date}-${transaction.state}-${transaction.id}.pdf`}>
                                            {({toPdf}) => (
                                                <button onClick={()=>
                                                     {
                                                         getPdf(transaction.id)
                                                         setTimeout(()=>{
                                                             toPdf()
                                                         },1000)
                                                     }
                                                    } className="btn-purple">See Results</button>
                                            )}
                                            </ReactToPdf>
                                            
                                        </td>
                                    </tr>
                                )
                                :
                                ''
                            }
                        </tbody>
                        </table>
                    </div>
                    </>
                )
            case 4:
                return(
                    <>
                          <div className="header-dashboard">
                             <h1>Driver’s Licence Information</h1>
                         </div>
                         <form className='license-settings' onSubmit={e=> UploadLicense(e)}>
                            <div className="form-row">
                                <div className="drop-zone">
                                    <input className='input-full disabled' disabled type="text" defaultValue={States()} />
                                </div>
                                <div className="drop-zone">
                                    <input pattern="[\d]{9}" className='input-full disabled'onChange={(e)=> setLicenseNum(e.target.value)} type="text" defaultValue={user.licenseNum} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="drop-zone">
                                    <h1>front</h1>
                                    <StyledDropZone accept={'image/*'}  onDrop={(file, text) => setFront(file)}>
                                        {
                                            frontPreview ?
                                            <img className='license' src={frontPreview} alt="" />
                                            :
                                            <div ref={frontLicense} className="center">
                                            <img src={Upload} alt="" />
                                            <h3>Drag and drop Photo</h3>
                                        </div>
                                        }
                                    </StyledDropZone>
                                            <p onClick={()=> frontLicense.current.click()}>or <span className='lnk-blue'>import from files</span></p>
                                </div>
                                <div className="drop-zone">
                                    <h1>back</h1>
                                    <StyledDropZone accept={'image/*'} onDrop={(file, text) => setBack(file)}>
                                    {
                                            backPreview ?
                                            <img className='license' src={backPreview} alt="" />
                                            :
                                            <div ref={backLicense} className="center">
                                            <img src={Upload} alt="" />
                                            <h3>Drag and drop Photo</h3>
                                        </div>
                                        }
                                    </StyledDropZone>
                                        <p onClick={()=> backLicense.current.click()}>or <span className='lnk-blue'>import from files</span></p>
                                </div>
                            </div>
                            <div className="form-row">
                                <p className="lnk-blue">{msg}</p>
                                {
                                    canEdit ?
                                        <input type='submit' value='Save Changes' className='btn-purple btn-medium' />
                                    :
                                    <button onClick={()=> setCanEdit(true)} className="btn-purple btn-medium">Edit</button>
                                }
                            </div>
                         </form>
                    </>
                )
        }
    }
    return (
        <>
        <Header />
        <div className="App gradient-back">
            <div className="dashboard-container">
                <div className="dashboard-menu">
                    <h3>Hello, {user.firstName}</h3>
                    <div className="menu">
                        <div onClick={()=> setSelectedMenu(0)} className="menu-item">
                            <img src="./images/dash.png" alt="dashboard" />
                             <p>Dashboard</p>
                        </div>
                        <div onClick={()=> setSelectedMenu(1)} className="menu-item">
                            <img src="./images/Settings.png" alt="dashboard" />
                             <p>Account Settings</p>
                        </div>
                        {/* <div onClick={()=> setSelectedMenu(2)} className="menu-item">
                            <img src="./images/payment.png" alt="dashboard" />
                             <p>Payment Methods</p>
                        </div> */}
                        <div onClick={()=> setSelectedMenu(3)} className="menu-item">
                            <img src="./images/transactions.png" alt="dashboard" />
                             <p>Transaction History</p>
                        </div>
                        <div onClick={()=> setSelectedMenu(4)} className="menu-item">
                            <img src="./images/license.png" alt="dashboard" />
                             <p>License Information</p>
                        </div>
                    </div>
                </div>
                <div className="content">
                    {DashBoardView()}
                </div>
            </div>
        </div>
        </>
    )
}

