import { createSlice } from "@reduxjs/toolkit";


export const DimensionsSlice = createSlice({
    name: 'dimensions',
    initialState:{
        dimensions: null
    },
    reducers:{
        setSync: (state,action) => {
                state.dimentions = action.payload;
        },
        removeSync: (state)=>{
            state.dimentions = null;
        }
    }
})

export const {setDimentions, removeDimentions} = DimensionsSlice.actions;

export const getDimensions = (state) => state.dimensions.dimensions;

export default DimensionsSlice.reducer;