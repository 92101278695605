import { FloridaTemplate } from "./PDFTemplates/FloridaTemplate"
import { NewYorkTemplate } from "./PDFTemplates/NewYorkTemplate"
import { NorthCarolinaTemplate } from "./PDFTemplates/NorthCarolinaTemplate"

export const PDFDownloader = ({transaction,user}) => {

    const PDFSwitcher = () =>{
        switch(transaction.state){
            case 'florida':
                return <FloridaTemplate transaction={transaction}  />
            case 'NewYork':
                return <NewYorkTemplate transaction={transaction} />
            case 'NorthCarolina':
                return <NorthCarolinaTemplate transaction={transaction} />
            default:
                return <h1>Error! invalid state</h1>
        }
    }
    return (
       <PDFSwitcher />
    )
}

