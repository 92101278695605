import '../PDFStyles/NewYork.css'
import '../PDFStyles/NewYorkSmall.css'
import React,{ useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../store/features/UserSlice'


export const NewYork = React.memo((props) => {

    const user = useSelector(selectUser)

    const [DOBMonth,setDOBMonth] = useState('00')
    const [DOBDay,setDOBDay] = useState('00')
    const [DOBYear,setDOBYear] = useState('00')

    const [data, setData] = useState({
        firstName: user.firstName,
        lastName: user.lastName,
        DOB: DOBMonth + '/' + DOBDay + '/' + DOBYear,
        gender: 0,
        address: user.address,
        aptNum: 'Apt#',
        city: user.city,
        country: 'Country',
        zipCode: user.zipCode,
        prescription: 0
    })

    useEffect(()=>{
        let list = document.getElementsByTagName('input')
        for( let i = 0 ; i < list.length ; i++){
            list[i].style.width = ((list[i].value.length+1 ) * 7) +'px'
        }
        if(localStorage.getItem('Prescription'))
            setData({...data,prescription:1})

        console.log('render')
    },[])

    const AdjustSize = (e) =>{
        e.preventDefault()
        let obj = e.target
         obj.style.width = ((e.target.value.length+1 ) * 7) + 'px'
         if(e.target.name){
             setData({...data, [e.target.name]: e.target.value})
         }

    }
    const ChangeRadio = (e) =>{
        setData({...data, [e.target.name]: e.target.value})
    }
    useEffect(()=>{
        setData({...data,
        DOB: DOBMonth + '/' + DOBDay + '/' + DOBYear
    })
    },[DOBDay,DOBMonth,DOBYear])

    useEffect(()=>{
        let isnum = /^\d+$/.test(data.zipCode)
        if(data.zipCode.length !== 5 || !isnum)
        {
            document.getElementById('zip').classList.add('bad-input')
        }
        else{
            document.getElementById('zip').classList.remove('bad-input')
        }
        if(props.canEdit)
        localStorage.setItem('data', JSON.stringify(data))
    },[data])

    return (
        <>
            <img src="./images/pdfs/newYork.jpg" alt="New York Form" />
            <input name='firstName' onChange={e => { AdjustSize(e)}} className='FirstName' defaultValue={data.firstName} type="text"/>
            <input name='lastName' onChange={e => { AdjustSize(e)}} className='lastName' defaultValue={data.lastName} type="text" />
            <input onChange={e => { AdjustSize(e); setDOBMonth(e.target.value)}} className='DOBMonth' defaultValue={DOBMonth} type="text" />
            <input onChange={e => { AdjustSize(e); setDOBDay(e.target.value)}} className='DOBDay' defaultValue={DOBDay} type="text" />
            <input onChange={e => { AdjustSize(e); setDOBYear(e.target.value)}} className='DOBYear' defaultValue={DOBYear} type="text" />
            <input name='address' onChange={e => { AdjustSize(e)}} className='Address' defaultValue={data.address} type="text" />
            <input name='aptNum' onChange={e => { AdjustSize(e)}} className='AptNum' defaultValue={data.aptNum} type="text" />
            <input name='city' onChange={e => { AdjustSize(e)}} className='City' defaultValue={data.city} type="text" />
            <input name='country' onChange={e => { AdjustSize(e)}} className='Country' defaultValue={data.country} type="text" />
            <input id="zip" name='zipCode' onChange={e => { AdjustSize(e)}} className='Zip' defaultValue={data.zipCode} type="text" />
            <div onChange={e =>ChangeRadio(e)}>
                <input name='gender' type="radio" value={0} className='MaleCheckSelect'/>
                <input name='gender' type="radio" value={1} className='FemaleCheckSelect'/>
            </div>
            <p className='State'>New York</p>
            <p className='DateM'>{new Date().getMonth()+1}</p>
            <p className='DateD'>{new Date().getDate()}</p>
            <p className='DateY'>{new Date().getFullYear()}</p>
            <p className="AcuityTestYes">&#x2713;</p>
            {
                localStorage.getItem('Prescription') ?
                <p className="LenseCheckYes">&#x2713;</p>
                :
                <p className="LenseCheckNo">&#x2713;</p>

            }
            <p className='DoctorName'></p>
            <p className='DoctorAddress'></p>
            <p className='DoctorCity'></p>
            <p className='DoctorState'></p>
            <p className='DoctorCountry'></p>
            <p className='DoctorZip'></p>
            <p className="Valid12">&#x2713;</p>
            <p className="Valid6"></p>
            <p className="DoctorSign"></p>
            <p className="DoctorLicense"></p>
        </>
    )
})

