import { createSlice } from "@reduxjs/toolkit";


export const userSlice = createSlice({
    name: 'user',
    initialState:{
        user: JSON.parse(localStorage.getItem('user'))
    },
    reducers:{
        login: (state,action) => {
                state.user = action.payload;
        },
        logout: (state)=>{
            window.location.href=`${process.env.REACT_APP_LINK}`
            state.user = null;
            localStorage.removeItem('user')
        },
        setTestState:  (state, action)=>{
            state.user.finishedTest = action.payload.change
        }
    }
})

export const {login, logout, setTestState} = userSlice.actions;

export const selectUser = (state) => state.user.user;

export default userSlice.reducer;