import React,{useEffect, useState} from 'react'
import { StripeContainer } from "./StripeContainer"
import {PdfEdit} from './PdfEdit'
import { BrowserView, MobileView } from 'react-device-detect'
import {Header} from './Header'
import { useSelector } from 'react-redux'
import { selectUser } from '../store/features/UserSlice'

export const EndController = React.memo(() => {

    const [step, setStep] = useState(0)

    const user = useSelector(selectUser)

    const [canEdit, setCanEdit] = useState(true)




    const Views = () =>{
        if(!step)
            return(
                <PdfEdit setStep={setStep} canEdit={canEdit} setCanEdit={setCanEdit} />
            )
        else
            return(
                <div className="App gradient-back">
                    <div className="payment-container">
                        <StripeContainer />
                    </div>
                </div>
            )
    }

    return (
        <>
        <BrowserView>
            <Header />
            <Views />
        </BrowserView>
        <MobileView>
            <div className="App">
                <h1 style={{maxWidth:'20ch',textAlign:'center'}}>You don't need your mobile device anymore.
                    Please continue on your desktop.
                </h1>
            </div>
        </MobileView>
        </>
    )
})

