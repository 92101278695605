import React, { useState } from 'react'
import { UpdateUserData } from '../../util/auth'
import { useDispatch } from "react-redux"
import { login } from '../../store/features/UserSlice'
import axios from 'axios'

export const AccountSettings = (props) => {

    const user = props.user
    const dispatch = useDispatch()

    const[firstName,setFirstName] = useState(user.firstName)
    const[lastName,setLastName] = useState(user.lastName)
    const[phoneNumber,setPhoneNumber] = useState(user.phoneNumber)
    const[address,setAddress] = useState(user.address)
    const[lstate,setLstate] = useState(user.usState)
    const[zipcode,setZipCode] = useState(user.zipCode)
    const[city,setCity] = useState(user.city)
    const[email,setEmail] = useState(user.email)
    const[password,setPassword] = useState('')

    const [canEdit,setCanEdit] = useState(false)
    const [err,setErr] = useState(false)
    const [status,setStatus] = useState('')

    function EnableEdit(){
        let inputs = document.getElementsByClassName('input-full')

      for(let i =0 ; i< inputs.length; i++){
          inputs[i].classList.remove('disabled')
            inputs[i].disabled = false;
      }

        setCanEdit(true)
    }
    function DisableEdit(){
        let inputs = document.getElementsByClassName('input-full')
        
        for(let i = 0; i< inputs.length ; i++){
            inputs[i].classList.add('disabled')
        }

        setCanEdit(false)
    }


    async function UpdateUser(e){

        e.preventDefault();
       
        { if(password === ''){
            setErr('* please enter your password')
        }else{
            setErr('')
            setStatus('loading...')
            let data = {
                ID: user.id,
                FirstName: firstName,
                LastName: lastName,
                Phone: phoneNumber,
                Address: address,
                State: lstate,
                ZipCode: zipcode,
                City: city,
                Email: email,
                Password: password
            }
            let config = {
                headers: {
                  "Content-Type": "application/json",
                  'Access-Control-Allow-Origin': '*',
                  }
                }
            await axios.post(`${process.env.REACT_APP_SERVER_LINK}/update-user`,data,config).then(res =>{
                console.log(res)
               if(res.status === 200){
                    let userData ={
                        id: user.id,
                        firstName: firstName,
                        lastName: lastName,
                        phone: phoneNumber,
                        address: address,
                        usState: lstate,
                        zipCode: zipcode,
                        city: city,
                        email: email,
                        frontLicense: user.frontLicense,
                        backLicense: user.backLicense,
                        licenseNum: user.licenseNum,
                        loggedIn: true,
                        tests_taken: user.tests_taken,
                        tests_passed: user.tests_passed,
                        test_in_progress: user.test_in_progress
                        }
                        dispatch(
                            login(userData)
                            )
                    localStorage.setItem('user', JSON.stringify(userData))
                    setStatus('Success!')
                    DisableEdit()
               }
        }).catch(
            err =>
            {
                if(err.response.status === 401){
                    setStatus('')
                    setErr('Invalid Password.')
                }
                else{
                    setStatus('')
                    setErr('Something went wrong, please try again')
                }
            }
        )
        }

    }
}

    return (
        <>
        <div className="header-dashboard">
            <h1>Settings</h1>
        </div>
        <form onSubmit={(e)=> UpdateUser(e)} className='acc-settings'>
            <div className="form-row">
                <div className="input-wrapp acc-fname">
                    <input className='input-full disabled' onChange={(e)=> setFirstName(e.target.value)} disabled type="text" defaultValue={user.firstName} />
                </div>
                <div className="input-wrapp acc-lname">
                    <input className='input-full disabled'onChange={(e)=> setLastName(e.target.value)} disabled type="text" defaultValue={user.lastName} />
                </div>
            </div>
            <div className="form-row">
                <div className="input-wrapp acc-phone">
                    <input className='input-full disabled' pattern="[0-9]+}" maxLength="12" onChange={(e)=> setPhoneNumber(e.target.value)} disabled type="text" defaultValue={user.phoneNumber}/>
                </div>
                <div className="input-wrapp acc-address">
                    <input className='input-full disabled'onChange={(e)=> setAddress(e.target.value)} disabled type="text" defaultValue={user.address} />
                </div>
            </div>
            <div className="form-row">
                <div className="input-wrapp acc-state">
                    <select className="input-full" disabled onChange={(e)=> setLstate(e.target.value)} defaultValue={lstate} name="cars" id="cars">
                        <option value="">State</option>
                        <option value="NewYork">New York</option>
                        <option value="florida">Florida</option>
                        <option value="NorthCarolina">North Carolina</option>
                    </select>
                </div>
                <div className="input-wrapp acc-zip">
                <input className='input-full disabled' pattern="[0-9]}" maxLength="5" onChange={(e)=> setZipCode(e.target.value)} disabled type="text"  defaultValue={user.zipCode}/>
                </div>
            </div>
            <div className="form-row">
                <div className="input-wrapp acc-city">
                    <input className='input-full disabled'onChange={(e)=> setCity(e.target.value)} disabled type="text" defaultValue={user.city}/>
                </div>
                <div className="input-wrapp acc-email">
                    <input className='input-full disabled'onChange={(e)=> setEmail(e.target.value)} disabled type="email" defaultValue={user.email}/>
                </div>
            </div>
            <div className="form-row">
                <div className="input-half">
                </div>
                <div className="input-half">
               
                </div>
            </div>
            <div className="form-row">
                <div className="input-wrapp acc-pw">   
                    <input className='input-full disabled' onChange={(e)=> setPassword(e.target.value)}  disabled type="password" />    
                </div>
                {
                    canEdit ? <input type='submit' value='Save Changes' className='btn-purple btn-medium' />
                    :
                    <button onClick={()=> EnableEdit()} className='btn-purple'>Edit</button>
                }
            </div>
                 <p>{err}</p>
                <p>{status}</p>
        </form>
    </>
    )
}
