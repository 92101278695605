import { faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'



export const MedicalQuestions = (props) => {

   
    const[selectOpen,setSelectOpen] = useState(false)
    const arrow = <FontAwesomeIcon icon={faCaretDown} />
    const X = <FontAwesomeIcon icon={faTimes} />

    const [illCounter, setIllCounter] = useState(8)
    const [overlay, setOverlay] = useState(4)
    const [open,setOpen] = useState(false)

    const [timespan, setTimespan] = useState('Time Period')

    function ChangeTimeSpan(e){
        let innerText = e.target.textContent
            let text = innerText.substring(0,9)+'...'
            setTimespan(text)
    }

    function buttonPress(e,check){

        console.log(e.target.parentElement)
        let buttons = e.target.parentElement.getElementsByTagName('button')

        buttons[0].classList.remove('active')
        buttons[1].classList.remove('active')


       e.target.classList.add('active')

        if(!check && illCounter < 1)
            setIllCounter(illCounter+1)
        else{
            if(illCounter!== 0)
                setIllCounter(illCounter-2)
        }
    }

    const OverlayData = () =>{
        switch(overlay){
            default:
            case 0:
                return(
                    <>
                    <div className="header">
                        <h1>
                         Health Conditions
                        </h1>
                    </div>
                    <div className="prep-test">
                        <h3>The following health conditions may impact your vision:</h3>
                        <div className="prep-list">
                            <ul>
                                <li>Diabetes
                                </li>
                                <li>
                                Grave's Disease
                                </li>
                                <li>
                                Heart Disease
                                </li>
                                <li>
                                High blood pressure
                                </li>
                            </ul>
                            <ul>
                                <li>
                                Nursing
                                </li>
                                <li>
                                Pregnant
                                </li>
                                <li>
                                Rheumatoid Arthritis
                                </li>
                                <li>
                                Visual distortions 
                                </li>
                            </ul>
                        </div>
                    </div>
                    </>
                )
            case 1:{
               return( <>
                <div className="header">
                    <h1>
                    Medications
                    </h1>
                </div>
                <div className="prep-test">
                    <h3>The following medication may impact your vision:</h3>
                    <div className="prep-list">
                        <ul>
                           <li>
                                 Alendronate sodium
                           </li>
                           <li>
                                Amitriptyline
                           </li>
                           <li>
                                Bentyl
                           </li>
                           <li>
                                Chlorpheniramine
                           </li>
                           <li>
                                Chlorpromazine
                           </li>
                           <li>
                                Cidofovir
                           </li>
                           <li>
                                Clomipramine
                           </li>
                           <li>
                                Clozapine
                           </li>
                           <li>
                                Cortisone
                           </li>
                           <li>
                                Cyclopentolate
                           </li>
                           <li>
                                Deferoxamine mesylate
                           </li>
                        </ul>
                        <ul>
                            <li>
                            Desipramine
                           </li>
                           <li>
                           Dicyclomine
                           </li>
                           <li>
                           Digitalis
                           </li>
                           <li>
                           Digoxin
                           </li>
                           <li>
                           Doxepin
                           </li>
                           <li>
                           Ethambutol
                           </li>
                           <li>
                           Etidronate disodium
                           </li>
                           <li>
                           Fingolimod
                           </li>
                           <li>
                           Homatropine
                           </li>
                           <li>
                           Hydroxychloroquine
                           </li>
                           <li>
                           Hydroxyzine mesylate
                           </li>
                        </ul>
                        <ul>
                            <li>
                            Hyoscyamine
                           </li>
                           <li>
                           Imipramine
                           </li>
                           <li>
                           Interferon-α
                           </li>
                           <li>
                           Meclizine
                           </li>
                           <li>
                           Metformin
                           </li>
                           <li>
                           Notriptyline
                           </li>
                           <li>
                           Oxybutynin
                           </li>
                           <li>
                           Pamidronate disodium
                           </li>
                           <li>
                           Plaquenil
                           </li>
                           <li>
                           Promethazine
                           </li>
                           <li>
                           Rifabutin
                           </li>
                        </ul>
                        <ul>
                            <li>
                            Rifampin
                           </li>
                           <li>
                           Risedronate Sodium
                           </li>
                           <li>
                           Scopolamine
                           </li>
                           <li>
                           Sertraline
                           </li>
                           <li>
                           Tamoxifen
                           </li>
                           <li>
                           Thioridazine
                           </li>
                           <li>
                           Tiludronate disodium
                           </li>
                           <li>
                           Trifluoperazine
                           </li>
                           <li>
                           Tropicamide
                           </li>
                           <li>
                           Zoledronic acid
                           </li>
                        </ul>
                    </div>
                </div>
                </>)
            }
            case 2:
                return(
                    <>
                         <div className="header">
                        <h1>
                        Symptoms
                        </h1>
                    </div>
                    <div className="prep-test">
                        <h3>The following is a list of symptoms that cause eye discomfort.</h3>
                        <div className="prep-list">
                            <ul>
                                <li>Burning
                                </li>
                                <li>
                                Eyestrain
                                </li>
                                <li>
                                Flashes or floaters in vision
                                </li>
                                <li>
                                Headaches or migraines
                                </li>
                                <li>
                                Inflammation or swelling
                                </li>
                            </ul>
                            <ul>
                                <li>
                                Itching
                                </li>
                                <li>
                                Night vision difficulty
                                </li>
                                <li>
                                Other eye discomfort
                                </li>
                                <li>
                                Redness
                                </li>
                                <li>
                                Sandy/gritty/dirty
                                </li>
                            </ul>
                            <ul>
                                <li>
                                Sensitivity to sunlight or bright lights
                                </li>
                                <li>
                                Serious eye pain
                                </li>
                                <li>
                                Watering
                                </li>
                               
                            </ul>
                        </div>
                    </div>
                    </>
                )
            case 3:
                return(
                    <>
                         <div className="header">
                        <h1>
                        Eye Conditions
                        </h1>
                    </div>
                    <div className="prep-test">
                        <h3>The following is a list of serious eye conditions.</h3>
                        <div className="prep-list">
                            <ul>
                                <li>Any type of eye disease
                                </li>
                                <li>
                                Cataracts
                                </li>
                                <li>
                                Double vision
                                </li>
                                <li>
                                Eye infection
                                </li>
                                <li>
                                Eye injury
                                </li>
                            </ul>
                            <ul>
                                <li>
                                Glaucoma
                                </li>
                                <li>
                                Keratoconus
                                </li>
                                <li>
                                Laser eye surgery
                                </li>
                                <li>
                                Lazy eye
                                </li>
                                <li>
                                Macular degeneration
                                </li>
                            </ul>
                            <ul>
                                <li>
                                Retinal detachment
                                </li>
                                <li>
                                Sudden loss of vision
                                </li>
                                <li>
                                Surgery for either eye
                                </li>
                            </ul>
                        </div>
                    </div>
                    </>
                )
            case 4:
                return(
                    <>
                    <div className="header">
                   <h1>
                   In-Person Eye Exam
                   </h1>
               </div>
               <div className="prep-test">
                   <h3>Your online vision test isn’t a comprehensive eye exam.</h3>
                   <p>
                   Did you know you should see a doctor for a comprehensive eye exam at least once every two years? They’ll measure your overall eye health, not just how well you are seeing. Schedule an exam with a doctor in your area today.
                   </p>
               </div>
               </>
                )
            case 5:
                return (
                    <>
                    <div className="header">
                   <h1>
                   Please Schedule an Exam in person
                   </h1>
               </div>
               <div className="prep-test">
                   <h3>Unfortunately, due to your medical history our eye doctors have determined that you would be best suited to have your eyes checked in person.</h3>
                  <Link to={'/'}><button className="btn-purple btn-medium">
                       Go Back
                   </button></Link> 
               </div>
               </>
                )
        }
    }

    function CheckAnswers(){
        if(illCounter > 0 && timespan === 'Time Period'){
            setOverlay(5)
            setOpen(true)
        }
        else
            props.setActivePrep(1)
    }

    useEffect(()=>{
        console.log(illCounter)
    },[illCounter])

    return (
       <>  
           <div className={`prep-overlay ${open ? 'open' : ''}`}>
                <div className="prep-overlay-container">
                    <div className="close" onClick={()=> setOpen(false)}>
                        {X}
                    </div>
                    {OverlayData()}
                </div>
           </div>
           <h1>Medical Questions</h1>
           <div className="row">
               <p>
               Do you have any health conditions that can affect your vision? Includes pregnancy, nursing, diabetes, heart disease, heart disease, high blood pressure.&nbsp;
               <span onClick={()=> {setOverlay(0); setOpen(true)}} className='lnk-blue'>view all</span>
               </p>
               <div className="buttons">
                   <button onClick={e => buttonPress(e,0)} className='btn-outline btn-yes'>
                       Yes
                   </button>
                   <button onClick={e => buttonPress(e,1)} className='btn-outline btn-no'>
                       No
                   </button>
               </div>
           </div>
           <div className="row">
               <p>
               Are you taking any medication that affects your vision?&nbsp;
               <span onClick={()=> {setOverlay(1); setOpen(true)}} className='lnk-blue'>view all</span>
               </p>
               <div className="buttons">
                   <button onClick={e => buttonPress(e,0)} className='btn-outline btn-yes'>
                       Yes
                   </button>
                   <button onClick={e => buttonPress(e,1)} className='btn-outline btn-no'>
                       No
                   </button>
               </div>
           </div>
           <div className="row">
               <p>
               Do you experience any eye discomfort?&nbsp;
               <span onClick={()=> {setOverlay(2); setOpen(true)}} className='lnk-blue'>view all</span>
               </p>
               <div className="buttons">
                   <button onClick={e => buttonPress(e,0)} className='btn-outline btn-yes'>
                       Yes
                   </button>
                   <button onClick={e => buttonPress(e,1)} className='btn-outline btn-no'>
                       No
                   </button>
               </div>
           </div>
           <div className="row">
               <p>
               Have you ever had a serious eye condition or surgery? &nbsp;<span onClick={()=> {setOverlay(3); setOpen(true)}} className='lnk-blue'>view all</span>
               </p>
               <div className="buttons">
                   <button onClick={e => buttonPress(e,0)} className='btn-outline btn-yes'>
                       Yes
                   </button>
                   <button onClick={e => buttonPress(e,1)} className='btn-outline btn-no'>
                       No
                   </button>
               </div>
           </div>
           <div className="row">
               <p> 
               When was the last time you had an in-person eye exam? &nbsp;
               <span onClick={()=> {setOverlay(4); setOpen(true)}} className='lnk-blue'>learn more</span>
               </p> 
               <div className="buttons">
                  <div className="select btn-outline" onClick={()=> setSelectOpen(!selectOpen)}>
                    <p>{timespan}</p>
                    <p className={`select-arrow ${selectOpen ? 'arrow-rotate' : ''}`}>{arrow}</p> 
                      <div className={`select-dropdown ${ selectOpen ? 'select-open' : ''}`}>
                          <div className="select-dropdown-item">
                              <p onClick={e => ChangeTimeSpan(e)}>Less than 6 months ago</p>
                          </div>
                          <div className="select-dropdown-item">
                              <p onClick={e => ChangeTimeSpan(e)}>Between 6 and 12 months ago</p>
                          </div>
                          <div className="select-dropdown-item">
                              <p onClick={e => ChangeTimeSpan(e)}>Between 1 and 2 years ago</p>
                          </div>
                          <div className="select-dropdown-item">
                              <p onClick={e => ChangeTimeSpan(e)}>More than 2 years ago</p>
                          </div>
                          <div className="select-dropdown-item">
                              <p onClick={e => ChangeTimeSpan(e)}>I have never had an in-person exam</p>
                          </div>
                      </div>
                  </div>
               </div>
           </div>
           <div className="row">
               <button onClick={()=>CheckAnswers()} className='btn-purple'>continue</button>
           </div>
       </>
    )
}




