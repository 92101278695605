import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../store/features/UserSlice'
import { Aknowledgement } from './TestPreparations/Aknowledgement'
import { LicenseInfo } from './TestPreparations/LicenseInfo'
import { MedicalQuestions } from './TestPreparations/MedicalQuestions'
import { Prescriptions } from './TestPreparations/Prescriptions'
import {Header} from './Header'

export const TestPreparations = () => {

    const user = useSelector(selectUser)


    const [activePrep, setActivePrep] = useState(0)

    var license = user.licenseNum
    var front = user.frontLicense
    var back = user.backLicense


    const Headers = () =>{
            return Preps().map((prep,i) =>
            <div className="number">
                  <p>
                      <span>{i+1}</span>
                          {prep.name}
                  </p>
          </div>
            )
      }

      useEffect(()=>{
          
        if(activePrep < Preps().length)
        {let numbers = document.getElementsByClassName('number')
    
        for(let i =0 ; i< numbers.length; i++){
            if(i === activePrep)
                numbers[i].classList.add('lnk-blue')
            else
                numbers[i].classList.remove('lnk-blue')
        }}
      },[activePrep])
    
    const Preps = () =>{

        if(license && front && back)
            return [
                {
                    name: 'Medical Questions',
                    component: <MedicalQuestions setActivePrep={setActivePrep} />
                },
                {
                    name: 'Aknowledgement',
                    component: <Aknowledgement setActivePrep={setActivePrep} />
                },
                {
                    name: 'Prescriptions',
                    component: <Prescriptions setActivePrep={setActivePrep} />
                }

            ]
        else 
            return [
                {
                    name: 'Medical Questions',
                    component: <MedicalQuestions setActivePrep={setActivePrep} />
                },
                {
                    name: 'Aknowledgement',
                    component: <Aknowledgement setActivePrep={setActivePrep} />
                },
                {
                    name: 'License Info',
                    component: <LicenseInfo user={user} setActivePrep={setActivePrep} />
                },
                {
                    name: 'Prescriptions',
                    component: <Prescriptions setActivePrep={setActivePrep} />
                }
            ]
    }

    return (
        <>
        <Header />
        <div className="App gradient-back">
        <div className="test-setup-container">
            <div className="header">
                {
                    Headers()
                }
            </div>
            <div className="test-prepare">
                {
                    Preps()[activePrep] ?
                    Preps()[activePrep].component
                    :
                    ''
                }
            </div>
        </div>
    </div>
    </>
    )
}


