import React, {useState, useEffect} from 'react'
import QRCode from 'react-qr-code'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Calibration } from './Calibration'
import { BrowserView, MobileView, isMobile } from 'react-device-detect'
import ReactCodeInput from 'react-code-input'
import { SocketConnect } from '../util/utilities'
import { getSync } from '../store/features/SyncSlice'
import { useSelector } from 'react-redux'





export const MobileSync = (props) => {

    const socket = SocketConnect()


    const sync = useSelector(getSync)

    const [step,setStep] = useState(0)

    const [phone, setPhone] = useState('')
    const [code, setCode] = useState('')
    const [sms,setSMS] = useState('Send')


    const [room,setRoom] = useState('')


    const urlSearchParams = new URLSearchParams(window.window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    useEffect(()=>{
        setRoom(params.token)
        if(!isMobile)
        socket.emit('generateSession', sync)
    },[])

    useEffect(()=>{
        if(isMobile && room){
            console.log(room)
            let data = {
                token: room
            }
            localStorage.setItem('sync', JSON.stringify(data))
            setTimeout(()=>{
                socket.emit('joinToken', room)
            },100)
        }
      },[room])

        useEffect(()=>{
            socket.on('step',data=>{
                if(!isMobile)
                 props.setActiveSetup(2)
                else{
                    let sync ={
                        token:data.token
                    }
                    localStorage.setItem('sync', JSON.stringify(sync))
                    setRoom(data.token)
                }
                })
            socket.on('synced',()=>{
                if(!isMobile)
                 props.setActiveSetup(2)
            })
        })

    function sendSMS(){
        let link = `http://192.168.100.50:3000/sync/?token=${sync.token}`
        let number = phone

        socket.emit('sms',({number,link}))
        setSMS('Sent')
    }
    const fetchToken = e =>{

        socket.emit('joinCode', code)
    }

    switch(step)
    {
        case 0:{ 
            return (
                <>
                <BrowserView className='sync-box-grid' style={{width: '100%'}}>
                         <h1>Connect Your Phone</h1>
                         <h3>Use one of three options to connect your Mobile Device with Desktop View </h3>
                            <div className="sync-QR">
                                <p className='lnk-blue'>Option 1</p>
                                <p>Scan to automatically sync</p>
                                <QRCode size={128} value={`${process.env.REACT_APP_MOBILE_LINK}/sync/?token=${sync.token}`} />
                            </div>
                            <div className="sync-SMS">
                                <p className='lnk-blue'>Option 2</p>
                                <p>Enter your phone number</p>
                                <PhoneInput
                                    country={'us'}
                                    value={phone}
                                    onChange={phoneN => setPhone(phoneN)}
                                />
                                <button className='btn-purple btn-medium' onClick={(e)=> {e.preventDefault(); sendSMS()}}>{sms}</button>
                            </div>
                            <div className="sync-CODE">
                                <p className='lnk-blue'>Option 3</p>
                                <p>Go to sync URL and enter the code</p>
                                <ReactCodeInput value={sync.code} type="number" autoFocus={false}  fields={6}  />
                               <p>{process.env.REACT_APP_MOBILE_LINK}/sync</p>
                            </div>
                        <p>
                            For more details, refer to the following guides: <br/>
                            Chrome: Android, iPhone & iPad   Safari   Firefox: Android, iPhone & iPad   Samsung Internet: Android, iPhone & iPad
                        </p>
                    </BrowserView>
                <MobileView>
                   {room ?
                        <Calibration token={room} socket={socket} />
                    :
                        <div className="App gradient-back">
                            <div className="d-flex" style={{flexDirection:'column',alignItems:'center', gap:'1rem'}}>
                                <h1>Enter Code</h1>
                                <ReactCodeInput onChange={e => setCode(e)} type='number' fields={6} />
                                <button onClick={e => fetchToken(e)} className='btn-purple btn-big'>Send</button>
                            </div>
                        </div>
                   }
                </MobileView>
                </>
            )
        }
        case 1:{
            return
        }
    }
    
}

