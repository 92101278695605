import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../store/features/UserSlice'
import '../PDFStyles/Florida.css'
import '../PDFStyles/FloridaSmall.css'

export const Florida = (props) => {

    const user = useSelector(selectUser)


    const [data, setData] = useState({
        fullName: `${user.firstName} ${user.lastName}`,
        licenseNum: user.licenseNum,
        address: user.address,
        city: user.city,
        DOB: '00/00/00'
    })

    useEffect(()=>{
        let list = document.getElementsByTagName('input')
        for( let i = 0 ; i < list.length ; i++){
            list[i].style.width = ((list[i].value.length + 1) * 7) +'px'
        }
    },[])

    const AdjustSize = (e) =>{
        let obj = e.target
       obj.style.width = ((e.target.value.length +1  ) * 7) + 'px' 

       setData({
           ...data,
         [e.target.name]: e.target.value
       })

    }
    useEffect(()=>{
        if(props.canEdit)
            localStorage.setItem('data', JSON.stringify(data))
    },[data])

    return (
        <>
            <img src="./images/pdfs/florida.jpg" alt="Florida Form" />
            <input name='fullName' type="text" onChange={e=> {AdjustSize(e)}} defaultValue={data.fullName} className="FirstName" />
            <input name='licenseNum' type="text" onChange={e=>{ AdjustSize(e)}} defaultValue={data.licenseNum} className="LicenseNum" />
            <input name='DOB' type="text" onChange={e=>{ AdjustSize(e)}} defaultValue={data.DOB} className="DOBDay" />
            <p className="Optometrist">&#x2713;</p>
            <p className="Ophthalmologist">&#x2713;</p>
            <input name='address' type="text" onChange={e=>{ AdjustSize(e)}} defaultValue={data.address}  className="Address" />
            <input name='city' type="text" onChange={e=>{ AdjustSize(e)}} defaultValue={data.city} className="City" />
            <p className='Date'>{(new Date().getMonth()+1) + '/' + new Date().getDate() + '/' + new Date().getFullYear()}</p>
            <p className="LensesIssued">???</p>
            <p className="TableFirstRow first">40</p>
            <p className="TableFirstRow second">40</p>
            <p className="TableFirstRow third">40</p>
            <p className="TableSecondRow first">40</p>
            <p className="TableSecondRow second">40</p>
            <p className="TableSecondRow third">40</p>
            <p className="Question-One Yes">&#x2713;</p>
            <p className="Question-One No">&#x2713;</p>
            <p className="Question-Two Yes">&#x2713;</p>
            <p className="Question-Three No">&#x2713;</p>
            <p className="Question-Four No">&#x2713;</p>
            <p className="Question-Five No">&#x2713;</p>
            <p className="Question-Six Yes">&#x2713;</p>
            <p className="Question-Seven No">&#x2713;</p>
            <p className="Question-Eight No">&#x2713;</p>
        </>
    )
}

