import '../../PDFStyles/Florida.css'

export const FloridaTemplate = ({transaction}) => {
    return (
        <>
            <img src="./images/pdfs/florida.jpg" alt="Florida Form" />
            <p className="UserSign">{transaction.signature}</p>
            <p className="LicenseNum">{transaction.licenseNum}</p>
            <p className="FirstName"> {transaction.fullName} </p>
            <p className='DOBDay'>{transaction.date_of_birth}</p>
            <p className="Optometrist">&#x2713;</p>
            <p className="Ophthalmologist">&#x2713;</p>
            <p className='Address'>{transaction.address}</p>
            <p className='City'>{transaction.city}</p>
            <p className='Date'>{`${transaction.date.split('/')[0]}/${transaction.date.split('/')[1]}/${transaction.date.split('/')[2]}`}</p>
            <p className='DoctorName'>Doctor Name</p>
            <p className='DoctorAddress'>Doctor Address , Doc.city Doc. zip , Doc. Country ,Florida</p>
            <p className="DoctorPhone">123123123</p>
            <p className="LensesIssued">???</p>
            <p className="AssociateSign">Associate Signature</p>
            <p className="AssociateAddress">Associate Address, city, zip , state</p>
            <p className="AssociatePhone">123123123</p>
            <p className="TableFirstRow first">40</p>
            <p className="TableFirstRow second">40</p>
            <p className="TableFirstRow third">40</p>
            <p className="TableSecondRow first">40</p>
            <p className="TableSecondRow second">40</p>
            <p className="TableSecondRow third">40</p>
            <p className="Question-One Yes">&#x2713;</p>
            <p className="Question-One No">&#x2713;</p>
            <p className="Question-Two Yes">&#x2713;</p>
            <p className="Question-Three No">&#x2713;</p>
            <p className="Question-Four No">&#x2713;</p>
            <p className="Question-Five No">&#x2713;</p>
            <p className="Question-Six Yes">&#x2713;</p>
            <p className="Question-Seven No">&#x2713;</p>
            <p className="Question-Eight No">&#x2713;</p>
            <p className="DoctorSign">Doctor Signarure</p>
            <p className="DoctorLicense">12345678</p>  
        </>
    )
}

