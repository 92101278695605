import React, { useState, useRef, useEffect } from 'react'
import Upload from '../../upload.svg'
import StyledDropZone from 'react-drop-zone/dist/StyledDropZone'
import axios from 'axios'
import { UpdateLicense, UploadImages } from '../../util/utilities'
import { login } from '../../store/features/UserSlice'
import { useDispatch } from 'react-redux'

export const LicenseInfo = (props) => {

    const user = props.user

    const [frontID] = useState(user.frontLicense)
    const [backID] = useState(user.backLicense)

    const [front, setFront] = useState();
    const [back, setBack] = useState();

    const [frontPreview,setFrontPreview] = useState()
    const [backPreview,setBackPreview] = useState()

    const [licenseNum, setLicenseNum] = useState(user.licenseNum)

    const frontLicense = useRef(null)
    const backLicense = useRef(null)

    const dispatch = useDispatch()

    const States = () =>{
        switch(user.usState){
            case 'NewYork':
                return 'New York'
            case 'florida':
                return 'Florida'
            case 'NorthCarolina':
                return 'North Carolina'
        }
    }

    useEffect(()=>{
        
        if(front){
            let reader = new FileReader();
            reader.onloadend = ()=>{
                setFrontPreview(reader.result)
            }
            reader.readAsDataURL(front)
        }
        else{
            setFrontPreview()
        }
        if(back){
            let reader = new FileReader();
            reader.onloadend = ()=>{
                setBackPreview(reader.result)
            }
            reader.readAsDataURL(back)
        }
        else{
            setBackPreview()
        }
    },[front,back])

    useEffect(()=>{
        async function GetLicense(){
            if(frontID || backID)
              {  let data = {
                    front: frontID,
                    back: backID
                }
                await axios.post('/getLicense',data,
               { headers: {
                    "Content-Type": "application/json",
                    'Access-Control-Allow-Origin': '*',
                    }}
                ).then( res => {
                   let data = res.data
                   setFrontPreview(data[0].path)
                   setBackPreview(data[1].path)
    
                })}
        }
        GetLicense()
    },[frontID,backID])

    function Proceed(e){
        e.preventDefault()
        if(licenseNum){
           if(!frontID && !backID){
            UploadImages(front,back,user.id,licenseNum,user.frontLicense, user.backLicense)
            axios.post(`${process.env.REACT_APP_SERVER_LINK}/getLicenseID`, {id:user.id}).then(
                res =>{
                         let userData ={
                                id: user.id,
                                firstName: user.firstName,
                                lastName: user.lastName,
                                address: user.address,
                                usState: user.usState,
                                zipCode: user.zipCode,
                                city: user.city,
                                phoneNumber: user.phoneNumber,
                                email: user.email,
                                frontLicense: res.data.license_front_id,
                                backLicense: res.data.license_back_id,
                                licenseNum: licenseNum,
                                loggedIn: true,
                                tests_taken: res.data.tests_taken,
                                tests_passed: res.data.tests_passed,
                                test_in_progress: res.data.test_in_progress
                                }
                               dispatch(
                                   login(userData)
                                   )
                                   localStorage.setItem('user', JSON.stringify(userData))
                                   props.setActivePrep(3)
                }
                 )
           }
           else{
               UpdateLicense(front, back , frontID, backID, user.id, licenseNum).then()
               axios.post(`${process.env.REACT_APP_SERVER_LINK}/getLicenseID`, {id:user.id}).then(
                res =>{
                         let userData ={
                                id: user.id,
                                firstName: user.firstName,
                                lastName: user.lastName,
                                address: user.address,
                                usState: user.usState,
                                zipCode: user.zipCode,
                                city: user.city,
                                phoneNumber: user.phoneNumber,
                                email: user.email,
                                frontLicense: res.data.license_front_id,
                                backLicense: res.data.license_back_id,
                                licenseNum: licenseNum,
                                loggedIn: true,
                                finishedTest: false
                                }
                               dispatch(
                                   login(userData)
                                   )
                                   localStorage.setItem('user', JSON.stringify(userData))
                                   props.setActivePrep(3)
                })
           }
        }
    }

    return (
        <form onSubmit={(e)=> Proceed(e)} className='acc-settings'>
            <div className="form-row">
                <div className="input-wrapp acc-state">
                    <input className='input-full' disabled type="text" defaultValue={States()} />
                </div>
                <div required className="input-wrapp acc-license">
                    <input className='input-full' onChange={(e)=> setLicenseNum(e.target.value)}  type="text" defaultValue={user.licenseNum} />
                </div>
            </div>
            <div className="form-row">
                <div className="drop-zone">
                    <h1>front</h1>
                    <StyledDropZone accept={'image/*'}  onDrop={(file, text) => setFront(file)}>
                        {
                            frontPreview ?
                            <img className='license' src={frontPreview} alt="" />
                            :
                            <div ref={frontLicense} className="center">
                            <img src={Upload} alt="" />
                            <h3>Drag and drop Photo</h3>
                        </div>
                        }
                    </StyledDropZone>
                            <p onClick={()=> frontLicense.current.click()}>or <span className='lnk-blue'>import from files</span></p>
                </div>
                <div className="drop-zone">
                    <h1>back</h1>
                    <StyledDropZone onDrop={(file, text) => setBack(file)}>
                    {
                            backPreview ?
                            <img className='license' src={backPreview} alt="" />
                            :
                            <div ref={backLicense} className="center">
                            <img src={Upload} alt="" />
                            <h3>Drag and drop Photo</h3>
                        </div>
                        }
                    </StyledDropZone>
                        <p onClick={()=> backLicense.current.click()}>or <span className='lnk-blue'>import from files</span></p>
                </div>
            </div>
            <div className="form-row cnt">
                <input type='submit' value='Continue' className='btn-purple' />
            </div>
            </form>
    )
}

