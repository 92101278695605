import React ,{useState,useEffect , useRef} from 'react'
import {BrowserView, isMobile, MobileView} from 'react-device-detect';
import Webcam from "react-webcam";
import { Button } from '@material-ui/core';
import { TestController } from './TestController';
import {CalibrationSetUp} from './Calibration-Instructions/CalibrationSetUp'
import Lottie from 'lottie-web';

export const Calibration = (props) => {

    const videoRef = useRef(null);
    const photoRef = useRef(null)
    const [isCalibrated] = useState(false)
    const [appStep, setAppStep] = useState(0)
    const [lang, setLang] = useState(0) // english = 0

    const token = JSON.parse(localStorage.getItem('sync')).token

    useEffect(()=>{
        if(!isMobile){
            props.socket.emit('join',token)
            if(localStorage.getItem('Prescription') !== null)
                socket.emit('prescription',token ,JSON.parse(localStorage.getItem('Prescription')).type) // send prescription if set
        }
        socket.on('prescription', type=>{
            if(isMobile){
                let data = {
                    prescription: type
                }
                localStorage.setItem('prescription',JSON.stringify(data))
            }
        })
    },[])

    const [instructionStep, setInstructionStep] = useState(0)

    const room = props.token

    const socket = props.socket

    const lottieRef = useRef(null)

    useEffect(()=>{
        socket.once('appstep',data=>{
            if(!isMobile)
                props.setCounter(data)
                setAppStep(data)
                })
        socket.on('calibrated', ()=>{
            if(isMobile)
                window.location.href=`${process.env.REACT_APP_MOBILE_LINK}/test`
            else
                window.location.href=`${process.env.REACT_APP_LINK}/test`
        })
    })


    const runCoco = async () =>{
        setInterval(()=>{
            detect()
        },100)
    }
    const detect = async () => {
        // Check data is available
        if (
          typeof videoRef.current !== "undefined" &&
          videoRef.current !== null &&
          videoRef.current.video.readyState === 4
        ) {
          // Get Video Properties
          const video = videoRef.current.video;
          const canvas = photoRef.current


          const context = canvas.getContext('2d')
           canvas.width = videoRef.current.video.videoWidth;
          canvas.height = videoRef.current.video.videoHeight;
          context.drawImage(video, 0, 0, videoRef.current.video.videoWidth, videoRef.current.video.videoHeight);
    

            var recWidth = mobileWidth*100/5 + 10
            var recHeight = mobileWidth*100/5 + 10
            var midRectHeight = mobileWidth*100/5
            var midRectWidth = mobileWidth*100/5
            var xPos = (canvas.width / 2) - (recWidth / 2);
            var yPos = (canvas.height / 2) - (recHeight / 2);
            var midxPos = (canvas.width / 2) - (midRectWidth / 2);
            var midyPos = (canvas.height / 2) - (midRectHeight / 2);

            context.strokeRect(xPos, yPos, recWidth, recHeight);
            context.strokeRect(midxPos, midyPos, midRectWidth, midRectHeight);


            let R = 0;
            let G = 0;
            let B = 0;
            let RM = 0;
            let GM = 0;
            let BM = 0;

            const dataColor = context.getImageData(xPos, yPos, recWidth, recHeight).data;
            const components = dataColor.length;

            for (let i = 0; i < components; i += 4) {
                // A single pixel (R, G, B, A) will take 4 positions in the array:
                R += dataColor[i];
                G += dataColor[i + 1];
                B += dataColor[i + 2];
            }

            const dataMidColor = context.getImageData(midxPos, midyPos, midRectWidth, midRectHeight).data
            const altComponents = dataMidColor.length

            for (let k = 0; k < altComponents; k += 4) {
                R -= dataMidColor[k];
                G -= dataMidColor[k + 1];
                B -= dataMidColor[k + 2];
                RM += dataMidColor[k];
                GM += dataMidColor[k + 1];
                BM += dataMidColor[k + 2];

            }

            const pixelsPerChannel = (components / 4) - (altComponents / 4);
            const pixelsMidPerChannel = altComponents / 4;

            // The |?operator is used here to perform an integer division:

            R = R / pixelsPerChannel | 0;
            G = G / pixelsPerChannel | 0;
            B = B / pixelsPerChannel | 0;
            RM = RM / pixelsMidPerChannel | 0;
            GM = GM / pixelsMidPerChannel | 0;
            BM = BM / pixelsMidPerChannel | 0;

            if ((R < 130 && G < 130 && B < 130) && (RM > 200 && GM > 200 && BM > 200)) {
                document.getElementById('detector').classList.add('complete')
                    let button =  document.getElementById('continue')
                    button.classList.remove('disabled') 
            } else {
                document.getElementById('detector').classList.remove('complete')
                let button =  document.getElementById('continue')
                    button.classList.add('disabled') 
            }
        }
      };
      function clearphoto() {
        var context = photoRef.getContext('2d');
        context.fillStyle = "#AAA";
        context.fillRect(0, 0, photoRef.width, photoRef.height);
    }
    useEffect(()=>{
        if(isMobile){
         
            runCoco()
        }
    },[]);
    
    const videoConstraints = {
        facingMode: { exact: "environment" },
        width: 300,
        height:400
    };

    const [mobileWidth , setMobileWidth] = useState(0.5);

    
    const ResizeMobile = (e) =>{
        setMobileWidth(e.target.value / 100);
    }
    const Calibrate = () =>{
        setMobileWidth(mobileWidth * 400)
        if(token)
             props.socket.emit('appstep',token,2)
        else
            props.socket.emit('appstep',room,2)
    }

    const DesktopVievs = (step) =>{
        switch(step){
            case 0:
            case1:{
                return(
                    <div className="cali-wrap">
                        <img src="./images/eye.png" alt="" className='blueSquare'/>
                    </div>
                )
            }
            case 2:{
                return(
                    <div className="blackWrap">
                        <div className="blackSquare" style={{aspectRatio:'1', width:`${mobileWidth*1200}px`, maxWidth:'99vw'}}></div>
                    </div>
                )
            }
        }
    }
    var animation
    useEffect(() => {
        if(appStep == 1 && instructionStep !== 2){
            Lottie.destroy(animation)
            animation = Lottie.loadAnimation({
                container: lottieRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require( `../Lotties/Lottie-5.json`)
        })
    }
        if(instructionStep === 2){
            Lottie.destroy(animation)
            animation = Lottie.loadAnimation({
                container: lottieRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require( `../Lotties/Lottie-6.json`)
         })
        }
    }, [instructionStep,appStep])

     function switchButton(x){
      let buttons = document.getElementsByClassName('btn-prep')

      for(let i = 0; i< buttons.length ; i++){
         if (i=x)
            buttons[i].classList.add('active')
         else
            buttons[i].classList.remove('active')
      }
   }
    const ScreenCalirbrator = () =>{
            switch(instructionStep){
                case 0:
                    return(
                        <>
                            <div className="App gradient-back">
                                <div className="col text-center">
                                    <div className='d-flex'>
                                        <button onClick={()=>{ setLang(0); switchButton(0)}} className="btn-medium btn-prep active">ENG</button>
                                        <button onClick={()=>{ setLang(1); switchButton(1)}} className="btn-medium btn-prep">ESP</button>
                                    </div>
                                    <p>
                                        {
                                            !lang ?
                                                'Position the back of your phone aproximately one ATM card lenght (85 mm or 3.3 in) away from the monitor.'
                                            :
                                                'Coloca una tarjeta bancaria entre tu pantalla y tu dispositivo móvil. Esta es la distancia necesaria para una calibración precisa.'
                                        }
                                    </p>
                                    <div className='lottie' ref={lottieRef}>

                                    </div>
                                    <button onClick={()=> setInstructionStep(1)} className='btn-purple btn-medium'>Continue</button>
                                </div>
                            </div>
                        </>
                    )
                case 1:
                    return(
                        <>
                        <div className="video-wrapp-mobile gradient-back">
                            <p>{
                                !lang ?
                               'Resize the bracket to fit the shape displayed in your camera view.'
                                :
                                'Ajusta el área de enfoque con los brackets rojos usando el deslizador mientras mantienes la distancia establecida anteriormente entre la pantalla y tu celular, para que ésta área encaje cómodamente en la pantalla'
                            }</p>
                            <div className="video-container">
                                <Webcam height={300} width={300} videoConstraints={videoConstraints} audio={false} />
                                <div className="resizeMobile" style={{transform:`scale(${mobileWidth})`}}>
                                    <div className="resize-mobile-fill"></div>
                                </div>
                            </div>
                            <input type="range" className="mobileRange" min={10} defaultValue={50} max={100} onChange={e => ResizeMobile(e)} />
                            <Button variant="outlined" onClick={()=> setInstructionStep(2)} className="mobileButton">Continue</Button>
                        </div>
                        </>
                    )
                case 2:
                    return(
                        <>
                        <div className="App gradient-back">
                            <div className="col text-center">
                                <div className='d-flex'>
                                        <button onClick={()=>{ setLang(0); switchButton(0)}} className="btn-medium btn-prep active">ENG</button>
                                        <button onClick={()=>{ setLang(1); switchButton(1)}} className="btn-medium btn-prep">ESP</button>
                                </div>
                                <p>
                                    {
                                        !lang ?
                                        ' Great Job! Now please position yourself approximately 10ft away from the image on your desktop monitor.'
                                        :
                                        ' Por favor colocate aproximadamente a 10 pies o  3 metros con 50 cm de distancia del recuadro blanco en tu pantalla'
                                    }
                               
                                </p>
                                <div className='lottie' ref={lottieRef}>

                                </div>
                                <button onClick={()=> Calibrate()} className='btn-purple btn-medium'>Continue</button>
                            </div>
                        </div>
                    </>
                    )
            }
    }

    const MobileViews = (step) =>{
        switch(step){
            case 0 : return <CalibrationSetUp setAppStep={setAppStep} />
            case 1:{
                return(
                   ScreenCalirbrator()
                )
            }
            case 2:{
                return(
                    <div className="video-wrapp-mobile gradient-back">
                        <p>
                            {
                                !lang ? 
                                'Point your camera again to the image and adjust your position until the bracket turns green before tapping Confirm.'
                                :
                                'Apunta tu cámara al recuadro mientras te aseguras de que éste entre dentro del área de enfoque de tu celular. Los brackets rojos se volverán verdes en el momento en que encajen y podrás presionar el botón de “Confirmar” para continuar.'
                            }
                             
                        </p>
                        <Webcam className="feed" videoConstraints={videoConstraints} audio={false} ref={videoRef} />
                        <canvas
                            className="feedCanvas"
                            ref={photoRef}
                        />

                        <div id='detector' style={{ height:`${mobileWidth/18}px`, width:`${mobileWidth/18}px`}} className="detector">
                        </div>
                        <button id='continue' onClick={() => {setAppStep(3)}} className="mobileButton btn-purple btn-medium disabled" > Continue </button>
                     </div>
                )
            }
            case 3:{
                return(
                    <>
                     <div className="App gradient-back">
                     <div className="col text-center">
                        <h1>Calibration <br /> Completed!</h1>
                        <p>Congratulations. <br />
                                    You’re ready to start the test</p>
                        <button onClick={()=>  socket.emit('calibrated', room)} className="btn-purple btn-big">Continue</button>
                     </div>
                  </div>
                    </>
                )
            }
        }
    }

    if(!isCalibrated)
    return (
        <>
        <BrowserView>
            {
                DesktopVievs(props.counter)
            }
        </BrowserView>
        <MobileView>
           {
               MobileViews(appStep)
           }
        </MobileView>
        </>
    )
    else return <TestController socket={socket} />
}
