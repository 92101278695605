import React,{ useEffect, useState } from "react"
import { BrowserView, MobileView } from "react-device-detect"

export const CountDown = (props) => {
    const [timer, setTimer] =  useState(3)

    useEffect(()=>{
       if(timer > 0 && props.load){
           setTimeout(()=> setTimer(timer-1), 1000)
            console.log('HE HE')
       }
        else{
            props.setLoad(false)
            setTimer(3)
        }

    },[timer,props.load])

    return (
        <>
        <BrowserView>
        <div className={`countdown ${timer == 0 || !props.load ? 'no-display' : ''}`}>
             <div className="number-big">
                    <p>{timer}</p>
             </div>
        </div>
        </BrowserView>
        <MobileView>
            <div className={`countdown-mobile ${timer == 0 || !props.load ? 'no-display' : ''}`}>
                <div className="number-big">
                        <p>{timer}</p>
                </div>
            </div>
        </MobileView>
        </>
    )
}

